// import React, { useState } from "react";
// import { Box, Button, Avatar, Menu, MenuItem, Divider } from "@mui/material";
// import { useUI } from "../../../contexts/UIContext";
// import ThreadHistory from "./ThreadHistory";
// import UserProfile from "./UserProfile";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../../contexts/AuthContext";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import black_logo from "../../../assets/modalX_transparent_orange_icon.png";
// import white_logo from "../../../assets/modalX_robot_icon_white.png";
// import { button_bg_color_for_New_Project, darkTheme_bg_color } from "../../../constants/colors";
// import LightModeIcon from '@mui/icons-material/LightMode';
// import DarkModeIcon from '@mui/icons-material/DarkMode';
// import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
// import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
// import plusIcon from "../../../assets/plusIcon_for_new_project.png";

// const SidebarContent = () => {
//   const { toggleTheme, currentTheme } = useUI();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [showProfileModal, setShowProfileModal] = useState(false);
//   const { logout } = useAuth();
//   const { clearThread, setThreadId, setThreadFiles, setHistoryFiles } = useWorkspace();
//   const isLoggedIn = localStorage.getItem('isLoggedIn');
//   const userEmail = localStorage.getItem('userEmail');
//   const theme = localStorage.getItem('theme');

//   const navigate = useNavigate();

//   const handleLogout = async () => {
//     await logout();
//     localStorage.setItem("isLoggedIn", "");
//     localStorage.setItem('token', '');
//     localStorage.removeItem('userEmail');
//     localStorage.removeItem('current_thread_id');
//     localStorage.removeItem('current_history_id');
//     navigate("/");
//   };

//   const handleNewChat = () => {
//     clearThread();
//     setThreadFiles([]);
//     setHistoryFiles([]);
//     localStorage.removeItem('current_thread_id');
//     localStorage.removeItem('current_history_id');
//     setThreadId(null);
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <Box
//       sx={{
//         width:'282px',
//         overflowY: "auto",
//         height:'100vh',
//         display: "flex",
//         flexDirection: "column",
//         p: 2,
//         bgcolor: theme === "dark" ? darkTheme_bg_color : "grey.300",
//         color: theme === "dark" ? "white" : "black"
//       }}
//     >
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
//         <Button
//           variant="outlined"
//           onClick={handleNewChat}
//           disabled={!isLoggedIn}
//           sx={{ gap:'8px',padding:'10px 24px 10px 16px',fontSize: '16px', width: '242px',textTransform:'none',bgcolor:button_bg_color_for_New_Project,color:'black',fontWeight:'600',height:'40px','&:hover': {
//             bgcolor: '#ffd9aa'
//           }}}
//         >
//           <img src={plusIcon}/>
//           New Project
//         </Button>
//         {/* <Button sx={{ bgcolor: 'transparent', border: 'none', mr: 2 }} onClick={() => navigate("/")}>
//           <Avatar src={theme === 'dark' ? black_logo : white_logo} sx={{ width: 25, height: 25, transform: theme === 'dark' ? 'scale(1.5)' : 'none' }} />
//         </Button> */}
//       </Box>
//       <div style={{marginTop:'36px'}}>
//         <ThreadHistory />
//       </div>

//       {/* <Button
//         variant="outlined"
//         onClick={handleMenuOpen}
//         sx={{ mt: 3, textTransform: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left' }}
//       >
//         <i className="bi bi-person-circle" />
//         {userEmail}
//       </Button> */}
//       {/* <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleMenuClose}
//       >
//         <MenuItem onClick={() => { toggleTheme(); handleMenuClose(); }}>
//           {currentTheme === "dark" ? "Light mode ☀️" : "Dark mode🌙"}
//         </MenuItem>
//         <Divider />
//         <MenuItem onClick={handleLogout}>Logout</MenuItem>
//       </Menu> */}
//       <hr></hr>
//       <div style={{ width: '100%', height: '140px',justifyContent:'center'}}>
//         {/* <Button
//           onClick={() => { toggleTheme(); handleMenuClose(); }}
//           sx={{ textTransform: 'none',gap:'10px',color:theme === 'dark'?'#FFFFFF':'' }}>
//           {currentTheme === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
//           {currentTheme === "dark" ? " Light mode" : " Dark mode"}
//         </Button> */}
//         <Button sx={{ textTransform: 'none',gap:'10px',color:theme === 'dark'?'#FFFFFF':'' }}>
//           {<PersonOutlineOutlinedIcon />}
//           {userEmail}
//         </Button>
//         <Button sx={{ textTransform: 'none',gap:'10px',color:theme === 'dark'?'#FFFFFF':'' }} onClick={() => handleLogout()}>
//           {<LogoutOutlinedIcon />}
//           Log out
//         </Button>
//       </div>
//       <UserProfile
//         showModal={showProfileModal}
//         setShowModal={setShowProfileModal}
//       />
//     </Box>
//   );
// };

// export default SidebarContent;

import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Avatar, Typography, CardMedia, useMediaQuery,Menu, MenuItem, ListItemIcon, ListItemText, Divider  } from "@mui/material";
import { useUI } from "../../../contexts/UIContext";
import ThreadHistory from "./ThreadHistory";
import UserProfile from "./UserProfile";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import black_logo from "../../../assets/modalX_transparent_orange_icon.png";
import white_logo from "../../../assets/modalX_robot_icon_white.png";
import logo from '../../../assets/upgradeLogo.png'
import {
  button_bg_color_for_New_Project,
  button_bg_color_for_New_Project_dm,
  darkTheme_bg_color,
  hovered_chat_bg_color,
  selected_chat_bg_color,
} from "../../../constants/colors";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import plusIcon from "../../../assets/plusIcon_for_new_project.png";
import {
  HoveredChat_bg_color,
  New_Project_Button_bg_color,
} from "../../../Provider/Color_Provider";
import { AccountInfoClick, ButtonClick, CreateNewProjectConfirmation, NavigationClick, trackButtonEvent } from "../../../config/analytics";
import { useCreditRecharge } from "../../../contexts/CreditRechageContext";
// import deleteIcon from '../../../assets/DeleteIcon.png'
import deleteIcon from '../../../assets/DeleteIcons.png'
import sourceUrl from "../../../api/Source.api";
import MenuIcon from '@mui/icons-material/Menu'
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import AddIcon from '@mui/icons-material/Add';
import TrackWebsiteModal from "./Track_Website/TrackWebsiteModal";
import canadaflag from '../../../assets/cd.png'
import Imgration from '../../../assets/im.png'
import DefaultIconFor_Websites from '../../../assets/Website Tracking/DefaultIconFor_Websites.png'
import axios from "axios";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


const SidebarContent = () => {
  const { toggleTheme, currentTheme } = useUI();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [website, setWebSite] = useState([]);
  const { logout, toggleLogout, setToggleLogout, allDelete, setAllDelete } = useAuth();
  const { clearThread, setThreadId, setThreadFiles, setHistoryFiles, subscribe, setShowLeftsideBar, showLeftsideBar, setShowSuggested, currentThread, openWebsiteTrackModal, setOpenWebsiteTrackModal,setIswebTrackClicked,setShowRightsideBar,setSelectedWeb ,waitThread,fileProcessing, currentHistoryId, setCurrentHistoryId, currentWebsiteLinkedId, iswebTrackClicked,setCurrentWebsiteLinkedId, updateWebList,setUpdateWebList, userInfo} = useWorkspace();
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const userEmail = localStorage.getItem("userEmail");
  const theme = localStorage.getItem("theme");
  const { upgradePlan } = useCreditRecharge()
  const token = localStorage.getItem('token');
  const mobileView = useMediaQuery('(max-width:992px)');
  const [count, setCount] = useState(0);
  const [addWebClicked, setAddWebClicked] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [notificationCount,setNotificationCount] = useState(0);
  const [moreoption, setMoreOption] = useState(false);
  const [initials, setInitials] = useState(false);
  const [userName, setUserName] = useState(null);

  const navigate = useNavigate();

  // const handleLogout = async () => {
  //   await logout();
  //   localStorage.setItem("isLoggedIn", "");
  //   localStorage.setItem("token", "");
  //   localStorage.removeItem("userEmail");
  //   localStorage.removeItem("current_thread_id");
  //   localStorage.removeItem("current_history_id");
  //   trackButtonEvent("button_click_logout", {
  //     button_name: "Logout",
  //     button_position: "bottom-left",
  //   });
  //   navigate("/");
  // };

    // Generate initials from first and last name, or fallback to email
    const getInitials = (firstName, lastName, userEmail) => {
      if (firstName || lastName) {
        const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
        const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
        return `${firstInitial}${lastInitial}`;
      }
  
      if (userEmail) {
        const namePart = userEmail.split("@")[0]; 
        return namePart.charAt(0).toUpperCase();
      }
      return "X"; // Default if no name or email is provided
    };


      useEffect(()=>{
        setInitials(getInitials(userInfo?.firstName, userInfo?.lastName , userEmail));
      },[userInfo])

  const webSiteData = [
    {
      linkId: 1,
      websiteLink: "www.modalx.ai",
      displayName: "Immigration and Citizenshiop",
      frequencyInMins: 5,
      notifications: true,
      text: "mock text",
      websiteLogoUrl: "https://xyz.xy/ab.png"

    },
    {
      linkId: 2,
      websiteLink: "www.modalx.ai",
      displayName: "2024 Election Resources - USA",
      frequencyInMins: 5,
      notifications: true,
      text: "mock text",
      websiteLogoUrl: "https://xyz.xy/ab.png"
    }
  ]

  // useEffect(() => {
  //   fetchWebsites();
  // }, [addWebClicked]);

  const fetchWebsites = async () => {
    try {
      const res = await axios.get(`${sourceUrl}/websiteTracking/listAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setWebSite(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddWebClicked = () => {
    setAddWebClicked(!addWebClicked);
  };

  // const trackCount = async () => {
  //   try {
  //     const res = await axios.get(`${sourceUrl}/websiteTracking/count`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     // No need for res.json(), axios already parses the response as JSON.
  //     return res.data; // Ensure the response has a data field
  //   } catch (error) {
  //     console.log(error);
  //     return null; // Return null in case of error to avoid undefined accesses
  //   }
  // };
  
  
  
  useEffect(()=>{
    if(currentHistoryId){
      setCurrentWebsiteLinkedId(null)
      setHoveredItemId(null)
    }
  },[currentHistoryId])

  // const handleAccountInfoClick = () =>{
  //   AccountInfoClick()
  // }
  

  const getSingleWebTrackDetails = async (id) => {
    setCurrentWebsiteLinkedId(id.linkId)
    setHoveredItemId(id.linkId)
    setCurrentHistoryId(null)
    setIswebTrackClicked(true);
    setSelectedWeb(id.displayName);
    // try {
    //   const res = await axios.get(`${sourceUrl}/websiteTracking/get/${id}`,{
    //     headers:{
    //       "Authorization":`Bearer ${token}`,
    //       "Content-Type":"application/json"
    //     }
    //   })
    //   const responseData = await res.json();
    //   return responseData.data ;
    // } catch (error) {
    //   console.log(error)
    // }
  }

  const HandleTrackWebSiteClick = async () =>{
    navigate('/workspace/trackwithmodalx')
      setIswebTrackClicked(true);
      setCurrentHistoryId(null)
    setCurrentWebsiteLinkedId(null)
  }

  const AlldeleteFunc = async () => {
    setAllDelete(true)
    // try {
    //   const response = await fetch(`${sourceUrl}/threads/deleteall`, {
    //     method: "DELETE",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Authorization": `Bearer ${token}`
    //     },
    //   });

    //   const responseData = await response.json();

    //   if (responseData.status) {
    //     handleNewChat();
    //   } else {
    //     alert("Delete all projects operation has been failed");
    //   }
    // } catch (error) {
    //   console.error("Thread creation error:", error);
    //   return null;
    // }
    // setAllDelete(false);
  }

  const createThreadId = async () => {
    try {
      const response = await fetch(`${sourceUrl}/threads`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      const responseData = await response.json();

      if (responseData.status) {
        const newThreadId = responseData.data.match(/(.*)/)[1];
        setThreadId(newThreadId);
        localStorage.setItem('threadId', newThreadId); // Store the new threadId in localStorage
        return newThreadId;
      } else {
        throw new Error("Thread creation failed");
      }
    } catch (error) {
      console.error("Thread creation error:", error);
      return null;
    }
  }

  const handleNewChat = async () => {
    if (window.innerWidth < 1281) {
      setShowLeftsideBar(false)
    }
    ButtonClick("new_project_click", "new Project")
    setIswebTrackClicked(false);
    setCurrentWebsiteLinkedId(null);
    setShowSuggested(true)
    clearThread();
    setThreadFiles([]);
    setHistoryFiles([]);
    localStorage.removeItem("current_thread_id");
    localStorage.removeItem("current_history_id");
    const newThreadId = await createThreadId();
    setThreadId(newThreadId);
    localStorage.setItem('current_thread_id',newThreadId);
    navigate(`/workspace/${newThreadId}`)
    CreateNewProjectConfirmation(newThreadId)
    trackButtonEvent("button_click_New_Project", {
      button_name: "New Project",
      button_position: "Top-left",
    });
    
  };
  const handleToggle = () => {
    setToggleLogout(true)

  }
  const closeSideBar = () => {
    setShowLeftsideBar(false)
    if (currentThread.length === 0) {
      setShowSuggested(true)
    }


  }

  const handleScroll = (e) => {
    const boxElement = e.target;
    if (boxElement.scrollTop > 0) {
      boxElement.classList.add('scrolled');
    } else {
      boxElement.classList.remove('scrolled');
    }
  };


  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     try {
  //       const res = await axios.get(`${sourceUrl}/websiteTracking/count`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       if (res.data.data.count > 0) {
  //         setNotificationCount(res.data.data.count);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching website count:', error);
  //     }
  //   }, 600000);

  //   return () => clearInterval(interval);
  // }, []);


  const trackCount = async () => {
    try {
      const res = await axios.get(`${sourceUrl}/v1/websiteTrackings/count`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setNotificationCount(res?.data?.data?.count || 0);
    } catch (error) {
      console.error("Error fetching website count:", error);
    }
  };

  useEffect(() => {
    trackCount();
    const interval = setInterval(trackCount, 900000);
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   const box = document.getElementById('scrollBox');
  //   if (box) {
  //     box.addEventListener('scroll', handleScroll);
  //   }
  //   return () => {
  //     if (box) box.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  // console.log(openWebsiteTrackModal, "================openWebsiteTrackModallllllllllllllllllllllllll==================", website)

  // const scrollContainerRef = useRef(null); // Ref for the scrollable container

  // useEffect(() => {
  //   // Scroll to the bottom whenever the websites array changes
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
  //   }
  // }, [website.length]);

  return (
    <Box
      sx={{
        width: "282px",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        // p: 2,
        bgcolor: theme === "dark" ? darkTheme_bg_color : "grey.300",
        color: theme === "dark" ? "white" : "black",
      }}
    >

      <Box sx={{ mb: 2, margin: "0 auto", paddingTop: '20px',filter: !isLoggedIn ? "blur(4px)" : "none",pointerEvents: !isLoggedIn ? "none" : "auto", }}>
        <Box
          onClick={closeSideBar}
          sx={{

            width: '24px',
            height: '24px',
            marginBottom: '20px',
            display: mobileView ? 'block' : 'none'

          }}><MenuIcon />

        </Box >
        <Link to='/workspace' style={{textDecoration:"none", color:"inherit"}}>
          <Button
            variant="outlined"
            onClick={handleNewChat}
            // disabled={!isLoggedIn}
            disabled={waitThread || fileProcessing ||!isLoggedIn}
            sx={{
              gap: "8px",
              padding: "10px 0px 10px 0px",
              fontSize: "16px",
              width: "242px",
              textTransform: "none",
              // bgcolor: New_Project_Button_bg_color,
              // bgcolor : '#F6BE6B', 
              bgcolor: waitThread || fileProcessing?'rgba(230, 224, 233, 0.12)':'#F6BE6B',           
              color: waitThread || fileProcessing?'#E6E0E9':'#17182C',
              fontWeight: "600",
              // height: "40px",
              fontFamily: "Inter, sans-serif",
              border : 'none',
              "&:hover": {
                bgcolor: "#ffd9aa",
                border : 'none'
              },
              "&.MuiButton-outlined": {
                color: waitThread || fileProcessing 
                  ? '#E6E0E9' 
                  : '#17182C',
              },
            }}
            >
            {/* <img src={plusIcon} alt="plus icon" /> */}
            <AddIcon sx={{ 
                  color: waitThread || fileProcessing 
                  ? '#E6E0E9' 
                  : '#17182C',}}/>
            New Project
          </Button>
        </Link>
      </Box>

      {/* <Box sx={{ flexGrow: 1, overflowY: 'scroll', mt: 3, paddingLeft : mobileView ? '7px' : '20px',
           
            '&::-webkit-scrollbar': {
              width : '10px',
              // height : '60px'
            },
            '&::-webkit-scrollbar-thumb': {
              bgcolor: 'rgba(255, 255, 255, 0.2)',
              borderRadius : '5px',
              height : '60px'
              
            },

       }}>
        <ThreadHistory />
      </Box> */}

      <Box onWheel={(e) => {e.stopPropagation()}}
        id="scrollBox"
        sx={{
          flexGrow: 1,
          overflowY: 'scroll',
          mt: 3, // Default margin
          paddingLeft: mobileView ? '7px' : '20px',
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            bgcolor: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '5px',
            height: '60px',
          },
          // Styling for the scrolled state
          '&.scrolled': {
            mt: 0,
          },
          filter: !isLoggedIn ? "blur(4px)" : "none",
          pointerEvents: !isLoggedIn ? "none" : "auto",
        }}
      >
        <ThreadHistory />
      </Box>

      {/* Model Pro Section */}
      {/* 
      <Box sx={{
          width: '242px',
          height: '36px',
          position: 'absolute', // To use top and left properties
          top: '420px',
          left: '20px',
          border : '1px solid rgba(255, 255, 255, 0.05)',
          gap : '12px',
          borderRadius : '8px'
  
      }}>
        
      </Box> */}

      {
        <Box sx={{padding: "0px 20px", paddingTop:"12px" , margin:"0px 0px",borderTop:"1px solid rgba(255, 255, 255, 0.10)",filter: !isLoggedIn ? "blur(4px)" : "none",pointerEvents: !isLoggedIn ? "none" : "auto",}}>

          {/* <Button
            sx={{
              display: 'flex',
              flexDirection: "row",
              textTransform: "none",
              padding: '12px 12px',
              gap: "8px",
              color: theme === "dark" ? "#FFFFFF" : "black",
              justifyContent: "flex-start",
              borderRadius: "8px",
              fontSize:'14px',
              border:"1px solid rgba(255, 255, 255, 0.05)",
              margin:"0px 0px",
              width:"100%",
            }}
            onClick={HandleTrackWebSiteClick}
          >
            <CachedRoundedIcon />
            <Box>Track with ModalX</Box>
          </Button> */}
          <Button
            sx={{
              display: 'flex',
              flexDirection: "row",
              textTransform: "none",
              padding: '12px 12px',
              gap: "8px",
              color: theme === "dark" ? "#FFFFFF" : "black",
              justifyContent: "space-between",  // Adjusted to space between items
              alignItems: "center",  // Centers items vertically
              borderRadius: "8px",
              fontSize:'14px',
              border:"1px solid rgba(255, 255, 255, 0.05)",
              margin:"0px 0px",
              width:"100%",
              bgcolor : iswebTrackClicked ? '#1F233E' : '',
              '&:hover':{
                bgcolor :'#282D51',
              }
            }}
            onClick={HandleTrackWebSiteClick}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
              <CachedRoundedIcon />
              <Box>Track with ModalX</Box>
            </Box>
            {notificationCount > 0 && <Box
              sx={{
                width: '32px',
                height: '20px',
                backgroundColor: '#CAC4D0',
                borderRadius: '12px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize:'12px',
                fontWeight:'600',
                color:'#17182C',
                fontFamily:'Inter'
              }}
            >
              {
                notificationCount <= 99 ?  notificationCount : "99+"
              }
             
            </Box>}
          </Button>


          {/* <Box sx={{ color: theme === "dark" ? "#FFFFFF" : "black", justifyContent: "flex-start", borderRadius: "8px", marginTop:"12px",  backgroundColor: "transparent",}}> 
              <Button onClick={() => { setOpenWebsiteTrackModal(true) }} sx={{ display: 'flex', flexDirection: "row", textTransform: "none", padding: '8px 16px 8px 16px', gap: "10px", backgroundColor: "transparent", }}>
                <Box sx={{scale:'0.8'}}>
                  <AddIcon sx={{ color: theme === "dark" ? "#FFFFFF" : "black", }} />
                </Box>
                <Box sx={{
                  textTransform: "none",
                  color: theme === "dark" ? "#FFFFFF" : "black",
                  fontSize:'12px'
                }}>Add Website</Box>
              </Button>
            </Box> */}
          {/* <Box sx={{ padding: "0px 0px" , margin:"0px 0px"}}>           
            <Box
                  ref={scrollContainerRef} // Attach the ref to the container
                  sx={{
                    maxHeight: "120px", // Approx height for 2 items, adjust based on actual item height
                    overflowY: "auto",   // Enables vertical scrolling when content exceeds maxHeight
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#aaa",
                      borderRadius: "4px",
                    },
                    // padding: "12px 0", // Padding for the scroll container
                  }}
                >
                  {website.length > 0 && website.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          padding: "8px 12px",
                          gap: "12px",
                          cursor: "pointer",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          "&:hover": { bgcolor: HoveredChat_bg_color },
                          borderRadius: "8px",
                          backgroundColor:
                          currentWebsiteLinkedId == item.linkId
                          ? theme == "dark"
                          ? selected_chat_bg_color
                          : "white"
                          : hoveredItemId == item.linkId
                          ? theme == "dark"
                          ? hovered_chat_bg_color
                          : "white"
                          : "",
                        }}
                        onClick={() => getSingleWebTrackDetails(item)}
                      >
                        <Avatar
                          alt="icon"
                          src={item.websiteLogoUrl?.length ? item.websiteLogoUrl : DefaultIconFor_Websites}
                          sx={{ width: "24px", height: "24px" }}
                        />
                        <Button
                          sx={{
                            padding:"0px, 0px",
                            display: "flex",
                            flexDirection: "row",
                            textTransform: "none",
                            gap: "10px",
                            color: theme === "dark" ? "#FFFFFF" : "black",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "150px",
                            justifyContent: "flex-start",
                            fontSize: '14px',
                          }}
                        >
                          {item?.displayName}
                        </Button>
                      </Box>
                    );
                  })}
                </Box>

            
          </Box> */}


        </Box>
      }

      {isLoggedIn && <Box
        sx={{
          // mt: 3,
          // pt: 3,
          // borderTop: `1px solid ${theme === "dark" ? "#343a40" : "black"}`,
          // borderTop : '1px solid red',
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          marginBottom:"20px",
        }}
      >

        {
          subscribe ?
            <Box 
            onClick={()=>{NavigationClick("Upgrade Plan")
              upgradePlan()}}
             sx={{
              margin: mobileView ? '0px 10px 0px 10px' : '12px 0px 0px 20px',
              padding: "12px 14px",
              maxWidth: "242px",
              height: "52px",
              borderRadius: "8px",
              // border: "1px solid rgba(255, 255, 255, 0.05)",
              display: "flex",
              marginBottom: '5px',
              textTransform: "none",
              "&:hover": { bgcolor: HoveredChat_bg_color },
            }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  // width: "24px",
                  // height: "24px",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                  image={logo}
                  alt="logo"
                />
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}
                >
                  ModalX Pro
                </Typography>

              </Box>
            </Box>
            :
            <Button onClick={()=>{NavigationClick("Upgrade Plan")
                                  upgradePlan()
             }}
              sx={{
                margin: mobileView ? "12px 10px 0px 10px" : '12px 20px 0px 20px',
                maxWidth: "100%",
                height: "52px",
                borderRadius: "8px",
                // border: "1px solid rgba(255, 255, 255, 0.05)",
                display: "flex",
                justifyContent: "space-around",
                textTransform: "none",
                "&:hover": { bgcolor: HoveredChat_bg_color },
              }}
            >
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  // bgcolor: "red",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: '100%',
                    height: '100%'
                  }}
                  image={logo}
                  alt="logo"
                />
              </Box>
              <Box
                sx={{
                  width: "182px",
                  height: "40px",
                  // bgcolor : 'red'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}
                >
                  Upgrade Plan
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "left",
                    color: "#FFFFFF",
                    opacity: "60%",
                  }}
                >
                  Get unlimited outputs and more
                </Typography>
              </Box>
            </Button>
        }
        <Button
          sx={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center",
            textTransform: "none",
            margin: mobileView ? '5px 10px 0px 10px' : '12px 20px 0px 20px',
            marginBottom: '5px',
            borderRadius:"8px",
            overflow: 'hidden',
            padding: '15px 12px 15px 12px',
            gap: "10px",
            height:"48px",
            color: theme === "dark" ? "#FFFFFF" : "black",
            bgcolor: moreoption ? "#1F233E" : "",
            "&:hover": { bgcolor: HoveredChat_bg_color },
          }}
          onClick={(event)=> setMoreOption(event.currentTarget)}
        >
        <Box sx={{display:"flex"}}>
        <Box sx={{display:"flex", color:"#17182C",bgcolor: "#CEC6E2",width:"24px",height:"24px",borderRadius:"50%" }}>
        <Box sx={{ fontFamily:"Inter", fontStyle:"normal", fontSize:"14px", fontWeight:600, letterSpacing:"0.1px",textAlign: "center",width:"24px",height:"24px"}}>{initials}</Box></Box>
        <Box sx={{color:"#ffffff", marginLeft:"8px"}}>
          {userEmail?.length > 16 ? `${userEmail?.substring(0, 16)}...` : userEmail}
        </Box>
        </Box>
        <Box sx={{marginLeft:""}}>
          {
            moreoption ?  <ArrowDropUpIcon/> : <ArrowDropDownIcon/> 
          }
          
        </Box>
        </Button>
        {/* <Button
          onClick={AlldeleteFunc}
          sx={{
            // marginLeft : '20px',
            // margin : '2px 20px 0px 10px',
            margin: mobileView ? '2px 20px 0px 10px' : '0px 0px 0px 20px',
            width: '242px',
            // height : '48px',
            display: "flex",
            justifyContent: 'space-around',
            textTransform: 'none',
            marginBottom: '5px',
            padding: '15px 12px 15px 12px',
            "&:hover": { bgcolor: HoveredChat_bg_color },

          }}>
          <Box sx={{
            width: '16px',
            height: '16px',
            marginLeft: '-6px'

          }}>
            <CardMedia
              component="img"
              alt="Image description"

              image={deleteIcon}
            />

          </Box>
          <Box sx={{
            width: '182px',
            height: '20px',



          }}>
            <Typography sx={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'left',
              color: '#FFFFFF',
            }}>Delete all projects</Typography>

          </Box>

        </Button>
        <Button
          sx={{
            margin: mobileView ? '0px 20px 20px 10px' : '0px 0px 20px 20px',
            // margin : '0px 20px 20px 10px',
            fontFamily: 'Inter',
            width: '242px',
            fontSize: '14px',
            fontWeight: 400,
            textTransform: "none",
            gap: "10px",
            color: theme === "dark" ? "#FFFFFF" : "black",
            justifyContent: "flex-start",
            "&:hover": { bgcolor: HoveredChat_bg_color },
            padding: '15px 12px 15px 12px'
          }}
          onClick={handleToggle}
        >
          <LogoutOutlinedIcon />
          Log out
        </Button> */}
      </Box>}
      {!isLoggedIn && 
      <Box 
       sx={{
        padding:'20px',
        width:'100%',
        height:'132px',
        display:'flex',
        flexDirection:'column',
        gap:'12px',
       }}
      >
        <Button 
        sx={{
          // width:'242px',
          textTransform:'none',
          bgcolor:button_bg_color_for_New_Project_dm,
          "&:hover": {
                bgcolor: "#ffd9aa",
                border : 'none'
          },
          color:'#17182C',
          fontSize:'16px',
          fontWeight:600,
          fontFamily:'inter',
        }}
        onClick={() => {
          navigate("/signup");
        }}
        >
          Sign up
        </Button>
        <Button 
        sx={{
          // width:'242px',
          textTransform:'none',
          color:'#F6BE6A',
          fontSize:'16px',
          fontWeight:600,
          fontFamily:'inter',
          border:'2px solid #F6BE6A'
        }}
        onClick={() => {
          navigate("/login");
        }}
        >
          Log in
        </Button>
      </Box>
      }

      {/* <UserProfile
        showModal={showProfileModal}
        setShowModal={setShowProfileModal}
      /> */}
      {/* <TrackWebsiteModal
        websites={website}
        onAddWebClicked={handleAddWebClicked}
      /> */}
      <SmallModalForLogout moreoption={moreoption} setMoreOption={setMoreOption} handleToggle={handleToggle} AlldeleteFunc={AlldeleteFunc} initials={initials} userName={userName} subscribe={subscribe}/>
    </Box>
  );
};

export default SidebarContent;

const SmallModalForLogout = ({moreoption, setMoreOption, handleToggle, AlldeleteFunc, initials, userName, subscribe}) =>{

  const {userInfo} = useWorkspace();
  const { upgradePlan } = useCreditRecharge()

  const handleClose = () =>{
    setMoreOption(!moreoption)
  }

  return(
    <div>
      <Menu
       anchorEl={moreoption}
       open={Boolean(moreoption)}
       onClose={handleClose}
       PaperProps={{
         style: {
          // position: "absolute",
          backgroundColor: '#1F233E',
          color: 'white',
          padding: '4px',
          borderRadius: '8px',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          marginLeft:"5px"
         },
       }}
       anchorOrigin={{
        vertical: 'top',       // Open from the top of the button
        horizontal: 'left',  // Align horizontally to the center of the button
      }}
      transformOrigin={{
        vertical: 'bottom',    // The menu will "pop" up from the bottom of its anchor point
        horizontal: 'center',  // Align horizontally to the center of the menu
      }}
      >
        <Box 
        sx={{
          display:"flex",
          alignItems:"center",
          flexDirection:"row",
          rowGap:"12px",
          borderRadius:"4px",
          padding:"6px 8px 6px 8px",
        }}>
        <Box sx={{display:"flex", justifyContent:"center", alignItems:"center",width:"30px",height:"30px",color:"#17182C",bgcolor:"#CEC6E2",borderRadius:"50%" }}>
        <Box sx={{ fontFamily:"Inter", fontStyle:"normal", fontSize:"14px", fontWeight:600,lineHeight:"20px", letterSpacing:"0.1px" }}>{initials}</Box>
        </Box>
        <Box>
        <Typography sx={{marginLeft:"12px", fontFamily:"Inter", fontSize:"14px", fontWeight:500 ,fontStyle:"normal", lineHeight:"20px",letterSpacing:"0.1px" ,marginBottom:"2px"}}>{userInfo?.firstName && userInfo?.lastName ? `${userInfo?.firstName} ${userInfo?.lastName}` : `${userInfo?.email.split('@')[0]}`}</Typography>
        {
          subscribe ?       
          <Box>
          <Typography sx={{fontFamily:"Inter", fontStyle:"normal", fontSize:"14px", fontWeight:400, color:"#F6BE6B", marginLeft:"12px", lineHeight:"20px", letterSpacing:"0.1px"}}>ModalX Pro</Typography>
          </Box>
          :
        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between" ,alignItems:"center",marginLeft:"12px",fontFamily:"Inter", fontStyle:"normal", fontSize:"14px"}}>
        <Typography sx={{fontWeight:400, color:"rgba(255, 255, 255, 0.60)", fontFamily: "Inter", fontStyle: "normal",fontSize: "14px"}}>Free plan</Typography>
        <Typography sx={{ fontWeight:600, color:"#F6BE6B", marginLeft:"10px", cursor:"pointer", fontFamily: "Inter", fontStyle: "normal",fontSize: "14px"}} onClick={()=>{NavigationClick("Upgrade Plan"); upgradePlan()}}>Upgrade</Typography>
        </Box>
        }
        </Box>
        </Box>

        <Divider sx={{borderColor:"#ffffff"}}/>

        <MenuItem onClick={AlldeleteFunc}
          sx={{
            padding:"16px 12px",
            borderRadius:"4px",
            marginTop:"4px",
            '&:hover': {
              backgroundColor: '#2F355F', 
            },
          }}>
        <ListItemIcon >
        <img alt="del" src={deleteIcon} style={{height:"20px", width:"20px"}}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{
      sx: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
      },
    }} primary="Delete all projects" />
        </MenuItem>

        <MenuItem  onClick={handleToggle}
          sx={{
            padding:"16px 12px",
            borderRadius:"4px",
            '&:hover': {
              backgroundColor: '#2F355F', // Change the background color on hover
            },
          }}>
        <ListItemIcon>
        <LogoutOutlinedIcon sx={{color:"#ffffff"}} />
        </ListItemIcon>
        <ListItemText sx={{width:"174px"}} primaryTypographyProps={{
      sx: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
      },
    }} primary="Log out" />
        </MenuItem>
      </Menu>
    </div>
  )
}
