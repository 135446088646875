import React, { useEffect, useState } from 'react';
import { Box, Button, CardMedia, IconButton, useMediaQuery } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { New_Project_Button_bg_color, SelectedChat_bg_color } from '../../Provider/Color_Provider';
import Image_1 from "../../assets/LandingPage_Items/1_image.png";
import Image_2 from "../../assets/LandingPage_Items/2_Video.png";
import Image_3 from "../../assets/LandingPage_Items/3_audio.png";
import Image_4 from "../../assets/LandingPage_Items/4_WebTrack.png";
import Image_5 from "../../assets/LandingPage_Items/5_Presentation.png";
import Image_6 from "../../assets/LandingPage_Items/6_Blog.png";
import image1 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Image/image1.png";
import image2  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Image/image2.png";
import image3  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Image/image3.png";
import image4  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Image/image4.png";
import image5  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Image/image5.png";
import video1 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/video/video1.png";
import video2 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/video/video2.png";
import video3 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/video/video3.png";
import video4 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/video/video4.png";
import video5 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/video/video5.png";
import ppt1 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Presentation/presentation1.png";
import ppt2 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Presentation/presentation2.png";
import ppt3 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Presentation/presentation3.png";
import ppt4 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Presentation/presentation4.png";
import ppt5 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Presentation/presentation5.png";
import audio1 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Audio/audio1.png";
import audio2  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Audio/audio2.png";
import audio3  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Audio/audio3.png";
import audio4  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Audio/audio4.png";
import audio5  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Audio/audio5.png";
import blog1 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Blog/blog1.png";
import blog2  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Blog/blog2.png";
import blog3  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Blog/blog3.png";
import blog4  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Blog/blog4.png";
import blog5  from "../../assets/LandingPage_Items/LandingPageFeatureIcon/Blog/blog5.png";
import webtrack1 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/WebTrack/webtrack1.png";
import webtrack2 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/WebTrack/webtrack1.png";
import webtrack3 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/WebTrack/webtrack1.png";
import webtrack4 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/WebTrack/webtrack1.png";
import webtrack5 from "../../assets/LandingPage_Items/LandingPageFeatureIcon/WebTrack/webtrack1.png";
import { button_bg_color_for_New_Project_dm, hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from '../../constants/colors';
import { useNavigate } from 'react-router-dom';
import LensIcon from "@mui/icons-material/Lens";

const Features = () => {

   
  const navigate = useNavigate()
  const handleImageGallery = () =>{
    window.scrollTo(0, 0);
    navigate('/imagegallery')
  }
  const handleVideoGallery = () =>{
    window.scrollTo(0, 0);
    navigate('/videogallery')
    
  }
  const handlePresentationGallery = () =>{
    window.scrollTo(0, 0);
    navigate('/presentationgallery')
    
  }

  const handleSpeechGallery = () =>{
    window.scrollTo(0, 0);
    navigate('/audiogallery')
    
  }

  const handleBlogGallery = () =>{
    window.scrollTo(0, 0);
    navigate('/blog');
    
  }

  const handlewebTrack = () =>{
    window.scrollTo(0, 0);
    navigate('/workspace');
    
  }

  const features = [
    {
      id: '1',
      title: 'Image Generation',
      description: 'Generate copyright-free images that effectively communicate your ideas.',
      image: [image1,image2,image3,image4,image5],
      handleRoute : handleImageGallery,
    },
    {
      id: '2',
      title: 'Video Generation',
      description: 'Generate video at scale for diverse audiences.',
      image: [video1,video2,video3,video4,video5],
      handleRoute : handleVideoGallery,
    },
    {
      id: '3',
      title: 'Speech Generation',
      description: 'Generate realistic speech in multiple languages seamlessly.',
      image: [audio1,audio2,audio3,audio4,audio5],
      handleRoute : handleSpeechGallery,
    },
    {
      id: '4',
      title: 'Track webpages',
      description: 'Track updates on any website, get summaries, and generate content about the updates seamlessly',
      image: [webtrack1,webtrack2,webtrack3,webtrack4,webtrack1],
      handleRoute : handlewebTrack,
    },
    {
      id: '5',
      title: 'Presentation Generation',
      description: 'Generate impactful presentations that deliver value.',
      image: [ppt1,ppt2,ppt3,ppt4,ppt5],
      handleRoute : handlePresentationGallery,
    },
    {
      id: '6',
      title: 'Blog Generation',
      description: 'Generate engaging blogs that deliver value.',
      image: [blog1,blog2,blog3,blog4,blog5],
      handleRoute : handleBlogGallery,
    }
  ];

  const isBelow991 = useMediaQuery("(max-width:991px)");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentHoverIndex, setCurrentHoverIndex] = useState(0);
  const totalBoxes = 6; // Total number of boxes in the carousel
  const visibleBoxes = 2.5; // Number of boxes visible at once
  // const boxWidth =  547; // Width of each box in pixels (including margin)
  const boxWidth =  isBelow991 ?  374 : 547; // Width of each box in pixels (including margin)
  const [imageArray, setImageArray] = useState(null);
  const [hoveredFeatureId , setHoveredFeatureId] = useState(null)
  const [intervalId, setIntervalId] = useState(null);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < totalBoxes - visibleBoxes) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

    // Handle mouse enter for hover effect and start the image slider
    const handleHoverEnter = (feature) => {
      setHoveredFeatureId(feature.id);
      setImageArray(feature.image); // Set the image array of the hovered feature
      setCurrentHoverIndex(0); // Reset index on hover
      const id = setInterval(() => {
        setCurrentHoverIndex((prevIndex) => (prevIndex + 1) % feature?.image?.length); // Cycle through images
      }, 2000); // 2 seconds interval to change image
      setIntervalId(id); // Store interval ID
    };
  
    // Handle mouse leave, stop the image slider
    const handleHoverLeave = () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear the interval on mouse leave
      }
      setHoveredFeatureId(null); // Reset hovered feature
      setCurrentHoverIndex(0); // Reset image index
    };

  return (
    <Box sx={{}}>
    <div style={{marginTop:'120px', background:"linear-gradient(147deg, #1F233E 16.27%, #171A2D 75.09%)"}}>
      <p style={{ textAlign: 'center', color: '#F6BE6B',fontSize: isBelow991?'12px':"16px", paddingTop: "80px" }}>Features</p>
      <div style={{ maxWidth: '1072px', margin: 'auto', textAlign: 'center' }}>
        <p style={{ fontSize: isBelow991?'28px':'48px', marginTop: isBelow991?'24px':'-0px' }}>
          The AI toolkit for every business need
        </p>
      </div>
      <Box display="flex" alignItems="center" justifyContent="center" mt={4} sx={{ marginTop: isBelow991?'100px':'138px' }}>
        <Box
          overflow="hidden"
          width={`${visibleBoxes * boxWidth}px`}
          display="flex"
          position="relative"
        >
          <Box
            
            sx={{
              display:"flex",
              transform: `translateX(-${currentIndex * boxWidth}px)`,
              transitionProperty: "transform",
              transitionDuration: "0.5s",
              transitionTimingFunction: "ease-in-out", // Smooth transition
              columnGap:"30px"
            }}
          >
            {features.map((feature, index) => (
              
              <Box  onMouseEnter={() => handleHoverEnter(feature)}
              onMouseLeave={(e) => handleHoverLeave()}
                key={feature.id}
                width={isBelow991?'358px':"547px"}
                height={isBelow991?'356px':"447px"}
                bgcolor={selected_chat_bg_color_dm}
                borderRadius="8px"
                // marginRight="30px"
                boxShadow={2}
                flexShrink={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection='column'
                p={2}
                sx={{
                  "&:hover": {
                  backgroundColor: "#282D51CC",
                  cursor: "pointer",
                },
                }}
              >
                <div style={{ textAlign: 'start', width: isBelow991?'318px':'480px' }}>
                  <p style={{ fontSize: isBelow991?'20px':'24px', marginBottom: '16px', color:"#F6F6F7" }}>{feature.title}</p>
                  <p style={{ marginBottom: isBelow991?'14px':'16px', height:"48px", opacity:0.6, fontWeight:400 }}>{feature.description}</p>
                  {/* <img  src={hoveredFeatureId === feature?.id ? imageArray[currentHoverIndex] : feature.image[0] } alt={feature.title} style={{ width: '100%', height:"" ,borderRadius: '8px', transition: "all 0.5s ease-in-out"}} /> */}
                  <CardMedia
                        component="img"
                        image={hoveredFeatureId === feature?.id ? imageArray[currentHoverIndex] : feature.image[0]}
                        alt={feature.title}
                        sx={{ width: '100%', height:"auto" ,borderRadius: '8px'}}
                      />
                </div>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  pr={2}
                  mt={2}
                >
                <Box sx={{width:"",height:"", display : "flex" , flexDirection:"row", columnGap:"10px", paddingLeft:"16px" }}>
                {imageArray &&
                  imageArray?.map((item, idx) => {
                    return (
                      <LensIcon
                        sx={{
                          color: idx === currentHoverIndex? "white" : "rgba(74, 80, 118, 1)",
                          width: idx === currentHoverIndex ?  "12px" : "10px",
                          height: idx === currentHoverIndex ?  "12px" : "10px",
                          display: feature.id === hoveredFeatureId ? "flex" : "none",
                          // transition: "all 0.3s ease-in-out", 
                          transitionProperty: "transform",
                          transitionDuration: "0.9s",
                          transitionTimingFunction: "ease-in-out", // Smooth transition
                        }}
                      />
                    );
                  })}
                  </Box>
                  <Button 
                    onClick={feature.handleRoute}
                    
                    sx={{textTransform:'none',
                      fontSize:isBelow991?'14px':'16px',
                      color:'#17182C',
                      bgcolor:button_bg_color_for_New_Project_dm,
                      width:isBelow991?'109px':'145px',
                      height:'40px',
                      fontWeight:'600',
                      '&:hover': {
                        backgroundColor: '#ffd9aa',
                      },}}
                  >
                   { index === 3 ? "Try it now" : "View Gallery" } 
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" sx={{maxWidth:'112px',height:'48px',display:'flex',justifyContent:'space-between',margin:'48px auto'}}>
          <IconButton
            onClick={handlePrevClick}
            disabled={currentIndex === 0}
            disableRipple
            sx={{
              bgcolor: '#1F233E',
              color: 'white', // Set the icon color
              '&:hover': {
                backgroundColor: '#1B1E36',
              },
              '&:disabled': {
                bgcolor: '#1F233E', // Maintain the same background color when disabled
                color: 'grey', // Change color to indicate disabled state
              },
              width:'48px',
              height:'49px'
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            onClick={handleNextClick}
            disabled={currentIndex >= totalBoxes - visibleBoxes}
            disableRipple
            sx={{
              bgcolor: '#1F233E',
              color: 'white', // Set the icon color
              '&:hover': {
                backgroundColor: '#1B1E36',
              },
              '&:disabled': {
                bgcolor: '#1F233E', // Maintain the same background color when disabled
                color: 'grey', // Change color to indicate disabled state
              },
              width:'48px',
              height:'49px'
            }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>

    </div>
    </Box>
  );
};

export default Features;


