import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  useMediaQuery,
} from "@mui/material";
import {
  auth,
  GoogleAuthProvider,
  signInWithPopup,
} from "../../config/firebase.config";
import { ErrorEvent, SignUpEvent } from "../../config/analytics";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import sourceUrl from "../../api/Source.api";
import ModalXLogo from "../../assets/signup/loginPageLogo.png";
import { useWorkspace } from "../../contexts/WorkspaceContext";
import { disposableEmailProviders } from "../../constants/disposableEmailDomains";
import {
  button_bg_color_for_New_Project_dm,
  uploadFiles_button_default_bg_color,
  uploadFiles_button_hovered_bg_color,
} from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import CommonToast from "../Common_Components/CommonToast";

const SignUpLogInLayout = ({ setCurrentWindow, route }) => {
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [notifVariant, setNotifVariant] = useState("");
  const [notifMsg, setNotifMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const { email, setEmail } = useWorkspace();
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const handleCloseToast = () => {
    setOpen(false);
  };

  const isDisposableEmail = (email) => {
    const emailDomain = email.split("@")[1];
    return disposableEmailProviders.includes(emailDomain);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (isDisposableEmail(newEmail)) {
      setIsInvalidEmail(true);
    } else {
      setIsInvalidEmail(false);
    }
  };

  const handleContinueButtonOfCheckEmail = async (email) => {
    //logic to what window open signup next window or login after verfy from firebase
    if (isDisposableEmail(email)) {
      setNotifMsg(
        "We do not accept temporary or disposable email addresses. Please use a permanent email."
      );
      setNotifVariant("error");
      setOpen(true);
      return;
    }
    if (email === "") {
      setNotifVariant("error");
      setOpen(true);
      setNotifMsg("Please enter your email");
      return;
    } else {
      try {
        const res = await axios.get(`${sourceUrl}/check-email?email=${email}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(res?.data?.data?.exists, "response user exist or not");
        if (res?.data?.data?.exists) {
          setCurrentWindow(2);
          navigate("/login");
        } else {
          navigate("/signup");
          setCurrentWindow(2);
        }
      } catch (error) {
        ErrorEvent(error, error.message);
        setNotifMsg(error.message || "Verification failed");
        setNotifVariant("error");
        setOpen(true);
        console.log("error", error);
      }
    }
  };

  const verifyUser = async (email) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${sourceUrl}/login/verify`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === false) {
        throw new Error("Only invited users will be able to use Modalx.ai");
      }
      return true;
    } catch (error) {
      // setNotifMsg(error.message || "Verification failed");
      // setNotifVariant("error");
      // setShowNotif(true);
      // setOpen(true);
      return false;
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      // ===================== Invited users check =====================
      // const isVerified = await verifyUser(result.user.email);
      // if (!isVerified) return;

      // ===============================================================
      if (result.user.emailVerified) {
        {
          result.user &&
            result.user.accessToken &&
            (await axios.post(
              `${sourceUrl}/profile`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${result.user.accessToken}`,
                },
              }
            ));
        }

        localStorage.setItem("token", result.user.accessToken);
        const isVerified = await verifyUser(result.user.email);
        if (!isVerified) {
          await axios.post(
            `${sourceUrl}/profile`,
            {},
            {
              headers: {
                Authorization: `Bearer ${result.user.accessToken}`,
              },
            }
          );
          return;
        }
        localStorage.setItem("current_thread_id", "");
        localStorage.setItem("current_history_id", "");
        setUser(result.user.emailVerified);
        navigate("/workspace");
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userEmail", result.user.email);
        window.location.reload();
        SignUpEvent("user_signup", "continue_with_google");
      } else {
        localStorage.setItem("isLoggedIn", "");
      }
    } catch (error) {
      console.error("Error signing up with Google:", error);
      ErrorEvent(error, error.message);
        setNotifMsg(`Error signing up with Google: ${error.message}`);
        setNotifVariant("error");
        setOpen(true);
    }
  };

  const handleOpenSignUpModal = () => {
    navigate("/signup");
  };

  const handleOpenLoginModal = () => {
    navigate("/login");
  };

  const customTextFieldStylesForInvalid = (isInvalid) => {
    return {
      "& .MuiInputLabel-root": {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color: "rgba(255, 255, 255, 0.80)",
        "&.Mui-focused": {
          color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80)",
        },
      },
      "& .MuiInputBase-input": {
        color: "#FFFFFF",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
        },
        "&:hover fieldset": {
          borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
        },
        "&.Mui-focused fieldset": {
          borderColor: isInvalid
            ? "#F2B8B5 !important"
            : "rgba(255, 255, 255, 0.80) ",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
        color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: isInvalid
          ? "#F2B8B5 !important"
          : "rgba(255, 255, 255, 0.80) ",
      },
    };
  };

  return (
    <div
      style={{
        width: "340px",
        height: isBelow991 ? "" : "100vh",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
      }}
    >
      <Box sx={{ marginTop: isBelow991 && 3 }}>
        <Box
          sx={{
            display: isBelow991 ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "140px",
            marginTop: 7,
          }}
        >
          <img
            src={ModalXLogo}
            alt={"logo"}
            style={{ width: "163px", height: "51px" }}
          />
        </Box>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "Inter",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "20px",
            color: "#FFFFFF",
            marginBottom: "40px",
            alignSelf: "center",
            whiteSpace: "nowrap",
            textAlign: "center",
          }}
        >
          {route === "login" ? "Welcome back" : "Create an account"}
        </Typography>
        <FormControl
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            type="email"
            onChange={(e) => {
              handleEmailChange(e);
            }}
            value={email}
            id="outlined-basic"
            label="Email address*"
            variant="outlined"
            sx={() => customTextFieldStylesForInvalid(isInvalidEmail)}
            style={{
              width: "100%",
              maxWidth: "340px",
              height: "56px",
              color: "#FFFFFF",
              backgroundColor: "#232746",
              borderRadius: "8px",
              boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
              placeholderfontsize: "16px",
            }}
          />
          <FormHelperText
            sx={{
              color: isInvalidEmail
                ? "#F2B8B5"
                : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "16px",
              alignSelf: "start",

              margin: {
                md: "4px 0px 14px 0px",
                lg: "4px 16px 14px 0px",
                xl: "4px 16px 14px 0px",
              },
            }}
          >
            {isInvalidEmail
              ? "Email address is invalid"
              : route !== "login" &&
                "For the best experience, use your work email"}
          </FormHelperText>
          <Box
            sx={
              isInvalidEmail
                ? {
                    marginTop: "20px",
                    width: "100%",
                  }
                : {
                    marginTop: route !== "login" ? "20px" : "36px",
                    width: "100%",
                  }
            }
          >
            <Button
              onClick={() => {
                handleContinueButtonOfCheckEmail(email);
              }}
              variant="outlined"
              sx={{
                gap: "8px",
                padding: "10px 24px 10px 16px",
                fontSize: "16px",
                width: "100%",
                maxWidth: "340px",
                textTransform: "none",
                bgcolor: button_bg_color_for_New_Project_dm,
                color: "black",
                fontWeight: "600",
                height: "40px",
                fontFamily: "Inter, sans-serif",
                border: "none",
                "&:hover": {
                  bgcolor: "#ffd9aa",
                  border: "none",
                },
              }}
            >
              Continue
            </Button>
            <Typography
              sx={{
                display: "flex",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "20px",
                color: "rgba(255, 255, 255, 0.80);",
                margin: "12px 0px",
                padding: "auto auto",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              OR
            </Typography>
            <Button
              onClick={handleGoogleSignUp}
              variant="outlined"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                padding: "10px 24px 10px 16px",
                fontSize: "16px",
                width: "100%",
                maxWidth: "340px",
                textTransform: "none",
                bgcolor: uploadFiles_button_default_bg_color,
                color: "black",
                fontWeight: "600",
                height: "40px",
                fontFamily: "Inter, sans-serif",
                marginBottom: "60px",
                border: "none",
                "&:hover": {
                  bgcolor: uploadFiles_button_hovered_bg_color,
                  border: "none",
                },
              }}
            >
              <img
                style={{ width: "5%" }}
                src={require(`../../assets/transparent-google-logo-google-logo-with-multicolored-g-and-1710875781697.png`)}
                alt="G"
              />
              Continue with Google
            </Button>
          </Box>

          <Box>
            {
              <FormHelperText
                sx={{
                  width: "100%",
                  maxWidth: "340px",
                  color:
                    "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px",
                  margin: "4px 0px 14px 0px",
                  alignSelf: "center",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                {route === "login"
                  ? "Don’t have an account yet? "
                  : "Already have an account? "}
                <span
                  onClick={
                    route === "login"
                      ? handleOpenSignUpModal
                      : handleOpenLoginModal
                  }
                  style={{
                    textDecorationLine: "underline",
                    cursor: "pointer",
                  }}
                >
                  {route === "login" ? "Sign up" : "Log in"}
                </span>
              </FormHelperText>
            }
          </Box>
        </FormControl>
      </Box>
      <CommonToast
        open={open}
        onClose={handleCloseToast}
        content={notifMsg}
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={3000}
        iconType={notifVariant}
      />
    </div>
  );
};

export default SignUpLogInLayout;
