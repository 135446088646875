// import React, { useState,useEffect } from "react";
// import { Navbar, Nav, Button, Image } from "react-bootstrap";
// import SignUpLogIn from "./SignUpLogIn";
// import { useNavigate } from "react-router-dom";
// import { useUI } from "../../contexts/UIContext";
// import { auth } from "../../config/firebase.config"; // Import auth from Firebase config
// import GetLogo from "../GetLogo";
// import { useMediaQuery } from "react-responsive";

// function NavigationBar({ showLogin = false }) {
//   const navigate = useNavigate();
//   const theme = localStorage.getItem('theme');
//   const isLoggedIn = localStorage.getItem('isLoggedIn') || "";
//   const { toggleTheme, currentTheme } = useUI();
//   const logoSrc = GetLogo();
//   const [showLoginModal, setShowLoginModal] = useState(false);
//   const [showSignUpModal, setShowSignUpModal] = useState(false);
//   const [emailSent, setEmailSent] = useState(false);
//   const isXsOrSm = useMediaQuery({ maxWidth: 992 });

//   useEffect(() => {
//     if(!isLoggedIn){
//       setShowSignUpModal(true);
//     }
//   }, [])

//   // Function to handle logout
//   const handleLogout = () => {
//     auth.signOut() // Sign out the current user
//       .then(() => {
//         // Clear localStorage or any other logout actions
//         localStorage.removeItem('isLoggedIn');
//         localStorage.removeItem('userEmail');
//         localStorage.setItem('token', '');
//         navigate("/"); // Redirect to home page after logout
//       })
//       .catch((error) => {
//         console.error("Error logging out:", error);
//         // Handle error, if any
//       });
//   };

//   const handleAccessClick = (current_click) => {
//     if (isLoggedIn) {
//       navigate(current_click);
//     } else {
//       setShowLoginModal(true);
//     }
//   };

//   // Custom inline styles for Navbar.Collapse
//   const collapseStyle = isXsOrSm
//     ? {
//         position: 'absolute',
//         top: '56px',
//         right: '0',
//         backgroundColor: theme === 'dark'?'#363A3E':'#C0C0C0',
//         width: '70%',
//         padding: '1rem',
//         boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
//         zIndex: 1050,
//         maxWidth:'200px',
//         borderRadius:'10px'
//       }
//     : {};

//   return (
//     <div className="border-bottom">
//       <Navbar expand="lg" className="mx-4 position-relative">
//         <Navbar.Brand
//           onClick={() => navigate("/")}
//           style={{ cursor: "pointer" }}
//         >
//           <Image
//             src={logoSrc}
//             loading="lazy"
//             alt="Company Logo"
//             style={{ width: '100px', scale: '2', paddingLeft: '20px' }}
//             className="mb-3"
//           />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" style={collapseStyle}>
//           <Nav className="mr-auto">
//             <Nav.Link onClick={() => navigate("/")} className="ml-2">
//               Home
//             </Nav.Link>
//             <Nav.Link onClick={() => handleAccessClick('/workspace')} className="me-2">
//               Workspace
//             </Nav.Link>
//             <Nav.Link onClick={() => handleAccessClick("/docs")} className="me-2">
//               Docs
//             </Nav.Link>
//             <Nav.Link onClick={() => handleAccessClick("/api")} className="me-2">
//               API
//             </Nav.Link>
//             <Nav.Link onClick={() => handleAccessClick("/blog")} className="me-2">
//               Blog
//             </Nav.Link>
//             {/* <Button
//               onClick={toggleTheme}
//               className="me-2 mb-2"
//               variant={currentTheme === "dark" ? "secondary" : "dark"}
//               style={{ maxWidth: '100px' }}
//             >
//               {currentTheme === "dark" ? "☀️" : "🌙"}
//             </Button> */}
//             {isLoggedIn ?
//               <Button className="me-2 mb-2" onClick={handleLogout} style={{ maxWidth: '100px' }}>Logout</Button> :
//               <SignUpLogIn
//                 showLoginModal={showLoginModal}
//                 setShowLoginModal={setShowLoginModal}
//                 showSignUpModal={showSignUpModal}
//                 setShowSignUpModal={setShowSignUpModal}
//                 setEmailSent={setEmailSent}
//               />
//             }
//           </Nav>
//         </Navbar.Collapse>
//       </Navbar>
//     </div>
//   );
// }

// export default NavigationBar;

// Above one is the latest one 03-08-2024

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Box,
  useMediaQuery,
  CardMedia,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useUI } from "../../contexts/UIContext";
import { auth } from "../../config/firebase.config";
import GetLogo from "../GetLogo";
import SignUpLogIn from "./SignUpLogIn";
import { darkTheme_bg_color } from "../../constants/colors";
import { trackButtonEvent } from "../../config/analytics";
import Signup from "./Signup";
import LandingPageContents from "../LandingPageContents";
import { useAuth } from "../../contexts/AuthContext";
import LogOut from "../../pages/LogOut";

function NavigationBar({ showLogin = false }) {
  const navigate = useNavigate();
  const theme = localStorage.getItem("theme");
  const isLoggedIn = localStorage.getItem("isLoggedIn") || "";
  const { toggleTheme, currentTheme } = useUI();
  const logoSrc = GetLogo();
  // const [showVerificationModal, setShowVerificationModal] = useState(false);
  // const [showLoginModal, setShowLoginModal] = useState(false);
  // const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const isXsOrSm = useMediaQuery("(max-width:991px)");
  // const [menuOpen, setMenuOpen] = useState(false);
  const {showLoginModal,setShowLoginModal,showSignUpModal,setShowSignUpModal,showVerificationModal,setShowVerificationModal,showResetPasswordModal,setShowResetPasswordModal,showResendModal,setShowResendModal,setToggleLogout,menuOpen, setMenuOpen} =useAuth()
  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     setShowSignUpModal(true);
  //   }
  // }, []);

  // useEffect(()=>{
  //   if(!menuOpen && isXsOrSm){
  //     setShowSignUpModal(false)
  //     setShowSignUpModal(false)
  //   }
  // },[menuOpen])

  // useEffect(() => {
  //   if (isXsOrSm) {
  //     if (showSignUpModal || showLoginModal || showVerificationModal || showResetPasswordModal || showResendModal) {
  //       setMenuOpen(true);
  //     }
  //   } else {
  //     // This condition ensures that the modal persists when switching back to a larger screen
  //     if (menuOpen) {
  //       setMenuOpen(false);
  //     }
  //   }
  // }, [isXsOrSm]);

  // const handleLogout = () => {
  //   trackButtonEvent("button_click_logout", {
  //     button_name: "Logout",
  //     button_position: "navbar-right",
  //   });
  //   auth
  //     .signOut()
  //     .then(() => {
  //       localStorage.removeItem("isLoggedIn");
  //       localStorage.removeItem("userEmail");
  //       localStorage.setItem("token", "");
  //       navigate("/");
  //     })
  //     .catch((error) => {
  //       console.error("Error logging out:", error);
  //     });
  // };

  const handleLogout = () =>{
    setToggleLogout(true)
    // console.log('ram')
    // localStorage.clear()
    // window.location.reload()
  }
  

  const handleAccessClick = (current_click) => {
    if (isLoggedIn) {
      navigate(current_click);
    } else {
      setShowLoginModal(true);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const HandleMenuIconClick = () =>{
    setShowLoginModal(false)
    setShowSignUpModal(false)
    setShowVerificationModal(false);
    setShowResetPasswordModal(false)
    setShowResendModal(false)
  }

  const escapeToggle = () => {
    setShowSignUpModal(false);
  };

  // Function to detect key press (e.g., 'Escape' key to toggle)
  const escapeHandleKeyDown = (event) => {
    if (event.key === 'Escape') {
      escapeToggle();
    }
  };

  useEffect(() => {
    // Add keydown event listener
    window.addEventListener('keydown', escapeHandleKeyDown);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', escapeHandleKeyDown);
    };
  }, []);
  console.log(showSignUpModal)

  return (
    <>
    <AppBar
      position="sticky"
      sx={{
        bgcolor: darkTheme_bg_color,
        height: "82.95px",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        boxShadow : 'none'

      }}
    >
      <Toolbar style={{ margin: "auto", width: "100%" }}>
        <Box
          onClick={() => navigate("/")}
          sx={{ cursor: "pointer", width: "179px", height: "50.95px" }}
        >
          <CardMedia component="img" image={logoSrc} alt="Company Logo" />
        </Box>
        {isXsOrSm ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleMenu}
              sx={{ ml: "auto" }}
            >
              {menuOpen ? <CloseIcon onClick={HandleMenuIconClick}/> : <MenuIcon />}
            </IconButton>
            {menuOpen && (
              <Box
             sx={{
               position: "fixed",
               top: "82.95px", // Height of the AppBar
               left: 0,
               width: "100%",
               height: "calc(100% - 82.95px)",
               backgroundColor: theme === "dark" ? darkTheme_bg_color : "#C0C0C0",
               zIndex: 1300,
               padding: "1rem",
               overflowY: "auto",
               margin:'auto',
               display:'flex',
               flexDirection:'column',
               alignItems:'center'
             }}
           >  
             {!showSignUpModal && !showLoginModal &&  !showVerificationModal && !showResetPasswordModal && !showResendModal &&
             <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", textAlign:"center"}}>
             <MenuItem sx={{ fontFamily : 'Inter',
                              fontSize : '20px',
                              fontWeight : 500,
                              color : '#FFFFFFCC',
                              opacity : '80%' ,
                              marginBottom : '28px',
                              marginTop : '48px'


             }} onClick={() => navigate("/")}>Home</MenuItem>
             <MenuItem  sx={{ fontFamily : 'Inter',
                              fontSize : '20px',
                              fontWeight : 500,
                              color : '#FFFFFFCC',
                              opacity : '80%',
                              marginBottom : '28px'
                             }}

              // onClick={() => handleAccessClick("/workspace")}
              onClick={() => navigate("/workspace")}
              >
               Workspace
             </MenuItem>
             {/* <MenuItem onClick={() => handleAccessClick("/docs")}>
               Docs
               
             </MenuItem>
             <MenuItem onClick={() => handleAccessClick("/api")}>API</MenuItem> */}
             <MenuItem  sx={{ fontFamily : 'Inter',
                              fontSize : '20px',
                              fontWeight : 500,
                              color : '#FFFFFFCC',
                              opacity : '80%',
                              marginBottom : '28px'
                             }}
              // onClick={() => handleAccessClick("/blog")}
              onClick={() => navigate("/blog")}
              >
               Blog
             </MenuItem>
             <MenuItem  sx={{ fontFamily : 'Inter',
                              fontSize : '20px',
                              fontWeight : 500,
                              color : '#FFFFFFCC',
                              opacity : '80%',
                              marginBottom : '28px'
                              
                            
                            }}
            //  onClick={() => handleAccessClick("/")}
            onClick={() => navigate("/usecases")}
             >
               Use Cases
             </MenuItem>
             <MenuItem  sx={{ fontFamily : 'Inter',
                              fontSize : '20px',
                              fontWeight : 500,
                              color : '#FFFFFFCC',
                              opacity : '80%',
                              marginBottom : '28px'
                              
                            
                            }}
             onClick={() => handleAccessClick("/pricing")}>
               Pricing
             </MenuItem>
             <MenuItem  sx={{ fontFamily : 'Inter',
                              fontSize : '20px',
                              fontWeight : 500,
                              color : '#FFFFFFCC',
                              opacity : '80%',
                              marginBottom : '90px'
                             }}
              // onClick={() => handleAccessClick("/contact")}
              onClick={() => navigate("/contact")}
              >
               Contact us
             </MenuItem>
             </Box>}
             {isLoggedIn ? (
               <MenuItem onClick={handleLogout}  
               sx={{ fontFamily : 'Inter',
                fontSize : '20px',
                fontWeight : 500,
                color : '#FFFFFFCC',
                opacity : '80%',
               }}>Logout</MenuItem>
             ) : (
                 <SignUpLogIn
                //  showLoginModal={showLoginModal}
                //  setShowLoginModal={setShowLoginModal}
                //  showSignUpModal={showSignUpModal}
                //  setShowSignUpModal={setShowSignUpModal}
                //  setEmailSent={setEmailSent}
                //  showVerificationModal={showVerificationModal}
                //  setShowVerificationModal={setShowVerificationModal}
               /> 
             )}
           </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "space-between",
              alignItems: "center",

            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexGrow: 1,
                maxWidth: "513px",
                margin: "auto",
              }}
            >
              <Button
                color="inherit"
                // onClick={() => handleAccessClick("/workspace")}
                onClick={() => navigate("/workspace")}
                sx={{ textTransform: "none", fontSize: "16px",
                  fontFamily : 'Inter',
                  fontWeight : 500,
                  color : '#FFFFFFCC',
                  opacity : '80%'
                 }}
              >
                Workspace
              </Button>
              <Button
                color="inherit"
                // onClick={() => handleAccessClick("/blog")}
                onClick={() => navigate("/blog")}
                sx={{ textTransform: "none", fontSize: "16px",
                  fontFamily : 'Inter',
                  fontWeight : 500,
                  color : '#FFFFFFCC',
                  opacity : '80%'
                 }}
              >
                Blog
              </Button>
              <Button
                color="inherit"
                // onClick={() => handleAccessClick("/")}
                onClick={() => navigate("/usecases")}
                sx={{ textTransform: "none", fontSize: "16px",
                  fontFamily : 'Inter',
                  fontWeight : 500,
                  color : '#FFFFFFCC',
                  opacity : '80%'
                  
                 }}
              >
                Use Cases
              </Button>
              <Button
                color="inherit"
                // onClick={() => handleAccessClick("/contact")}
                onClick={() => navigate("/pricing")}
                sx={{ textTransform: "none", fontSize: "16px",
                  fontFamily : 'Inter',
                  fontWeight : 500,
                  color : '#FFFFFFCC',
                  opacity : '80%'
                 }}
              >
                Pricing
              </Button>
              <Button
                color="inherit"
                // onClick={() => handleAccessClick("/contact")}
                onClick={() => navigate("/contact")}
                sx={{ textTransform: "none", fontSize: "16px",
                  fontFamily : 'Inter',
                  fontWeight : 500,
                  color : '#FFFFFFCC',
                  opacity : '80%'
                 }}
              >
                Contact us
              </Button>
            </Box>
            <Box
              sx={{
                minWidth: "179px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {isLoggedIn ? (
                <Button
                  color="inherit"
                  onClick={handleLogout}
                  sx={{ textTransform: "none", fontSize: "16px",
                    fontFamily : 'Inter',
                    fontWeight : 500,
                    color : '#FFFFFFCC',
                    opacity : '80%'
                   }}
                >
                  Logout
                </Button>
              ) : (                
                <SignUpLogIn
                //   showLoginModal={showLoginModal}
                //   setShowLoginModal={setShowLoginModal}
                  // showSignUpModal={showSignUpModal}
                //   setShowSignUpModal={setShowSignUpModal}
                //   setEmailSent={setEmailSent}
                //   showVerificationModal={showVerificationModal}
                //  setShowVerificationModal={setShowVerificationModal}
                /> 
              )}
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar> 
    <LogOut/> 
    </> 
  );
}

export default NavigationBar;


// ============================== use below one for testing(to show landing page)=================================

// import React from "react";
// import { Navbar, Nav, Button, Image } from "react-bootstrap";
// import SignUpLogIn from "./SignUpLogIn";
// import { useNavigate } from "react-router-dom";
// import { useUI } from "../../contexts/UIContext";
// import { auth } from "../../config/firebase.config"; // Import auth from Firebase config
// import GetLogo from "../GetLogo";

// function NavigationBar({ showLogin = false }) {
//   const navigate = useNavigate();
//   const isLoggedIn = localStorage.getItem('isLoggedIn') || "" ;
//   const { toggleTheme, currentTheme } = useUI();
//   const logoSrc = GetLogo()
//   // Function to handle logout
//   const handleLogout = () => {
//     auth.signOut() // Sign out the current user
//       .then(() => {
//         // Clear localStorage or any other logout actions
//         localStorage.removeItem('isLoggedIn');
//         localStorage.removeItem('userEmail');
//         localStorage.setItem('token','') ;
//         navigate("/"); // Redirect to home page after logout
//       })
//       .catch((error) => {
//         console.error("Error logging out:", error);
//         // Handle error, if any
//       });
//   };

//   return (
//     <div className="border-bottom">
//       <Navbar expand="lg" className="mx-4">
//         <Navbar.Brand
//           onClick={() => navigate("/")}
//           style={{
//             cursor: "pointer",
//           }}
//         >
//           {/* Modal{" "}
//           <span
//             style={{
//               fontWeight: "bold",
//               color: "#ff4f00",
//               fontSize: "1.2em",
//             }}
//           >
//             X
//           </span> */}
//           <Image
//               src={logoSrc}
//               loading="lazy"
//               alt="Company Logo"
//               style={{width:'100px',scale:'2',paddingLeft:'20px'}}
//               className="mb-3"
//             />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
//           <Nav className="mr-auto">
//             <Nav.Link onClick={() => navigate("/")} className="ml-2">
//               Home
//             </Nav.Link>
//             {/* <Nav.Link onClick={() => navigate("/tools")} className="me-2">
//               Tools
//             </Nav.Link> */}
//             <Nav.Link onClick={() => navigate("/landing")} className="me-2">
//               Workspace
//             </Nav.Link>
//             <Nav.Link onClick={() => navigate("/landing")} className="me-2">
//               Docs
//             </Nav.Link>
//             <Nav.Link onClick={() => navigate("/landing")} className="me-2">
//               API
//             </Nav.Link>
//             <Nav.Link onClick={() => navigate("/landing")} className="me-2">
//               Blog
//             </Nav.Link>
//             <Button
//               onClick={toggleTheme}
//               className="me-2 mb-2"
//               variant={currentTheme === "dark" ? "secondary" : "dark"}
//             >
//               {currentTheme === "dark" ? "☀️" : "🌙"}
//             </Button>
//             {/* {isLoggedIn ?
//               <Button onClick={handleLogout}>Logout</Button> :
//               <SignUpLogIn showLogin={showLogin} />
//             } */}
//             <Button onClick={() => navigate("/landing")} >SignUp</Button>
//           </Nav>
//         </Navbar.Collapse>
//       </Navbar>
//     </div>
//   );
// }

// export default NavigationBar;
