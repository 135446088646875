// import React, { useRef } from "react";
// import { Button, Row, Col, Spinner, Container } from "react-bootstrap";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";


// const PromtInputFileUploader = () => {
//   const { threadFiles, setThreadFiles, uploadFiles,sending } = useWorkspace();
//   const fileInputRef = useRef(null);
//   const theme = localStorage.getItem('theme');
//   const isLoggedIn = localStorage.getItem('isLoggedIn');
//   const handleFileChange = async (event) => {
//     const files = Array.from(event.target.files); // Convert FileList to Array
//     const existingFileNames = threadFiles.map((file) => file.name);

//     // Filter out files that already exist in threadFiles by their names
//     const newFiles = files.filter(
//       (file) => !existingFileNames.includes(file.name)
//     );

//     // Clear the file input after selecting files
//     event.target.value = "";

//     // Map files to upload promises
//     const uploadPromises = newFiles.map(async (file) => {
//       // Map each file to the prescribed JSON format and mark as "uploading"
//       const fileToUpdate = {
//         type: "file",
//         file: file,
//         name: file.name,
//         status: "uploading", // Mark as "uploading" initially
//         fileId: null,
//       };

//       // Update threadFiles with the new file object
//       setThreadFiles((prevThreadFiles) => [...prevThreadFiles, fileToUpdate]);

//       // Upload the file and return fileId
//       const response = await uploadFiles(fileToUpdate);
//       const fileId = response.data.id ;
//       // Update the file object with uploaded state or upload failed
//       setThreadFiles((prevThreadFiles) =>
//         prevThreadFiles.map((prevFile) =>
//           prevFile.name === file.name
//             ? fileId
//               ? { ...prevFile, status: "uploaded", fileId: fileId }
//               : { ...prevFile, status: "upload failed" }
//             : prevFile
//         )
//       );

//       // Return updated file object with fileId
//       return fileId
//         ? { ...fileToUpdate, status: "uploaded", fileId: fileId }
//         : { ...fileToUpdate, status: "upload failed" };
//     });

//     // Wait for all uploads to finish
//     await Promise.all(uploadPromises);

//     // // Update threadFiles with uploaded files
//     // setThreadFiles((prevThreadFiles) =>
//     //   prevThreadFiles.map((prevFile) => {
//     //     const uploadedFile = uploadedFiles.find(
//     //       (file) => file.name === prevFile.name
//     //     );
//     //     return uploadedFile ? uploadedFile : prevFile;
//     //   })
//     // );
//   };

//   const handleRemoveFile = (index) => {
//     const updatedFiles = threadFiles.filter(
//       (_, fileIndex) => fileIndex !== index
//     );
//     setThreadFiles(updatedFiles); // Update the context
//   };

//   const handleFileSelect = () => {
//     fileInputRef.current.click();
//   };

//   return (
//     <Container fluid>
//       <Row style={{ display: "flex", flexDirection: "column",marginBottom:'-10px'}}>
//       <Button
//         variant="outline-primary"
//         onClick={handleFileSelect}
//         className="mb-2 d-flex align-items-center justify-content-start"
//         style={{ backgroundColor:'transparent', transition: "background-color 0.3s",borderColor:'transparent',color:'grey'}}
//         onMouseEnter={(e) => e.target.style.color ='blue'}
//         onMouseLeave={(e) => e.target.style.color ='grey'}
//         disabled={!isLoggedIn}
//         >
//         <i className="bi bi-paperclip pe" style={{scale:'1.3'}}/>
        
//         </Button>

//         <input
//           id="file-upload"
//           type="file"
//           accept=".txt, .docx, .mp3, .pdf, .mp4, .xlsx, video/*"
//           style={{ display: "none" }}
//           ref={fileInputRef}
//           onChange={handleFileChange}
//           multiple
//         />
//       </Row>
//       {!threadFiles.some((file) => file.type === "file") ? (
//         <Row className="text-secondary mb-1">
//           {/* no files selected */}
//         </Row>
//       ) : (
//         // threadFiles.map((file, index) => (
//         //   <div key={index}>
//         //     {file.type === "file" && (
//         //       <Row className="mb-1 px-0 m-0 align-items-center">
//         //         <Col
//         //           xs="10"
//         //           className={
//         //             file.status === "upload failed"
//         //               ? "text-danger p-0 m-0"
//         //               : "p-0 m-0"
//         //           }
//         //           style={{
//         //             overflow: "hidden",
//         //             textOverflow: "ellipsis",
//         //             whiteSpace: "nowrap",
//         //           }}
//         //         >
//         //           {file.name}
//         //         </Col>
//         //         <Col
//         //           xs="2"
//         //           className="m-0 p-0"
//         //           style={{
//         //             display: "flex",
//         //             justifyContent: "end",
//         //             alignItems: "center",
//         //           }}
//         //         >
//         //           {file.status === "uploading" ? (
//         //             <Spinner animation="border" role="status" size="sm">
//         //               <span className="visually-hidden">Loading...</span>
//         //             </Spinner>
//         //           ) : file.status === "upload failed" ? (
//         //             <i
//         //               className="bi bi-exclamation-circle"
//         //               onClick={() => handleRemoveFile(index)}
//         //               style={{ cursor: "pointer" }}
//         //             />
//         //           ) : (
//         //             <i
//         //               className="bi bi-x-lg"
//         //               onClick={() => handleRemoveFile(index)}
//         //               style={{ cursor: "pointer" }}
//         //             />
//         //           )}
//         //         </Col>
//         //       </Row>
//         //     )}
//         //   </div>
//         // ))
//         <></>
//       )}
//     </Container>
//   );
// };

// export default PromtInputFileUploader;


import React, { useRef, useState, useEffect } from "react";
import {  Row, Col, Spinner, Container, ProgressBar } from "react-bootstrap";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import sourceUrl from "../../../api/Source.api";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Dialog, IconButton, Typography, Button, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../contexts/AuthContext";
import { button_bg_color_for_New_Project_dm } from "../../../constants/colors";

const PromtInputFileUploader = () => {
  const { threadFiles, setThreadFiles, uploadFiles, checkUrlStatus, threadId,setWaitThread,InvokeMatrixApi,upload_FilesOnly } = useWorkspace();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [progressStatusChecking, setProgressStatusChecking] = useState({});
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const token = localStorage.getItem('token');
  // console.log('threadfiles', threadFiles);
  const [showSignUpToUnlock,setShowSignUpToUnlock] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const theme = localStorage.getItem('theme');
  // const {setShowSignUpModal,setShowLoginModal} = useAuth();
  const isBelow550 = useMediaQuery("(max-width:550px)");

  const handleFileChange = async (event) => {
    try {
      setWaitThread(true);
      const files = Array.from(event.target.files); // Convert FileList to Array
      const existingFileNames = threadFiles.map((file) => file.name);

      // Filter out files that already exist in threadFiles by their names
      const newFiles = files.filter(
        (file) => !existingFileNames.includes(file.name)
      );

      // Clear the file input after selecting files
      event.target.value = "";
      
      // Map files to upload promises
      const uploadPromises = newFiles.map(async (file) => {
        setLoading(true); // Show loader

        // Map each file to the prescribed JSON format and mark as "uploading"
        const fileToUpdate = {
          type: "file",
          file: file,
          name: file.name,
          status: "uploading", // Mark as "uploading" initially
          fileId: null,
          url: null, // Add a placeholder for the URL
        };

        // Update threadFiles with the new file object
        setThreadFiles((prevThreadFiles) => [...prevThreadFiles, fileToUpdate]);

        // Upload the file and return fileId
        const response = await upload_FilesOnly(fileToUpdate);
        const fileId = response.data.id;
        const url = response.data.url;

        // console.log('here is the url that sent before check kkkkkkkkkkkkkkkkkkkkkkkkk',response)
        // Update the file object with uploaded state or upload failed
        setThreadFiles((prevThreadFiles) =>
          prevThreadFiles.map((prevFile) =>
            prevFile.name === file.name
              ? {
                  ...prevFile,
                  status: response.data.status, // Update status
                  fileId: fileId, // Update fileId
                  url: url, // Update URL
                }
              : prevFile
          )
        );

        // Start checking progress status
        setProgressStatusChecking((prev) => ({ ...prev, [url]: true }));
        
        // Poll progress status every 3 seconds
        const interval = setInterval(async () => {
          
          // const progressStatus = await checkUrlStatus(url);
          const status_resp = await fetch(`${sourceUrl}/status`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization":`Bearer ${token}`
            },
            body: JSON.stringify({ threadId, url }),
          });
          const responseData = await status_resp.json();
          if (status_resp.status === 200 && responseData.data.status === "completed") {
             // ==========================Invoke matrix api ===================================================
              InvokeMatrixApi(responseData,status_resp,'POST','status',{ threadId, url })
             // ===============================================================================================
            setThreadFiles((prevThreadFiles) =>
              prevThreadFiles.map((prevFile) =>
                prevFile.name === file.name
                  ? {
                      ...prevFile,
                      status:"completed", // Update status
                      fileId: fileId, // Update fileId
                      url: url, // Update URL
                    }
                  : prevFile
              )
            );
           
            setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
            clearInterval(interval); // Stop interval
          }
          else {
            if(status_resp.status === 200 && responseData.data.status === "failed"){
              
              setThreadFiles((prevThreadFiles) =>
                prevThreadFiles.map((prevFile) =>
                  prevFile.name === file.name
                    ? {
                        ...prevFile,
                        status:"failed",
                        fileId:"", // Update fileId
                        url: url, // Update URL
                      }
                    : prevFile
                )
              );
               // ==========================Invoke matrix api ===================================================
                InvokeMatrixApi(responseData,status_resp,'POST','status',{ threadId, url })
                // ===============================================================================================
              setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
              clearInterval(interval); // Stop interval
            }
            else if(status_resp.status === 500){
              setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
              clearInterval(interval); // Stop interval
            } 
            
          }
        }, 3000);

        // Return updated file object with fileId
        return fileId
          ? { ...fileToUpdate, status: "uploaded", fileId: fileId }
          : { ...fileToUpdate, status: "failed" };
      });

      // Wait for all uploads to finish
      await Promise.all(uploadPromises);

      setLoading(false); // Hide loader
      setWaitThread(false);
    } catch (error) {
      console.error("Error while handling file change:", error);
    }
  };

  

  // const handleRemoveFile = (index) => {
  //   console.log("removed file index",index)
  //   const updatedFiles = threadFiles.filter(
  //     (_, fileIndex) => fileIndex !== index
  //   );
  //   setThreadFiles(updatedFiles); // Update the context
  // };

  const handleRetryFile = async (file) => {
    
    // Mark the file as uploading
    const updatedFiles = threadFiles.map((prevFile) =>
      prevFile.name === file.name
        ? { ...prevFile, status: "uploading" }
        : prevFile
    );
    setThreadFiles(updatedFiles);
  
    // Retry uploading the file
    const response = await upload_FilesOnly(file);
    const fileId = response.data.id;
    const url = response.data.url;
  
    // Update the file object with the new status and fileId
    setThreadFiles((prevThreadFiles) =>
      prevThreadFiles.map((prevFile) =>
        prevFile.name === file.name
          ? {
              ...prevFile,
              status: response.data.status, // Update status
              fileId: fileId, // Update fileId
              url: url, // Update URL
            }
          : prevFile
      )
    );
  
    // Start checking progress status
    setProgressStatusChecking((prev) => ({ ...prev, [url]: true }));
  
    // Poll progress status every 3 seconds
    const interval = setInterval(async () => {
      
      const progressStatus = await checkUrlStatus(url);
      const status_resp = await fetch(`${sourceUrl}/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ threadId, url }),
      });
      const responseData = await status_resp.json();
      if (progressStatus && responseData.data.status === "completed") {
        // Update the file status to completed
        setThreadFiles((prevThreadFiles) =>
          prevThreadFiles.map((prevFile) =>
            prevFile.name === file.name
              ? {
                  ...prevFile,
                  status: "completed",
                }
              : prevFile
          )
        );
        // ==========================Invoke matrix api ===================================================
         InvokeMatrixApi(responseData,status_resp,'POST','status',{ threadId, url })
        // ===============================================================================================
        setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
        clearInterval(interval); // Stop interval
      } else {
        // Update the file status to failed if the retry is unsuccessful
        if (responseData.data.status === "failed") {
          setThreadFiles((prevThreadFiles) =>
            prevThreadFiles.map((prevFile) =>
              prevFile.name === file.name
                ? {
                    ...prevFile,
                    status: "failed",
                    fileId:"",
                  }
                : prevFile
            )
          );
          // ==========================Invoke matrix api ===================================================
          InvokeMatrixApi(responseData,status_resp,'POST','status',{ threadId, url })
          // ===============================================================================================
          setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
          clearInterval(interval); // Stop interval
        }
      }
    }, 3000);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseSignToUnlockModal = () => {
    setShowSignUpToUnlock(false);
  }
  

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleSignupToUnlock = (e) => {
    e.preventDefault();
    setShowModal(false);
    setShowSignUpToUnlock(true);
    
  }

  return (
    <div>
      <Container fluid>
        <Row style={{ display: "flex", flexDirection: "column",marginBottom:'-5px'}}>
          <Button
            variant="outline-primary"
            onClick={(e) => {
              if (isLoggedIn) {
                handleFileSelect(e);
              } else {
                handleSignupToUnlock(e);
              }
            }}
            className="mb-2 d-flex align-items-center justify-content-start"
            style={{ fontSize: '.95rem',width : '32px',backgroundColor:'transparent',border:'none',color:'white',
            paddingBottom : '6px'
            }}
          >
            <FileUploadOutlinedIcon />
          </Button>
          <input
            id="file-upload"
            type="file"
            accept=".txt, .docx, .mp3, .pdf, .mp4, .xlsx, video/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple
          />
        </Row>
        {!threadFiles.some((file) => file.type === "file") ? (
          <Row className="text-secondary mb-1" style={{ fontSize: '.95rem' }}>
          </Row>
        ) : (
          threadFiles.map((file, index) => (
            <div key={index}>
              {file.type === "file" && (
                <Col>
                  <Row className="mb-1 px-0 m-0 align-items-center">
                    <Col
                      xs="10"
                      className={
                        file.status === "upload failed"
                          ? "text-danger p-0 m-0"
                          : "p-0 m-0"
                      }
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color:file.status === "failed"?"red":''
                      }}
                    >
                      {/* {file.name} */}
                    </Col>
                    <Col
                      xs="2"
                      className="m-0 p-0"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      {file.status === "uploading" ? (
                        
                        // <Spinner animation="border" role="status" size="sm">
                        //   <span className="visually-hidden">Loading...</span>
                        // </Spinner>
                        <></>
                      ) : 
                        file.status === "failed" ? (
                        // <i
                        //   className="bi bi-exclamation-circle"
                        //   // onClick={() => handleRemoveFile(index)}
                        //   style={{ cursor: "pointer" }}
                        // />
                          // <i class="bi bi-arrow-repeat"
                          //   onClick={() => handleRetryFile(file)}
                          //   style={{ cursor: "pointer" }}
                          // ></i>
                          <></>
                      ) : (
                        // <i
                        //   className="bi bi-x-lg"
                        //   onClick={() => handleRemoveFile(index)}
                        //   style={{ cursor: "pointer" }}
                        // />
                          // file.status === "failed" && <i class="bi bi-arrow-repeat"
                          //   onClick={() => handleRetryFile(file)}
                          //   style={{ cursor: "pointer" }}
                          // ></i>
                          <></>
                      )}
                    </Col>
                  </Row>
                  {/* {progressStatusChecking[file.url] && (
                    <ProgressBar animated now={100} label="Processing the file...." />
                  )} */}
                </Col>
              )}
            </div>
          ))
        )}
        {/* {loading && (
          <Row className="mt-3">
            <Spinner animation="border" role="status" size="md">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Row>
        )} */}
      </Container>
      {/* <Dialog
        open={showSignUpToUnlock}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            width:'480px',
            // height: "673px",
            gap: "0px",
            borderRadius: "8px",
            //  border: "1px solid #CAC4D0",
            opacity: "0px",
            bgcolor: "#1F233E",
            margin: "",
          },
        }}
      >
        <IconButton
          onClick={handleCloseSignToUnlockModal}
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            color: 'white', // Adjust color based on theme
          }}
        >
          <CloseIcon />
        </IconButton>
        <div
          style={{
            backgroundColor:
              theme === "dark" ? '#1F233E' : "",
            color: theme === "dark" ? "white" : "",
            width:'480px',
            height:'auto',
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
          }}
        >
          <Box
            sx={{
              width: '60px',
              height: '60px',
              bgcolor: '#282D51',
              borderRadius: '50%',
              display: 'flex', // Enables Flexbox
              justifyContent: 'center', // Centers horizontally
              alignItems: 'center', // Centers vertically
              marginTop:'40px',
            }}
          >
            <FileUploadOutlinedIcon style={{ width: '36px', height: '36px' }} />
          </Box>
          <Typography sx={{fontSize:'16px',fontWeight:'600',fontFamily:'inter',margin:'20px auto'}}>
             Sign up to unlock file uploads
          </Typography>
          <Typography sx={{fontSize:'14px',fontWeight:'400',fontFamily:'inter',padding:'0 40px',textAlign:'center',marginBottom:'40px',color:'rgba(255, 255, 255, 0.80)'}}>
              ModalX uses your uploaded files as context in your projects.
              Sign up or login to access this feature
          </Typography>
          <Box 
                sx={{
                padding:'0 40px 40px 40px',
                width:'100%',
                height:'132px',
                display:'flex',
                flexDirection:'column',
                gap:'12px'
                }}
              >
                <Button 
                sx={{
                  maxWidth:'400px',
                  textTransform:'none',
                  bgcolor:button_bg_color_for_New_Project_dm,
                  "&:hover": {
                        bgcolor: "#ffd9aa",
                        border : 'none'
                  },
                  color:'#17182C',
                  fontSize:'16px',
                  fontWeight:600,
                  fontFamily:'inter',
                }}
                onClick={() => {
                  navigate("/");
                  setShowSignUpModal(true)
                }}
                >
                  Sign up
                </Button>
                <Button 
                sx={{
                  maxWidth:'400px',
                  textTransform:'none',
                  color:'#F6BE6A',
                  fontSize:'16px',
                  fontWeight:600,
                  fontFamily:'inter',
                  border:'2px solid #F6BE6A'
                }}
                onClick={() => {
                  navigate("/");
                  setShowLoginModal(true);
                }}
                >
                  Log in
                </Button>
              </Box>
        </div>
      </Dialog> */}
      <Dialog
        open={showSignUpToUnlock}
        onClose={handleCloseModal}
        maxWidth="sm"
        PaperProps={{
          sx: {
            maxWidth: "480px", // Ensures modal width is capped at 480px
            width: "100%", // Makes it responsive for smaller screens
            borderRadius: "8px",
            bgcolor: "#1F233E",
            margin: "auto", // Centers the modal vertically
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <IconButton
          onClick={handleCloseSignToUnlockModal}
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div
          style={{
            backgroundColor: theme === "dark" ? "#1F233E" : "",
            color: theme === "dark" ? "white" : "",
            width: "100%",
            padding: "20px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "60px",
              height: "60px",
              bgcolor: "#282D51",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <FileUploadOutlinedIcon style={{ width: "36px", height: "36px" }} />
          </Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              margin: "20px 0",
              textAlign: "center",
            }}
          >
            Sign up to unlock file uploads
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Inter",
              padding: "0 10%",
              textAlign: "center",
              marginBottom: "20px",
              color: "rgba(255, 255, 255, 0.80)",
            }}
          >
            ModalX uses your uploaded files as context in your projects. Sign up or
            login to access this feature.
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                width: "90%",
                maxWidth: "400px",
                textTransform: "none",
                bgcolor: button_bg_color_for_New_Project_dm,
                "&:hover": {
                  bgcolor: "#ffd9aa",
                  border: "none",
                },
                color: "#17182C",
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "Inter",
              }}
              onClick={() => {
                navigate("/signup");
                // setShowSignUpModal(true);
              }}
            >
              Sign up
            </Button>
            <Button
              sx={{
                width: "90%",
                maxWidth: "400px",
                textTransform: "none",
                color: "#F6BE6A",
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "Inter",
                border: "2px solid #F6BE6A",
              }}
              onClick={() => {
                navigate("/login");
                // setShowLoginModal(true);
              }}
            >
              Log in
            </Button>
          </Box>
        </div>
      </Dialog>
    </div>
    
  );
};

export default PromtInputFileUploader;

