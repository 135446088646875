import React, {useState, useRef} from "react";
import { Box, Button, CardMedia, Typography, useMediaQuery } from "@mui/material";
import { darkTheme_bg_color } from "../../constants/colors";
import CheckedIcon from "../../assets/LandingPage_Items/How It Works/Checked.png";
import SearchUser_icon from "../../assets/LandingPage_Items/How It Works/Search_User.png";
import EditUser_icon from "../../assets/LandingPage_Items/How It Works/User_Edit.png";
import VideoImage from "../../assets/LandingPage_Items/How It Works/video output Image for How it works.png";
import image_blow_990 from "../../assets/LandingPage_Items/How It Works/how_works_image_below_990.png";
import Image from "../../assets/landingPageActionImage.png";
import LadingPage_ActionImage_Mobile from "../../assets/LadingPage_ActionImage_Mobile.png";
import Modalx_Logo from "../../assets/Modalx_Dark_Logo.png";
import Picture from "../../assets/Button/Picture.png";
import PDF from "../../assets/Button/PDF.png";
import Music_Library from "../../assets/Button/Music Library.png";
import Blog from "../../assets/Button/Blog.png";
import presentation from "../../assets/Button/presentation.png";
import search from "../../assets/Button/search.png";
import translate from "../../assets/Button/translate.png";
import videoIcon from "../../assets/Button/videoIcon.png";
import image1 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image1.png'
import image2 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image2.png'
import image3 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image3.png'
import image4 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image4.png'
import image5 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image4.png'
import video1 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video1.png'
import video2 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video2.png'
import video3 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video3.png'
import video4 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video4.png'
import video5 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video5.png'
import audio1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio1.png";
import audio2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio2.png";
import audio3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio3.png";
import audio4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio4.png";
import audio5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio5.png";
import ppt1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation1.png";
import ppt2 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation2.png";
import ppt3 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation3.png";
import ppt4 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation4.png";
import ppt5 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation5.png";
import blog1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog1.png";
import blog2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog2.png";
import blog3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog3.png";
import blog4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog4.png";
import blog5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog5.png";
import translate1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation1.png";
import translate2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation2.png";
import translate3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation3.png";
import translate4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation4.png";
import translate5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation5.png";
import report1  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report1.png";
import report2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report2.png";
import report3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report3.png";
import report4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report1.png";
import report5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report2.png";
import web1  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch1.png";
import web2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch2.png";
import web3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch3.png";
import web4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch1.png";
import web5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch2.png";

import {
  HoveredChat_bg_color,
  SelectedChat_bg_color,
} from "../../Provider/Color_Provider";
import CommanHoverModal from "../Common_Components/CommanHoverModal";

const ModalX_Advantages = () => {
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const isBelow600 = useMediaQuery("(max-width:600px)");
  const isBelow750 = useMediaQuery("(max-width:750px)");
  const isBetween992And1300 = useMediaQuery('(min-width:991px) and (max-width:1300px)');
  const [hoveredCard, setHoveredCard] = useState(null);
    const [open, setOpen] = useState(false);
    const [modalPosition, setModalPosition] = useState({
      top: 0,
      left: 0,
      right: 0,
      botton: 0,
    });
    const timeoutRef = useRef(null);

  const grids = [
    { id: 1,image:Picture, title: "Create an image", icon: CheckedIcon, name:"Image",
    imageArray: [
      { id: 1, name: "image", url: image1 },
      { id: 2, name: "image", url: image2 },
      { id: 3, name: "image", url: image3 },
      { id: 4, name: "image", url: image4 },
      { id: 5, name: "image", url: image5 },
    ],
    description: "Generate copyright-free images that effectively communicate your ideas.",
    url: "/imagegallery",
  },
    { id: 2,image:videoIcon, title: "Generate a video", icon: CheckedIcon, name:"Video",
      imageArray: [
        { id: 1, name: "image", url: video1 },
        { id: 2, name: "image", url: video2 },
        { id: 3, name: "image", url: video3 },
        { id: 4, name: "image", url: video4 },
        { id: 5, name: "image", url: video5 },
      ],
      description: "Generate video at scale for diverse audiences.",
      url: "/videogallery",
     },
    { id: 3,image:translate, title: "Translate a document", icon: CheckedIcon,name: "translation", 
      imageArray: [
        { id: 1, name: "image", url: translate1 },
        { id: 2, name: "image", url: translate2 },
        { id: 3, name: "image", url: translate3 },
        { id: 4, name: "image", url: translate4 },
        { id: 5, name: "image", url: translate5 },
      ], 
    description:"Get accurate translations while keeping the original context",
    url: "/workspace",
    },
    { id: 4,image:presentation, title: "Create a presentation", icon: CheckedIcon,  name: "Presentation",
      imageArray: [
        { id: 1, name: "image", url: ppt1 },
        { id: 2, name: "image", url: ppt2 },
        { id: 3, name: "image", url: ppt3 },
        { id: 4, name: "image", url: ppt4 },
        { id: 5, name: "image", url: ppt5 },
      ],
      description: "Generate impactful presentations that deliver value",
      url: "/presentationgallery", 
    },
    { id: 5,image:Modalx_Logo, title: "MODALX", icon: CheckedIcon,}, // Non-clickable grid
    { id: 6,image:search, title: "Find anything on the web", icon: CheckedIcon, name:"Search", 
      imageArray: [
        { id: 1, name: "image", url: web1 },
        { id: 2, name: "image", url: web2 },
        { id: 3, name: "image", url: web3 },
        { id: 4, name: "image", url: web4 },
        { id: 5, name: "image", url: web5 },
      ],
      description:"Get any information you want from the web through genuine sources",
      url:"/workspace",
    },
    { id: 7,image:Music_Library, title: "Generate audio", icon: CheckedIcon, name:"Speech",
      imageArray: [
        { id: 1, name: "image", url: audio1 },
        { id: 2, name: "image", url: audio2 },
        { id: 3, name: "image", url: audio3 },
        { id: 4, name: "image", url: audio4 },
        { id: 5, name: "image", url: audio5 },
      ],
      description: "Generate realistic audio in multiple languages seamlessly.",
      url: "/audiogallery" },
    { id: 8,image:Blog, title: "Write a blog", icon: CheckedIcon,name: "Blog", 
      imageArray: [
        { id: 1, name: "image", url: blog1 },
        { id: 2, name: "image", url: blog2 },
        { id: 3, name: "image", url: blog3 },
        { id: 4, name: "image", url: blog4 },
        { id: 5, name: "image", url: blog5 },
      ],
      description: "Generate engaging blogs that deliver value",
      url: "/blogarticle",
    },
    { id: 9,image:PDF, title: "Generate a report", icon: CheckedIcon,name: "Report", 
      imageArray: [
        { id: 1, name: "image", url: report1 },
        { id: 2, name: "image", url: report2 },
        { id: 3, name: "image", url: report3 },
        { id: 4, name: "image", url: report4 },
        { id: 5, name: "image", url: report5 },
      ],
      description: "Generate engaging report that deliver value",
      url: "/workspace",
     },
  ];


  const handleHover = (event, card) => {
    clearTimeout(timeoutRef.current); // Clear previous timeout to avoid flickering
    const rect = event.currentTarget.getBoundingClientRect(); // Get card position
    timeoutRef.current = setTimeout(() => {

      const scrollTop = window.scrollY; // Current vertical scroll position
      const scrollLeft = window.scrollX; // Current horizontal scroll position

      const modalOffsetX = 100; // Offset for the modal's horizontal position
      const modalOffsetY = -340; // Offset for the modal's vertical position
    
       // Calculate the modal position relative to the document
      const modalTop = rect.top + scrollTop + modalOffsetY;
      const modalLeft = rect.left + scrollLeft + modalOffsetX;
    
      setModalPosition({
        top: modalTop,
        left: modalLeft,
      });
      setHoveredCard(card);
      setOpen(true);
    }, 500);
  };

  const handleLeave = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setOpen(false);
      setHoveredCard(null);
    }, 200);
  };

  const handleModalHover = (isHovering) => {
    if (!open) return;
    if (isHovering) {
      clearTimeout(timeoutRef.current); // Keep modal open
    } else {
      timeoutRef.current = setTimeout(() => {
        setHoveredCard(null);
        setOpen(false); // Close modal when mouse leaves modal
      }, 500);
    }
  };

  return (
    <>
    <Box
      sx={{
        textAlign: "center",
        // background: `linear-gradient(to bottom, #1e223d, ${darkTheme_bg_color})`,
        padding: "50px 0 150px 0", // Adjust padding as needed
        maxWidth: "1300px",
        margin: "auto",
        marginBottom: "100px",
        "@media (max-width : 991px)": {
          maxHeight: "100%",
          marginBottom: "0px",
        },
      }}
    >
      <Box
        sx={{
          // height: "182px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "100px",
          "@media (max-width : 991px)": {
            // height: "150px",
            marginBottom: "63px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            textAlign: "center",
            color: "#F6BE6B",
            "@media (max-width : 991px)": {
              fontSize: "12px",
            },
          }}
        >
          Integrations
        </Typography>

        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "48px",
            fontWeight: 500,
            letterSpacing: "-0.32",
            textAlign: "center",
            color: "#FFFFFF",
            opacity: "80%",
            marginTop : '48px',
            "@media (max-width : 991px)": {
              fontSize: "28px",
              marginTop : '24px',
              padding : '0px 7.5% 0px 7.5%',
              
            },
            padding:'0 82px'
          }}
        >
          We cut through the AI clutter so you can focus on what matters – growing your business
        </Typography>

      </Box>
      <Box
        sx={{
          // height: "341px",
          margin: "auto",
          display: "flex",
          flexDirection:isBelow991?'column':'row',
          justifyContent: "space-between",
          alignItems: isBelow991 ? "center" : "stretch",
          "@media (max-width : 991px)": {
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100%",
          },
          maxWidth:isBetween992And1300?'1024px':'1072px',
          // paddingLeft:'186px',
          // paddingRight:'80px'
        }}
      >
        <Box
          component="img"
          src={isBelow991?LadingPage_ActionImage_Mobile:Image}
          sx={{
            maxWidth: "480px",
            height: "100%",
            // "@media (max-width : 991px)": {
            //   display: "none",
            // },
            maxWidth:isBetween992And1300 || isBelow750?'355px':'467px',
            marginBottom:isBelow991?'20px':''
          }}
          alt="Video Illustration"
        />
        <Box
          sx={{
            maxWidth:isBetween992And1300 || isBelow750 ?"596px":'681px',
            height: "auto",
            maxHeight: isBelow750?'auto':"206px",
            display: "grid",
            gridTemplateColumns: isBelow750?"repeat(2, 1fr)":"repeat(3, 1fr)",
            gap: isBetween992And1300?'15px':"30px", // Gap between grids
            justifyContent: "center",
            justifyItems: "center",
            marginTop: "0px",
            "@media (max-width : 991px)": {
              width: "100%",
              padding: "0px 7.5% 0px 7.5%",
            },
            margin: "auto",
            marginLeft:isBelow750?'': !isBelow991?'30px':''
          }}
        >
          {grids.map((grid) =>
            grid.id === 5 ? ( // Special styling for the 5th grid
              <Box
                key={grid.id}
                sx={{
                  width: "179px",
                  height: "50.955px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  gridColumn: isBelow750 ? "1 / span 2" : "auto",
                }}
              >
                <Box
                  component="img"
                  src={Modalx_Logo} // Replace with the appropriate image
                  alt="Special Grid Image"
                  sx={{
                    width: "179px",
                    height: "50.955px",
                  }}
                />
              </Box>
            ) : (
              <Box onMouseLeave={handleLeave}>
              <Box  onMouseEnter={(e) => handleHover(e, grid)}
                key={grid.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  minHeight: "50px",
                  padding: isBetween992And1300 || isBelow750 ?'12px 8px' : "12px",
                  borderRadius: "12px",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  bgcolor: "1F233E",
                  cursor: "pointer",
                  "&:hover": { bgcolor: "#1F233E" },
                  marginLeft: isBetween992And1300 || isBelow750?'' : grid.id === 1 ? "39px" : grid.id === 7 ? "73px" : "12px",
                  marginRight: isBetween992And1300 || isBelow750?'' : grid.id === 3 ? "39px" : grid.id === 9 ? "73px" : "12px",
                  minWidth: isBelow750?'112px':"138px", // Minimum width for the grid
                  width: "auto", // Allow width to adjust based on content
                }}
                component={Button}
              >
                <Box
                  component="img"
                  src={grid.image}
                  alt={`${grid.title} Icon`}
                  sx={{
                    width: isBelow991?'20px':"24px",
                    height: isBelow991?'20px':"24px",
                    marginRight: "12px",
                  }}
                />
                <Typography
                  sx={{
                    color: "white",
                    fontSize: isBelow991?'12px':"14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    whiteSpace: "nowrap",
                    textTransform: "none",
                  }}
                >
                  {grid.title}
                </Typography>
              </Box>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
    <CommanHoverModal 
    open={open}
    handleLeave={handleLeave}
    modalPosition={modalPosition}
    hoveredCard={hoveredCard}
    handleModalHover={handleModalHover}
    />
    </>
  );
};

export default ModalX_Advantages;