import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  ImageSlider_Active_Dod,
  ImageSlider_Inactive_Dod_bg,
} from "../../constants/colors";
import Image1 from "../../assets/signup/Group 174.png";
import Image1MobileView from "../../assets/signup/Frame174MobileView.png";
import Image2 from "../../assets/signup/Frame 142.png";
import Image2MobileView from "../../assets/signup/Frame142MobileView.png";
import ModalXLogo from "../../assets/signup/loginPageLogo.png";

const SignupLeftSide = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const isBelow1300px = useMediaQuery("(max-width: 1300px)")
  const isBelow991 = useMediaQuery("(max-width:991px)");

  let images = [
    {
      image: isBelow991 ? Image1MobileView : Image1,
      title: "Personalized Solutions, Custom Outputs",
      disc: "Get a custom AI playbook designed for your needs, not a one-size-fits-all solution. Generate customized solutions for all your business goals.",
    },
    {
      image: isBelow991 ? Image2MobileView : Image2,
      title: "Simplified ",
      disc: "We cut through the AI clutter so you can focus on what matters - growing your business",
    },
    {
      image: isBelow991 ? Image1MobileView : Image1,
      title: "Personalized Solutions, Custom Outputs",
      disc: "Get a custom AI playbook designed for your needs, not a one-size-fits-all solution. Generate customized solutions for all your business goals.",
    },
    {
      image: isBelow991 ? Image2MobileView : Image2,
      title: "Simplified ",
      disc: "We cut through the AI clutter so you can focus on what matters - growing your business",
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [images.length]);


  return (
    <>
      <Box>
      {
            isBelow991 && 
              <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "24px",
                marginTop: "40px",
                width: isBelow991 ? "350px" : "100%"
              }}
            >
              <img
                src={ModalXLogo}
                alt={"logo"}
                style={{ width: "163px", height: "51px" }}
              />
            </Box>
          }
        <Box sx={{display: "flex", flexDirection:"column", width: isBelow991? "350px" : "520px", height: isBelow991? "317px" : "448px"}}>
        {/* <Box sx={{ margin: isBelow991 ? "25px 0 0 0" : "110px 40px 0px 40px" }}> */}
          <Box style={{ display:"flex", justifyContent:"center", width:"100%", height:isBelow991? "208px" : "255px" }}>
            <img style={{}} src={images[currentIndex].image} alt="img" />
          </Box>
        {/* </Box> */}
        <Box
          sx={{
            display: "flex",
            maxWidth: "599px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: isBelow991? "16px" : "24px",
              fontStyle: "normal",
              fontWeight: 600,
              letterSpacing: "-0.32px",
              lineHeight: "20px",
              color: "#FFFFFF",
              alignSelf: "center",
              whiteSpace: "nowrap",
              marginTop: isBelow991? "24px" : "100px",
              opacity: 0.8,
            }}
          >
            {images[currentIndex].title}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              maxWidth: "520px",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize:  isBelow991? "12px" :  "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: isBelow991? "14px" : "24px",
              letterSpacing: isBelow991? 0.067 : 0.1,
              color: "rgba(255, 255, 255,0.8)",
              alignSelf: "center",
              marginTop: "16px",
              textAlign: "center",
            }}
          >
            {images[currentIndex].disc}
          </Typography>
        </Box>
        </Box>

        <Box display="flex" justifyContent="center" mt={ isBelow991 ? 4 : 8}>
        {images.map((_, index) => (
          <Box
            key={index}
            // onClick={() => goToSlide(index)}
            sx={{
              width: "10px",
              height: "10px",
              backgroundColor:
                currentIndex === index
                  ? ImageSlider_Active_Dod
                  : ImageSlider_Inactive_Dod_bg,
              borderRadius: "50%",
              margin: "0 5px",
              cursor: "pointer",
            }}
          />
        ))}
      </Box>
      </Box>
    </>
  );
};

export default SignupLeftSide;
