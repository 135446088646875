// import React, { useEffect, useState } from "react";
// import { Col, Row, Container, Image, Button } from "react-bootstrap";
// import MessageActions from "./MessageActions";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import TypingIndicator from "./TypingIndicator";
// import black_logo from "../../../assets/ModalX_-orange_Icon.png";
// import white_logo from "../../../assets/modalX_robot_icon_white.png";
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
// import he from 'he';
// import sourceUrl from "../../../api/Source.api";
// import {decode} from 'html-entities';
// import markdownit from 'markdown-it';
// import MDEditor from '@uiw/react-md-editor';
// import rehypeSanitize from "rehype-sanitize";
// import './custom-markdown-styles.css'

// const MessageCard = ({ message, handleDownload, handlePreview }) => {
//   const token = localStorage.getItem('token');
//   console.log("current_messages_from_history", message);
//   const { waitThread,setCurrentThread,setWaitThread,sendMessage,setSending } = useWorkspace();
//   const [theme, setTheme] = useState(localStorage.getItem('theme'));
//   const [fileStatus, setFileStatus] = useState([]);
//   const [input, setInput] = useState("");

//   // const findUrl = (msg) => {
//   //   const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
//   //   const match = urlRegex.exec(msg);
//   //   return match ? match[1] : null;
//   // };

//   const findUrl = (msg) => {
//     const urlRegex = /(https?:\/\/[^\s)]+?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt|pdf))/g;
//     const matches = [];
//     let match;
//     while ((match = urlRegex.exec(msg)) !== null) {
//       matches.push(match[0]);
//     }
//     return matches;
//   };

//   const removeUrlsAndBrackets = (msg) => {
//     return msg.replace(/\(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt)\)/g, '');
//   };

//   useEffect(() => {
//     // Update the theme when it changes
//     const handleThemeChange = () => setTheme(localStorage.getItem('theme'));
//     window.addEventListener('storage', handleThemeChange);

//     return () => {
//       window.removeEventListener('storage', handleThemeChange);
//     };
//   }, []);

//   const handleCopyToClipboard = (text) => {
//     navigator.clipboard.writeText(text)
//       .then(() => {
//         console.log('Link copied to clipboard:', text);
//       })
//       .catch((error) => {
//         console.error('Error copying link to clipboard:', error);
//       });
//   };

//   useEffect(() => {
//     const urls = findUrl(message.agent);
//     console.log("Found URLs:", urls);
//     if (urls && urls.length > 0) {
//       // Initialize fileStatuses with URLs and status null
//       const initialStatuses = urls.map(url => ({ url, status: null }));
//       setFileStatus(initialStatuses);

//       // For each URL, check status and update state
//       urls.forEach(async (url, index) => {
//         const status = await checkStatusFromHistoryUrl(url);
//         setFileStatus(prevStatuses => {
//           const newStatuses = [...prevStatuses];
//           newStatuses[index] = { url, status };
//           return newStatuses;
//         });
//       });
//     }
//   }, [message.agent]);

//   const checkStatusFromHistoryUrl = async (url) => {
//     try {
//       const response = await fetch(`${sourceUrl}/status`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${token}`
//         },
//         body: JSON.stringify({ url }),
//       });

//       const responseData = await response.json();
//       if (responseData.data.status === "completed") {
//         return "completed";
//       } else if (responseData.data.status === "failed") {
//         return "failed";
//       } else {
//         return null;
//       }
//     } catch (error) {
//       console.error("Check URL status request error:", error);
//       return null;
//     }
//   };

//   const renderers = {
//     a: ({ href, children }) => (
//       <div style={{ display: 'inline-flex', alignItems: 'center', wordBreak: 'break-word' }}>
//         <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
//         <i
//           className="bi bi-copy"
//           style={{ cursor: 'pointer', marginLeft: '20px' }}
//           onClick={() => handleCopyToClipboard(href)}
//           title="Copy link"
//         />
//       </div>
//     ),
//   };

//   const decodeUnicode = (str) => {
//     // const stringWithoutDoubleBackslashes = str.split("\\\")
//     const stringNewLine = str.split("\\n").join("\n")
//     let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
//       const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
//       return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
//     });
//     const finalString = stringNewLineWithAnchorTag.replace(/\\/g, '');
//     return finalString
//   };

// // Function to decode HTML entities
// const decodeContent = (content) => {
//   return decodeUnicode((content));
// };

//   const handleSubmit = async (messageContent) => {
//     setSending(true)
//     setWaitThread(true);
//     if (messageContent.trim()) {
//       const latestEntry = { sender: "user", content: messageContent };
//       setInput(""); // Reset input field
//       setCurrentThread((prevThread) => [...prevThread, latestEntry]);
//       const entryResponse = await sendMessage(latestEntry).catch(console.error);
      
//       if (entryResponse) {
//         setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//         setSending(false)
//       }
//       else{
//         setCurrentThread((prevThread) => [...prevThread, {content:"Unable to process your query!..",sender:'modalX'}]);
//         setSending(false);
//       }
//     }
//     setWaitThread(false);
//     // here add the functionality to check url status and update it in the currentThread
//   };

//   const handleRetry = () => {
//     setCurrentThread((prevThread) => {
//       const lastUserMessage = prevThread.filter(msg => msg.sender === "user").slice(-1)[0];
//       const newThread = prevThread.slice(0, -1);
//       handleSubmit(lastUserMessage.content); // Retry the submission with the last user message
//       return newThread;
//     });
//   };

//   const getFileFormat = (url) => {
//     // Extract the file extension from the URL
//     const parts = url.split('.');
//     if (parts.length > 1) {
//       return parts[parts.length - 1]; // Return the last part as the file format
//     } else {
//       return ''; // Return empty string if no file extension found
//     }
//   };

//   function renderResponse(response){  
//     response = findUrl(response) ? removeUrlsAndBrackets(response) : response;                   
//     if (findUrl(response) && findUrl(response).includes('dailylimitexceeded')) {
//       // updatedParagraph = 'Your daily '+ getFileFormat(findUrl(message.agent))+' generation limit was exceeded. Consider upgrading to our premium plan for unlimited access to features.' ;
//       let updatedParagraph = `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ getFileFormat(findUrl(response))+` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`
     
//        return <div style={{ wordBreak: "break-word" }}>
//           <MDEditor.Markdown source={decodeUnicode(updatedParagraph)} style={{backgroundColor:"#171A2D", color:"white" }} previewOptions={{rehypePlugins: [[rehypeSanitize]],}}/> 
//         </div>
      
//     }else{
//       return <div style={{ wordBreak: "break-word",  }}>
//         <MDEditor.Markdown source={decodeUnicode(response)} style={{ backgroundColor:"#171A2D", color:"white"}}  className="custom-md-editor"/>
//       </div>
//     } 
// }

// const renderFirstTimeResponse = (response) =>{
//     const cleanedParagraph = findUrl(response) ? removeUrlsAndBrackets(response) : response;
//      return <div style={{ wordBreak: "break-word" }}>
//         <MDEditor.Markdown source={decodeUnicode(cleanedParagraph)} style={{ backgroundColor:"#171A2D", color:"white"}}  className="custom-md-editor" previewOptions={{rehypePlugins: [[rehypeSanitize]]}} />
//       </div>
// }

//   return (
//   //   <Container fluid > 
//   //   {message.sender ?
//   //     <Row className="pb-3" >
//   //       {message.sender === "user" ? (
//   //         <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px',paddingLeft:'40px'}}>
//   //           <Row>
//   //             <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
//   //               <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
//   //             </Col>
//   //             <Col xs={11} style={{ margin: 'auto' }}>
//   //               {message.content && typeof message.content === 'string' && message.content.split("\n").map((line, index) => (
//   //                 <div key={index} style={{ wordBreak: "break-word" }}>
//   //                   {decodeContent(line)}
//   //                 </div>
//   //               ))}
//   //             </Col>
//   //           </Row>
//   //         </Col>
//   //       ) : (
//   //         <Row className="justify-content-end" >
//   //           <Col xs={11} className="text-end" style={{ marginBottom: '40px'}}>
//   //             <Row>
//   //               <Col xs={11} style={{ margin: 'auto', paddingRight: message.content.length < 44 ? '20px' : '',paddingTop:'10px'}} className={message.content.length > 55 ? "text-start" : 'text-end'}>
//   //                 {/* {message.content &&
//   //                   typeof message.content === 'string' &&
//   //                   message.content.split("\\n").map((paragraph, index) => {
//   //                     return (
//   //                       <div
//   //                         key={index}
//   //                         style={{ wordBreak: "break-word" }}
//   //                       >
//   //                         <ReactMarkdown children={decodeContent(paragraph)} remarkPlugins={[remarkGfm]} components={renderers} />
//   //                       </div>
//   //                     );
//   //                   })} */}
//   //                   {message.content &&
//   //                   typeof message.content === 'string' && renderFirstTimeResponse(message.content)}
//   //               </Col>
//   //               <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//   //                 <img style={{ width: '48px', height: '48px'}} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//   //               </Col>
//   //             </Row>
//   //             {message.file && (
//   //               <Row>
//   //                 <MessageActions
//   //                   message={message}
//   //                   handleDownload={handleDownload}
//   //                   handlePreview={handlePreview}
//   //                 />
//   //               </Row>
//   //             )}
//   //             {message.content === 'Unable to process your query!..' ? (
//   //               <div style={{display:'flex',justifyContent:'right'}}>
//   //                 <Button
//   //                 onClick={handleRetry}
//   //                 style={{ marginTop: "10px",maxWidth:'300px',border:'2px solid red',backgroundColor:'transparent'}}
//   //               >
//   //                 Regenerate Response <i class="bi bi-arrow-repeat"></i>
//   //               </Button>
//   //               </div>
                
//   //             ):''}
//   //           </Col>
//   //         </Row>
//   //       )}
//   //     </Row>
//   //     :
//   //     <Row className="pb-3">
//   //       <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px',paddingLeft:'40px'}}>
//   //         <Row>
//   //           <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
//   //             <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
//   //           </Col>
//   //           <Col xs={11} style={{ margin: 'auto' }}>
//   //             {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
//   //               <div key={index} style={{ wordBreak: "break-word" }}>
//   //                 {decodeContent(line)}
//   //               </div>
//   //             ))}
//   //           </Col>
//   //         </Row>
//   //       </Col>
//   //       <Row className="justify-content-end">
//   //         <Col xs={11} className="text-end">
//   //           <Row>
//   //             <Col xs={11} style={{ margin: 'auto', paddingLeft: '40px', marginBottom: '40px',paddingTop:'10px'}} className={message.agent.length > 55 ? "text-start" : 'text-end'}>
//   //               <Row>
//   //                 {message.agent &&
//   //                   typeof message.agent === 'string' && renderResponse(message.agent)}
//   //               </Row>
//   //               <Row>
//   //                 {
//   //                   findUrl(message.agent) &&
//   //                   <Row>
//   //                     <MessageActions
//   //                       message={{
//   //                         file: { url: findUrl(message.agent), mode: 'from_history', status: fileStatus }
//   //                       }}
//   //                       handleDownload={handleDownload}
//   //                       handlePreview={handlePreview}
//   //                     />
//   //                   </Row>
//   //                 }
//   //               </Row>
//   //             </Col>
//   //             <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//   //               <img style={{ width: '48px', height: '48px' }} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//   //             </Col>
//   //           </Row>
//   //           {message.file && (
//   //             <Row>
//   //               <MessageActions
//   //                 message={message}
//   //                 handleDownload={handleDownload}
//   //                 handlePreview={handlePreview}
//   //               />
//   //             </Row>
//   //           )}
//   //         </Col>
//   //       </Row>
//   //     </Row>
//   //   }
//   // </Container>

//     <Container fluid > 
//       {message.sender ?
//         <Row className="pb-3" >
//           {message.sender === "user" ? (
//             <Col xs={12} className="text-end" style={{ marginBottom: '20px', paddingRight: '30px',paddingLeft:'40px', backgroundColor: ''}}>
//               <Row style={{backgroundColor : ''}}>

//               <Col xs={11} style={{ margin: 'auto' , backgroundColor : '',display : 'flex',marginRight : '20px',
//                   justifyContent : 'end'}}>
//                 {message.content && typeof message.content === 'string' && message.content.split("\n").map((line, index) => (
//                   <div key={index} style={{ wordBreak: "break-word",textAlign : 'start', marginRight : '-13px' 
//                   ,backgroundColor : '', maxWidth : '95%',
//                   display : 'flex',
//                   justifyContent : 'end'

//                   }}>
//                     {decodeContent(line)}
//                   </div>
//                 ))}
//               </Col>
//               <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
//                 <i  className="bi bi-person fs-4" style={{margin:'auto'}}/>
//               </Col>
//               </Row>
//             </Col>
//           ) : (
//             <Row className="justify-content-end" style={{ backgroundColor : ''}} >
                            
//               <Col xs={11} className="text-end" style={{ marginBottom: '40px',backgroundColor : ''}}>
//                 <Row style={{backgroundColor : '', marginLeft : '-86px',backgroundColor : ''}} >
//                 <Col style={{width: '48px', height: '48px', backgroundColor : '#1F233E', marginRight : '', marginLeft : '27px', borderRadius : '24px'}} xs={1} >
//                     <img style={{ width: '24px', height: '24px' , marginTop : '12px'}} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//                   </Col>
//                   <Col xs={11} style={{ margin: 'auto' ,paddingTop:'10px',
//                     backgroundColor : '',width : '87%', marginLeft : '10px'
//                   }} className={message.content.length > 55 ? "text-start" : 'text-start'}>
                  
//                       {message.content &&
//                       typeof message.content === 'string' && renderFirstTimeResponse(message.content)}
//                   </Col>
//                 </Row >
//                 {message.file && (
//                   <Row style={{backgroundColor : '', marginLeft : '-1px'}}>
//                     <MessageActions
//                       message={message}
//                       handleDownload={handleDownload}
//                       handlePreview={handlePreview}
//                     />
//                   </Row>
//                 )}
//                 {message.content === 'Unable to process your query!..' ? (
//                   <div style={{display:'flex',justifyContent:'right'}}>
//                     <Button
//                     onClick={handleRetry}
//                     style={{ marginTop: "10px",maxWidth:'300px',border:'2px solid red',backgroundColor:'transparent'}}
//                   >
//                     Regenerate Response <i class="bi bi-arrow-repeat"></i>
//                   </Button>
//                   </div>
                  
//                 ):''}
//               </Col>
//             </Row>
//           )}
//         </Row>
//         :
//         <Row className="pb-3">
//           <Col xs={12} className="text-end" style={{ marginBottom: '20px', paddingRight: '30px',paddingLeft:'30px',backgroundColor : ''}}>
//             <Row style={{ backgroundColor : ''}}>

//               <Col  style={{ margin: 'auto' , backgroundColor : '',display : 'flex',marginRight : '20px',
//                   justifyContent : 'end'}}>
//                 {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
//                   <div key={index} style={{ wordBreak: "break-word", textAlign : 'start', marginRight : '-13px' 
//                     ,backgroundColor : '', maxWidth : '95%'}}>
//                     {decodeContent(line)}
//                   </div>
//                 ))}
//               </Col>
//               <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-1" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
//                 <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
//               </Col>
//             </Row>
//           </Col>
//           <Row className="justify-content-end" style={{backgroundColor : ''}}>

//             <Col xs={11} className="text-end" style={{ marginBottom: '40px',backgroundColor : ''}}>
//               <Row style={{ marginLeft : '-86px',backgroundColor : ''}}>

//                   <Col style={{width: '48px', height: '48px', backgroundColor : '#1F233E', marginRight : '', marginLeft : '7px', borderRadius : '24px'}} xs={1} >
//                     <img style={{ width: '24px', height: '24px' , marginTop : '12px'}} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//                   </Col>
//                 <Col xs={11} style={{ margin: 'auto',paddingTop:'10px',
//                     backgroundColor : '', marginLeft : '10px', width : '90%'}} className={message.agent.length > 55 ? "text-start" : 'text-start'}>
//                 {/* paddingTop:'10px',
//                     backgroundColor : 'pink',width : '620px', marginLeft : '10px' */}
//                   <Row style={{}}>
//                     {message.agent &&
//                       typeof message.agent === 'string' && renderResponse(message.agent)}
//                   </Row>
//                   <Row>
//                   {fileStatus.length > 0 && fileStatus.map((fileStatus, index) => (
//                     <Row key={index}>
//                       <MessageActions
//                         message={{
//                           file: { url: fileStatus.url, mode: 'from_history', status: fileStatus.status }
//                         }}
//                         handleDownload={handleDownload}
//                         handlePreview={handlePreview}
//                       />
//                     </Row>
//                   ))}
//                   </Row>
//                 </Col>
//                 {/* <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//                   <img style={{ width: '48px', height: '48px' }} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//                 </Col> */}
//               </Row>
//               {message.file && (
//                 <Row >
//                   <MessageActions
//                     message={message}
//                     handleDownload={handleDownload}
//                     handlePreview={handlePreview}
//                   />
//                 </Row>
//               )}
//             </Col>
//           </Row>
//         </Row>
//       }
//     </Container>
//   );
// };

// export default MessageCard;


// ================================== Above one is the messagecard component for handling multiple message actions ========================================

//===============================updated code before on 04-09-2024  (below)=====================================
// import React, { useEffect, useState } from "react";
// import { Col, Row, Container, Image, Button } from "react-bootstrap";
// import MessageActions from "./MessageActions";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import TypingIndicator from "./TypingIndicator";
// import black_logo from "../../../assets/ModalX_-orange_Icon.png";
// import white_logo from "../../../assets/modalX_robot_icon_white.png";
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
// import he from 'he';
// import sourceUrl from "../../../api/Source.api";
// import {decode} from 'html-entities';
// import markdownit from 'markdown-it';

// const MessageCard = ({ message, handleDownload, handlePreview }) => {
//   const token = localStorage.getItem('token');
//   console.log("current_messages_from_history", message);
//   const { waitThread,setCurrentThread,setWaitThread,sendMessage,setSending } = useWorkspace();
//   const [theme, setTheme] = useState(localStorage.getItem('theme'));
//   const [fileStatus, setFileStatus] = useState(null);
//   const [input, setInput] = useState("");

//   const findUrl = (msg) => {
//     const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
//     const match = urlRegex.exec(msg);
//     return match ? match[1] : null;
//   };

//   const removeUrlsAndBrackets = (msg) => {
//     return msg.replace(/\(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt)\)/g, '');
//   };

//   useEffect(() => {
//     // Update the theme when it changes
//     const handleThemeChange = () => setTheme(localStorage.getItem('theme'));
//     window.addEventListener('storage', handleThemeChange);

//     return () => {
//       window.removeEventListener('storage', handleThemeChange);
//     };
//   }, []);

//   const handleCopyToClipboard = (text) => {
//     navigator.clipboard.writeText(text)
//       .then(() => {
//         console.log('Link copied to clipboard:', text);
//       })
//       .catch((error) => {
//         console.error('Error copying link to clipboard:', error);
//       });
//   };

//   useEffect(() => {
//     const url = findUrl(message.agent);
//     if (url) {
//       checkStatusFromHistoryUrl(url).then((status) => {
//         setFileStatus(status);
//       });
//     }
//   }, [message.agent]);

//   const checkStatusFromHistoryUrl = async (url) => {
//     try {
//       const response = await fetch(`${sourceUrl}/status`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${token}`
//         },
//         body: JSON.stringify({ url }),
//       });

//       const responseData = await response.json();
//       if (responseData.data.status === "completed") {
//         return "completed";
//       } else if (responseData.data.status === "failed") {
//         return "failed";
//       } else {
//         return null;
//       }
//     } catch (error) {
//       console.error("Check URL status request error:", error);
//       return null;
//     }
//   };

//   const renderers = {
//     a: ({ href, children }) => (
//       <div style={{ display: 'inline-flex', alignItems: 'center', wordBreak: 'break-word' }}>
//         <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
//         <i
//           className="bi bi-copy"
//           style={{ cursor: 'pointer', marginLeft: '20px' }}
//           onClick={() => handleCopyToClipboard(href)}
//           title="Copy link"
//         />
//       </div>
//     ),
//   };

//   const decodeUnicode = (str) => {
//     return str.replace(/\\u[\dA-F]{4}/gi, (match) => {
//       return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
//     });
//   };

//   // const decodeContent = (content) => {
//   //   return decodeUnicode(he.decode(content));
//   // };

//   const md = markdownit({
//     html: true,
//     linkify: true,
//     typographer: true,
//     quotes: '“”‘’',
//   })

// // Function to decode HTML entities
// const decodeContent = (content) => {
//   return decodeUnicode(decode(content));
// };

//   const handleSubmit = async (messageContent) => {
//     setSending(true)
//     setWaitThread(true);
//     if (messageContent.trim()) {
//       const latestEntry = { sender: "user", content: messageContent };
//       setInput(""); // Reset input field
//       setCurrentThread((prevThread) => [...prevThread, latestEntry]);
//       const entryResponse = await sendMessage(latestEntry).catch(console.error);
      
//       if (entryResponse) {
//         setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//         setSending(false)
//       }
//       else{
//         setCurrentThread((prevThread) => [...prevThread, {content:"Unable to process your query!..",sender:'modalX'}]);
//         setSending(false);
//       }
//     }
//     setWaitThread(false);
//     // here add the functionality to check url status and update it in the currentThread
//   };

//   const handleRetry = () => {
//     setCurrentThread((prevThread) => {
//       const lastUserMessage = prevThread.filter(msg => msg.sender === "user").slice(-1)[0];
//       const newThread = prevThread.slice(0, -1);
//       handleSubmit(lastUserMessage.content); // Retry the submission with the last user message
//       return newThread;
//     });
//   };

//   const getFileFormat = (url) => {
//     // Extract the file extension from the URL
//     const parts = url.split('.');
//     if (parts.length > 1) {
//       return parts[parts.length - 1]; // Return the last part as the file format
//     } else {
//       return ''; // Return empty string if no file extension found
//     }
//   };

//   return (
//     <Container fluid > 
//       {message.sender ?
//         <Row className="pb-3" >
//           {message.sender === "user" ? (
//             <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px',paddingLeft:'40px'}}>
//               <Row>
//                 <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
//                   <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
//                 </Col>
//                 <Col xs={11} style={{ margin: 'auto' }}>
//                   {message.content && typeof message.content === 'string' && message.content.split("\n").map((line, index) => (
//                     <div key={index} style={{ wordBreak: "break-word" }}>
//                       {decodeContent(line)}
//                     </div>
//                   ))}
//                 </Col>
//               </Row>
//             </Col>
//           ) : (
//             <Row className="justify-content-end" >
//               <Col xs={11} className="text-end" style={{ marginBottom: '40px'}}>
//                 <Row>
//                   <Col xs={11} style={{ margin: 'auto', paddingRight: message.content.length < 44 ? '20px' : '',paddingTop:'10px'}} className={message.content.length > 55 ? "text-start" : 'text-end'}>
//                     {/* {message.content &&
//                       typeof message.content === 'string' &&
//                       message.content.split("\\n").map((paragraph, index) => {
//                         return (
//                           <div
//                             key={index}
//                             style={{ wordBreak: "break-word" }}
//                           >
//                             <ReactMarkdown children={decodeContent(paragraph)} remarkPlugins={[remarkGfm]} components={renderers} />
//                           </div>
//                         );
//                       })} */}
//                       {message.content &&
//                       typeof message.content === 'string' &&
//                       message.content.split("\\n").map((paragraph, index) => {
//                         const cleanedParagraph = findUrl(message.content) ? removeUrlsAndBrackets(paragraph) : paragraph;
//                         return (
//                           <div
//                             key={index}
//                             style={{ wordBreak: "break-word" }}
//                           >
//                             {/* <ReactMarkdown children={decodeContent(cleanedParagraph)} remarkPlugins={[remarkGfm]} components={renderers} /> */}
//                             {/* <div>{renderers}</div> */}
//                             {/* <Markdown options={{overrides: renderers, }}>{decodeContent(cleanedParagraph)}</Markdown> */}
//                             <div dangerouslySetInnerHTML={{ __html: md.render(decodeContent(cleanedParagraph)) }} />
//                           </div>
//                         );
//                       })}
//                   </Col>
//                   <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//                     <img style={{ width: '48px', height: '48px'}} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//                   </Col>
//                 </Row>
//                 {message.file && (
//                   <Row>
//                     <MessageActions
//                       message={message}
//                       handleDownload={handleDownload}
//                       handlePreview={handlePreview}
//                     />
//                   </Row>
//                 )}
//                 {message.content === 'Unable to process your query!..' ? (
//                   <div style={{display:'flex',justifyContent:'right'}}>
//                     <Button
//                     onClick={handleRetry}
//                     style={{ marginTop: "10px",maxWidth:'300px',border:'2px solid red',backgroundColor:'transparent'}}
//                   >
//                     Regenerate Response <i class="bi bi-arrow-repeat"></i>
//                   </Button>
//                   </div>
                  
//                 ):''}
//               </Col>
//             </Row>
//           )}
//         </Row>
//         :
//         <Row className="pb-3">
//           <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px',paddingLeft:'40px'}}>
//             <Row>
//               <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
//                 <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
//               </Col>
//               <Col xs={11} style={{ margin: 'auto' }}>
//                 {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
//                   <div key={index} style={{ wordBreak: "break-word" }}>
//                     {decodeContent(line)}
//                   </div>
//                 ))}
//               </Col>
//             </Row>
//           </Col>
//           <Row className="justify-content-end">
//             <Col xs={11} className="text-end">
//               <Row>
//                 <Col xs={11} style={{ margin: 'auto', paddingLeft: '40px', marginBottom: '40px',paddingTop:'10px'}} className={message.agent.length > 55 ? "text-start" : 'text-end'}>
//                   <Row>
//                     {message.agent &&
//                       typeof message.agent === 'string' &&
//                       message.agent.split("\\n").map((paragraph, index) => {
//                         let updatedParagraph = paragraph;
//                         if (findUrl(message.agent) && findUrl(message.agent).includes('dailylimitexceeded')) {
//                           // updatedParagraph = 'Your daily '+ getFileFormat(findUrl(message.agent))+' generation limit was exceeded. Consider upgrading to our premium plan for unlimited access to features.' ;
//                           updatedParagraph = `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ getFileFormat(findUrl(message.agent))+` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team
//                           ` ;
//                           return (
//                             <div
//                               key={index}
//                               style={{ wordBreak: "break-word" }}
//                             >

//                               {/* <ReactMarkdown children={decodeContent(updatedParagraph)} remarkPlugins={[remarkGfm]} components={renderers} /> */}
//                               {/* <Markdown options={{overrides: renderers, }}>{decodeContent(updatedParagraph)}</Markdown> */}
//                               <div dangerouslySetInnerHTML={{ __html: md.render(decodeContent(updatedParagraph)) }} />
//                             </div>
//                           );
//                         }
//                         const cleanedParagraph = findUrl(message.agent) ? removeUrlsAndBrackets(paragraph) : paragraph;
//                         return (
//                           <div
//                             key={index}
//                             style={{ wordBreak: "break-word"}}
//                           >
//                             {/* <ReactMarkdown children={decodeContent(cleanedParagraph)} remarkPlugins={[remarkGfm]} components={renderers} /> */}
//                             {/* <Markdown options={{overrides: renderers, }}>{decodeContent(cleanedParagraph)}</Markdown> */}
//                             <div dangerouslySetInnerHTML={{ __html: md.render(decodeContent(cleanedParagraph)) }} />
//                           </div>
//                         );
//                       })}
//                   </Row>
//                   <Row>
//                     {
//                       findUrl(message.agent) &&
//                       <Row>
//                         <MessageActions
//                           message={{
//                             file: { url: findUrl(message.agent), mode: 'from_history', status: fileStatus }
//                           }}
//                           handleDownload={handleDownload}
//                           handlePreview={handlePreview}
//                         />
//                       </Row>
//                     }
//                   </Row>
//                 </Col>
//                 <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//                   <img style={{ width: '48px', height: '48px' }} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//                 </Col>
//               </Row>
//               {message.file && (
//                 <Row>
//                   <MessageActions
//                     message={message}
//                     handleDownload={handleDownload}
//                     handlePreview={handlePreview}
//                   />
//                 </Row>
//               )}
//             </Col>
//           </Row>
//         </Row>
//       }
//     </Container>
//   );
// };

// export default MessageCard;

//==========================================updated code before on 04-09-2024  (above)======================================================


//==========================================Shabna Code Start (Update Date - 04-09-2024)======================================================

import React, { useEffect, useState,useRef } from "react";
import { Col, Row, Container, Image, Button } from "react-bootstrap";
import MessageActions from "./MessageActions";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import TypingIndicator from "./TypingIndicator";
import black_logo from "../../../assets/ModalX_-orange_Icon.png";
import white_logo from "../../../assets/modalX_robot_icon_white.png";
// updatedModalxLogo
import updatedModalxLogo from '../../../assets/updatedModalxLogo.png'
import errorIconWhileGenerating from '../../../assets/errorIconWhileGenerating.png'
import userIcon from '../../../assets/userIcon.png'
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import he from 'he';
import sourceUrl from "../../../api/Source.api";
import {decode} from 'html-entities';
import markdownit from 'markdown-it';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import './custom-markdown-styles.css'
import { agent_conversation_bg_color, defaultSettings_button_bg_color, hovered_chat_bg_color_dm, selected_chat_bg_color_dm, user_conversation_bg_color } from "../../../constants/colors";
import EditLastPrompt from "./EditLastPrompt";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useMediaQuery } from "react-responsive";
import { Box, Typography,CardMedia } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import SettingsButtonBelowImagePreview from "../../SettingsButtonBelowImagePreview";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { useCreditRecharge } from "../../../contexts/CreditRechageContext";
import { containsMediaFile } from "../../../contexts/WorkspaceUtils";



const MessageCard = ({ message, handleDownload, handlePreview,index }) => {

  const token = localStorage.getItem('token');
  // console.log("current_messages_from_history", message); 
  const { waitThread,setCurrentThread,setWaitThread,sendMessage,setSending,currentThread,editClick,setEditClick,stopMessageIdRef, setVisibleStopGenerationButton } = useWorkspace();
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const [fileStatus, setFileStatus] = useState([]);
  const [input, setInput] = useState("");
  const [hoveredOverEditIcon, setHoveredOverEditIcon] = useState(false);
  const editPromptRef = useRef(null);
  const isUnder991px = useMediaQuery({ maxWidth:991});
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const navigate = useNavigate();
  const {setShowSignUpModal} = useAuth();
  const { upgradePlan } = useCreditRecharge()

  const handleEditClick = () => {
    setEditClick(true)
    if (editPromptRef.current) {
      editPromptRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt|pdf|docx))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const findUrls = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt|pdf|docx))/g;
    let match;
    const urls = [];
    while ((match = urlRegex.exec(msg)) !== null) {
      urls.push(match[0]);
    }
    return urls;
  };

  // const findUrls = (msg) => {
  //   const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
  //   const matches = msg.match(urlRegex); // Use match to find all occurrences
  //   return matches ? matches : null; // Return all matches or null if no matches
  // };


  const removeUrlsAndBrackets = (msg) => {
    return msg.replace(/\(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt|pdf|docx)\)/g, '');
  };

  useEffect(() => {
    // Update the theme when it changes
    const handleThemeChange = () => setTheme(localStorage.getItem('theme'));
    window.addEventListener('storage', handleThemeChange);

    return () => {
      window.removeEventListener('storage', handleThemeChange);
    };
  }, []);

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Link copied to clipboard:', text);
      })
      .catch((error) => {
        console.error('Error copying link to clipboard:', error);
      });
  };

  // Previous Useeffect for handling status of each file from history

  // useEffect(() => {
  //   const url = findUrl(message.agent);
  //   if (url) {
  //     checkStatusFromHistoryUrl(url).then((status) => {
  //       setFileStatus(status);
  //     });
  //   }
  // }, [message.agent]);



  useEffect(() => {
    const urls = findUrls(message.agent); // Use findUrls to get all URLs
  
    if (urls && urls.length > 0) {
      // Initialize file object with all URLs having "processing" status
      const initialFiles = urls.map(url => ({ url, status: "processing" }));
  
      // Map over currentThread to find the matching message and add the file key
      setCurrentThread(prevThread =>
        prevThread.map(ele => {
          if (ele.agent === message.agent) {
            // Add the file key to the message
            return { ...ele, file: initialFiles, status: "processing" };
          }
          return ele;
        })
      );
  
      // Batch request for all URLs
      const fetchBatchStatus = async () => {
        try {
          const batchResponse = await fetch(`${sourceUrl}/status/batch`, {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              ...(isLoggedIn && { Authorization: `Bearer ${token}` })
            },
            body: JSON.stringify({ urls }),
          });
          const responseData = await batchResponse.json();
  
          // Iterate through each response item
          responseData.data?.forEach((responseItem, index) => {
            setCurrentThread(prevThread =>
              prevThread?.map(ele => {
                if (ele.agent === message.agent) {
                  const updatedFiles = ele?.file?.map((file, fileIndex) =>
                    fileIndex === index
                      // ? { ...file, status:  "processing" } // Update the file status
                      ? { ...file, status: responseItem.status } // Update the file status
                      : file
                  );
  
                  // Check if all files are either completed or failed
                  const allFilesCompletedOrFailed = updatedFiles?.every(
                    file => file.status === "completed" || file.status === "failed"
                  );
  
                  // Check if any file is still "processing"
                  const hasProcessingFile = updatedFiles?.some(
                    file => file.status !== "completed" && file.status !== "failed"
                  );
  
                  // setFileStatus("processing");
                  setFileStatus(responseItem.status);
  
                  // Update status: "processing" if any file is still processing,
                  // Otherwise, mark it as "completed"
                  return {
                    ...ele,
                    file: updatedFiles,
                    // status:  "processing" 
                    status: hasProcessingFile ? "processing" : "completed",
                  };
                }
                return ele;
              })
            );
          });
        } catch (error) {
          console.error("Error fetching batch status:", error);
        }
      };
  
      // Call the batch API once to process all file statuses
      fetchBatchStatus();
    }
  }, [message.agent, setCurrentThread]);
  
  
  

  useEffect(() => {
    const handleCopy = (event) => {
      event.preventDefault();
  
      const selection = window.getSelection();
      const selectedText = selection.toString();
      const selectedHtml = selection.getRangeAt(0).cloneContents();
  
      const div = document.createElement('div');
      div.appendChild(selectedHtml);
  
      const elementsWithWhiteText = div.querySelectorAll('*');
      elementsWithWhiteText.forEach((element) => {
        const computedStyle = window.getComputedStyle(element);
        if (computedStyle.color === 'rgb(255, 255, 255)') { 
          element.style.color = 'black'; 
        }
      });

      event.clipboardData.setData('text/plain', selectedText);
      event.clipboardData.setData('text/html', div.innerHTML);
    };
  
    document.addEventListener('copy', handleCopy);
    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, []);
  

  const checkStatusFromHistoryUrl = async (url) => {
    try {
      const response = await fetch(`${sourceUrl}/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ url }),
      });

      const responseData = await response.json();
      if (responseData.data.status === "completed") {
        return "completed";
      } else if (responseData.data.status === "failed") {
        return "failed";
      } else {
        return null;
      }
    } catch (error) {
      console.error("Check URL status request error:", error);
      return null;
    }
  };

  const renderers = {
    a: ({ href, children }) => (
      <div style={{ display: 'inline-flex', alignItems: 'center', wordBreak: 'break-word' }}>
        <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
        <i
          className="bi bi-copy"
          style={{ cursor: 'pointer', marginLeft: '20px' }}
          onClick={() => handleCopyToClipboard(href)}
          title="Copy link"
        />
      </div>
    ),
  };

  const decodeUnicode = (str) => {
    // const stringWithoutDoubleBackslashes = str.split("\\\")
    const stringNewLine = str?.split("\\n").join("\n")
    let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
      const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
      return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
    });
    let finalString = stringNewLineWithAnchorTag.replace(/\\/g, '');
    if (finalString.includes("onboarding_assets_suggested")) {
      finalString = finalString.replace("onboarding_assets_suggested", '');
    }
    return finalString
  };

// Function to decode HTML entities
const decodeContent = (content) => {
  return decodeUnicode((content));
};

  const handleSubmit = async (messageContent) => {
    setSending(true)
    setVisibleStopGenerationButton(true)
    setWaitThread(true);
    if (messageContent.trim()) {
      const latestEntry = { sender: "user", content: messageContent };
      setInput(""); // Reset input field
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      // if(findUrl(entryResponse.content)){
      //   // setHasLink(true)
      //   await extractLinks(entryResponse.content)
      //   }
      if (entryResponse) {
        if(entryResponse.content === ""){
          setCurrentThread((prevThread) => [...prevThread, {content:"There was an error while generating a response",sender:'modalX'}]);
          setSending(false)
        }
        else if(stopMessageIdRef.current.includes(entryResponse.unique_id)){
          setCurrentThread((prevThread)=>[...prevThread])
          setVisibleStopGenerationButton(false);
        }
        else{
          // setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          if(containsMediaFile(entryResponse.content)){
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          }else{
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
            setVisibleStopGenerationButton(false);
          }
        }
      }
      else{
        setCurrentThread((prevThread) => [...prevThread, {content:"Unable to process your query!..",sender:'modalX'}]);
        setSending(false);
        setVisibleStopGenerationButton(false);
      }
    }
    setWaitThread(false);
    // here add the functionality to check url status and update it in the currentThread
  };

  const handleRetry = () => {
    setCurrentThread((prevThread) => {
      const lastUserMessage = prevThread.filter(msg => msg.sender === "user").slice(-1)[0];
      const newThread = prevThread.slice(0, -1);
      handleSubmit(lastUserMessage.content); // Retry the submission with the last user message
      return newThread;
    });
  };

    function containsURL(inputString) {
      const mediaFileRegex = /(https?:\/\/[^\s]+?\.(jpeg|jpg|png|gif|mp3|mp4|pdf|docx|txt|zip|rar))/gi;
      const websiteRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/gi;
    
      if (mediaFileRegex.test(inputString)) {
        return true; // Media file link found
      }
      if (websiteRegex.test(inputString)) {
        return false; // General website link found
      }
      return false;
    }
    
  

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url?.split('.');
    if (parts?.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };

  const removeImageExclamations = (content) => {
    return content.replace(/!\[([^\]]+)\]\((.*?)\)/g, '[$1]($2)');
  };


  const handlePremium = (action) => {
    if (action === 'sign up') {
      setShowSignUpModal(true); 
      navigate("/");
    }
    else if(action === 'premium plan'){
      upgradePlan();
    }
    else if(action === 'benefits'){
      navigate('/pricing')
    }
  };
  
  function renderResponse(response) {  
    if (findUrl(response) && findUrl(response).includes('dailylimitexceeded')) {
      const premiumPlanLink = (
        <span
          onClick={() => handlePremium("premium plan")}
          onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          style={{ color: "#f6be6b", cursor: "pointer"}}
        >
          premium plan
        </span>
      );
  
      const signUpLink = (
        <span
          onClick={() => handlePremium("sign up")}
          onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          style={{ color: "#f6be6b", cursor: "pointer"}}
        >
          signing up
        </span>
      );

      const benefits = (
        <span
          onClick={() => handlePremium("benefits")}
          onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          style={{ color: "#f6be6b", cursor: "pointer"}}
        >
          benefits
        </span>
      );

      let updatedParagraph = isLoggedIn ? (
        <>
          Hi there,<br /><br />
          It looks like you’ve reached your daily limit for generating {getFileFormat(findUrl(response))} files. We’re really sorry about that! We know how important uninterrupted access is for you.<br /><br />
          To keep enjoying all the great {benefits} and have a seamless experience, we’d love for you to consider upgrading to our {premiumPlanLink}.<br /><br />
          Thank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.<br /><br />
          Warm regards,<br /><br />
          The ModalX Team
        </>
      ) : (
        <>
          Hi there,<br /><br />
          It looks like you’ve reached your daily limit for generating {getFileFormat(findUrl(response))} files.We’re really sorry about that! We know how important uninterrupted access is for you.<br /><br />
          To continue enjoying all the amazing {benefits} and experience uninterrupted service, we invite you to {signUpLink} and upgrade to our {premiumPlanLink} for an enhanced and seamless experience.<br /><br />
          Thank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.<br /><br />
          Warm regards,<br /><br />
          The ModalX Team
        </>
      );
  
      return (
        <div style={{ wordBreak: "break-word" }}>
          {updatedParagraph}
        </div>
      );
    } else {
      const cleanedParagraph = findUrls(response) ? removeUrlsAndBrackets(response) : response;
      
      if (cleanedParagraph.includes('Hi there,\n\nIt looks like you’ve reached your daily limit for generating')) {
        const premiumPlanLink = (
          <span
            onClick={() => handlePremium("premium plan")}
            onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            style={{ color: "#f6be6b", cursor: "pointer"}}
          >
            premium plan
          </span>
        );
  
        const signUpLink = (
          <span
            onClick={() => handlePremium("sign up")}
            onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            style={{ color: "#f6be6b", cursor: "pointer",}}
          >
            signing up
          </span>
        );
        const benefits = (
          <span
            onClick={() => handlePremium("benefits")}
            onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            style={{ color: "#f6be6b", cursor: "pointer"}}
          >
            benefits
          </span>
        );
  
        let updatedParagraph = isLoggedIn ? (
          <>
            Hi there,<br /><br />
            It looks like you’ve reached your daily limit for generating {getFileFormat(findUrl(response))} files. We’re really sorry about that! We know how important uninterrupted access is for you.<br /><br />
            To keep enjoying all the great {benefits} and have a seamless experience, we’d love for you to consider upgrading to our {premiumPlanLink}.<br /><br />
            Thank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.<br /><br />
            Warm regards,<br /><br />
            The ModalX Team
          </>
        ) : (
          <>
            Hi there,<br /><br />
            It looks like you’ve reached your daily limit for generating {getFileFormat(findUrl(response))} files.We’re really sorry about that! We know how important uninterrupted access is for you.<br /><br />
            To continue enjoying all the amazing {benefits} and experience uninterrupted service, we invite you to {signUpLink} and upgrade to our {premiumPlanLink} for an enhanced and seamless experience.<br /><br />
            Thank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.<br /><br />
            Warm regards,<br /><br />
            The ModalX Team
          </>
        );
  
        return (
          <div style={{ wordBreak: "break-word" }}>
            {updatedParagraph}
          </div>
        );
      } else {
        return (
          <div style={{ wordBreak: "break-word" }}>
            <MDEditor.Markdown
              source={decodeUnicode(cleanedParagraph)}
              style={{
                backgroundColor: agent_conversation_bg_color,
                color: "white",
                fontSize: isUnder991px ? "14px" : "16px",
                fontFamily: "Inter"
              }}
              className="custom-md-editor"
            />
          </div>
        );
      }
    }
  }
  
  

var imagePreview = false
function checkJPEG(url) {

  
  const imageExtensionPattern = /\.(jpeg|jpg|png|gif|bmp|webp|svg)$/i; // Regular expression for image extensions

  if (imageExtensionPattern.test(url)) {
    imagePreview = true;
    
  }

}

function extractURL(text) {
  const urlPattern = /(https?:\/\/[^\s)]+)/g; // Regular expression to match URLs
  const matches = text.match(urlPattern);     // Extracts all matching URLs
  
  if (matches) {
    matches.forEach(url => checkJPEG(url)); // Print each URL found
  } else {
    // console.log('No URLs found');
  }
}

const renderFirstTimeResponse = (response) =>{
    const cleanedParagraph = findUrls(response) ? removeUrlsAndBrackets(response) : response;
    if (cleanedParagraph.includes('Hi there,\n\nIt looks like you’ve reached your daily limit for generating')) {
      const premiumPlanLink = (
        <span
          onClick={() => handlePremium("premium plan")}
          onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          style={{ color: "#f6be6b", cursor: "pointer"}}
        >
          premium plan
        </span>
      );

      const signUpLink = (
        <span
          onClick={() => handlePremium("sign up")}
          onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          style={{ color: "#f6be6b", cursor: "pointer"}}
        >
          signing up
        </span>
      );
      const benefits = (
        <span
          onClick={() => handlePremium("benefits")}
          onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          style={{ color: "#f6be6b", cursor: "pointer"}}
        >
          benefits
        </span>
      );

      let updatedParagraph = isLoggedIn ? (
        <>
          Hi there,<br /><br />
          It looks like you’ve reached your daily limit for generating {getFileFormat(findUrl(response))} files. We’re really sorry about that! We know how important uninterrupted access is for you.<br /><br />
          To keep enjoying all the great {benefits} and have a seamless experience, we’d love for you to consider upgrading to our {premiumPlanLink}.<br /><br />
          Thank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.<br /><br />
          Warm regards,<br /><br />
          The ModalX Team
        </>
      ) : (
        <>
          Hi there,<br /><br />
          It looks like you’ve reached your daily limit for generating {getFileFormat(findUrl(response))} files.We’re really sorry about that! We know how important uninterrupted access is for you.<br /><br />
          To continue enjoying all the amazing {benefits} and experience uninterrupted service, we invite you to {signUpLink} and upgrade to our {premiumPlanLink} for an enhanced and seamless experience.<br /><br />
          Thank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.<br /><br />
          Warm regards,<br /><br />
          The ModalX Team
        </>
      );

      return (
        <div style={{ wordBreak: "break-word" }}>
          {updatedParagraph}
        </div>
      );
    }
    else{
      return (<div style={{ wordBreak: "break-word"  }}>
        <MDEditor.Markdown source={decodeUnicode(cleanedParagraph)} style={{ backgroundColor: message.content === 'There was an error while generating a response'  ?"#171A2D" :agent_conversation_bg_color,
           color: message.content === 'There was an error while generating a response' ?"#F2B8B5" :'white',  fontSize: isUnder991px ? "14px" : "16px", fontFamily:"Inter"}}  
           className="custom-md-editor" previewOptions={{rehypePlugins: [[rehypeSanitize]]}} />
      </div>)
    }
     
}



  return (
  //   <Container fluid > 
  //   {message.sender ?
  //     <Row className="pb-3" >
  //       {message.sender === "user" ? (
  //         <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px',paddingLeft:'40px'}}>
  //           <Row>
  //             <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
  //               <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
  //             </Col>
  //             <Col xs={11} style={{ margin: 'auto' }}>
  //               {message.content && typeof message.content === 'string' && message.content.split("\n").map((line, index) => (
  //                 <div key={index} style={{ wordBreak: "break-word" }}>
  //                   {decodeContent(line)}
  //                 </div>
  //               ))}
  //             </Col>
  //           </Row>
  //         </Col>
  //       ) : (
  //         <Row className="justify-content-end" >
  //           <Col xs={11} className="text-end" style={{ marginBottom: '40px'}}>
  //             <Row>
  //               <Col xs={11} style={{ margin: 'auto', paddingRight: message.content.length < 44 ? '20px' : '',paddingTop:'10px'}} className={message.content.length > 55 ? "text-start" : 'text-end'}>
  //                 {/* {message.content &&
  //                   typeof message.content === 'string' &&
  //                   message.content.split("\\n").map((paragraph, index) => {
  //                     return (
  //                       <div
  //                         key={index}
  //                         style={{ wordBreak: "break-word" }}
  //                       >
  //                         <ReactMarkdown children={decodeContent(paragraph)} remarkPlugins={[remarkGfm]} components={renderers} />
  //                       </div>
  //                     );
  //                   })} */}
  //                   {message.content &&
  //                   typeof message.content === 'string' && renderFirstTimeResponse(message.content)}
  //               </Col>
  //               <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
  //                 <img style={{ width: '48px', height: '48px'}} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
  //               </Col>
  //             </Row>
  //             {message.file && (
  //               <Row>
  //                 <MessageActions
  //                   message={message}
  //                   handleDownload={handleDownload}
  //                   handlePreview={handlePreview}
  //                 />
  //               </Row>
  //             )}
  //             {message.content === 'Unable to process your query!..' ? (
  //               <div style={{display:'flex',justifyContent:'right'}}>
  //                 <Button
  //                 onClick={handleRetry}
  //                 style={{ marginTop: "10px",maxWidth:'300px',border:'2px solid red',backgroundColor:'transparent'}}
  //               >
  //                 Regenerate Response <i class="bi bi-arrow-repeat"></i>
  //               </Button>
  //               </div>
                
  //             ):''}
  //           </Col>
  //         </Row>
  //       )}
  //     </Row>
  //     :
  //     <Row className="pb-3">
  //       <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px',paddingLeft:'40px'}}>
  //         <Row>
  //           <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
  //             <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
  //           </Col>
  //           <Col xs={11} style={{ margin: 'auto' }}>
  //             {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
  //               <div key={index} style={{ wordBreak: "break-word" }}>
  //                 {decodeContent(line)}
  //               </div>
  //             ))}
  //           </Col>
  //         </Row>
  //       </Col>
  //       <Row className="justify-content-end">
  //         <Col xs={11} className="text-end">
  //           <Row>
  //             <Col xs={11} style={{ margin: 'auto', paddingLeft: '40px', marginBottom: '40px',paddingTop:'10px'}} className={message.agent.length > 55 ? "text-start" : 'text-end'}>
  //               <Row>
  //                 {message.agent &&
  //                   typeof message.agent === 'string' && renderResponse(message.agent)}
  //               </Row>
  //               <Row>
  //                 {
  //                   findUrl(message.agent) &&
  //                   <Row>
  //                     <MessageActions
  //                       message={{
  //                         file: { url: findUrl(message.agent), mode: 'from_history', status: fileStatus }
  //                       }}
  //                       handleDownload={handleDownload}
  //                       handlePreview={handlePreview}
  //                     />
  //                   </Row>
  //                 }
  //               </Row>
  //             </Col>
  //             <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
  //               <img style={{ width: '48px', height: '48px' }} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
  //             </Col>
  //           </Row>
  //           {message.file && (
  //             <Row>
  //               <MessageActions
  //                 message={message}
  //                 handleDownload={handleDownload}
  //                 handlePreview={handlePreview}
  //               />
  //             </Row>
  //           )}
  //         </Col>
  //       </Row>
  //     </Row>
  //   }
  // </Container>

    <Container fluid > 
      {message.sender ?
        <Row className="pb-0" style={{backgroundColor : '', padding:"0px", margin:"0px 0px 12px 0px" }} >
          {message.sender === "user" ? (
            // <Col xs={12} className="text-start" style={{ marginBottom: '20px', paddingRight: '30px',paddingLeft:'40px', backgroundColor: ''}}>
            //   <Row style={{ backgroundColor : 'red' , marginTop : '40px', marginBottom : '20px'}}>
            //     <div>{index === currentThread.length - 2 && !editClick && <Button onClick={() => setEditClick(true)}>edit</Button>}</div>
            //     <Col  style={{ margin: 'auto' , backgroundColor : user_conversation_bg_color ,display : 'flex',flexDirection:'column', maxWidth:"455px",marginRight : '20px',borderRadius:'8px',
            //         justifyContent : 'start'}}>
            //       {index === currentThread.length - 2 && editClick && <EditLastPrompt msg={message}/>}
            //       {(index !== currentThread.length - 2 || !editClick) && message.content && typeof message.content === 'string' && message.content.split("\n").map((line, index) => (
            //         <div key={index} style={{ wordBreak: "break-word", textAlign : 'start', marginRight : '-13px', padding : '12px'
            //           ,backgroundColor : ''}}>
            //           {decodeContent(line)}
            //         </div>
            //       ))}
            //     </Col>
            //     <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-1" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
            //       <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
            //     </Col>
            //   </Row>
            // </Col>
            // Render Request In First Time Here for big and small Screen  ======================================   
            <Row style={{backgroundColor : '', padding:"0px", margin:"0px"}}>
            <Col xs={12} className="text-end" style={{ marginBottom: '20px', marginRight:"0px", backgroundColor: '', padding:"0px" , marginLeft: '0px', }}>
              <Row style={{ backgroundColor: '', marginTop: '40px', marginBottom: '20px', justifyContent: 'flex-end', alignItems: 'start', padding:"0px", marginLeft:"0px", marginRight:"0px" }}>
                
                {/* Edit Button */}
               
            {
            isUnder991px ? 
            <Row style={{justifyContent:"flex-end", rowGap:"16px",backgroundColor:"", padding:"0px",  margin:"0px"}}>
                  {/* User Icon */}
              <Col xs="auto" className="d-flex flex-column justify-content-start align-items-start p-0 m-0" style={{
                backgroundColor: '#1F233E',
                borderRadius: '50%',
                width: '48px',
                height: '48px',
                // backgroundColor: 'green',
                display : "flex",
                alignItems: 'start',
              }}>
                <div style={{width : '48px' , height : '48px', backgroundColor:""}}>
                  <img src={userIcon} alt="" style={{width : '48px' , height : '48px', backgroundColor:""}}/>

                </div>
                {/* <i className="bi bi-person fs-4" style={{ margin: 'auto' }} /> */}
              </Col>    
                  {/* <div > */}
              <Row style={{justifyContent:"flex-end",display:"flex", flexDirection:"row", flexWrap:"nowrap"}}>
              {index === currentThread.length - 2 && !editClick && (
                <Col xs="auto" style={{ display: 'flex', alignItems: 'center',backgroundColor: ''}}>
                  {/* <Button onClick={() => setEditClick(true)} style={{ height: 'fit-content' }}>Edit</Button> */}
                  <Button
                    onClick={() => setEditClick(true)}
                    style={{
                      width: '36px',
                      height: '36px',
                      display: 'flex',
                      justifyContent:"center",
                      backgroundColor: hoveredOverEditIcon ? "#282D51" : "#1F233A", // Change background on hover
                      borderRadius: '50%',
                      // transition: 'background-color 0.3s, opacity 0.3s',
                      // opacity: hoveredOverEditIcon ? 0.06 : 1, // Change opacity on hover
                      border:'none'
                    }}
                    onMouseOver={() => setHoveredOverEditIcon(true)}
                    onMouseOut={() => setHoveredOverEditIcon(false)}
                  >
                    <EditOutlinedIcon />
                  </Button>
                </Col>
              )}

              {/* Message Content */}
              <Col xs="auto" style={{
                backgroundColor: '#2F355F',
                display: 'flex',
                flexDirection: 'column',
                // width: index === currentThread.length - 1 ?  '350px' : (editClick ? '350px' : '302px'),
                maxWidth:index === currentThread.length - 1 ?  '350px' : (editClick ? '350px' : '302px'),
                borderRadius: '8px',
                paddingRight : '12px',
                marginRight: isUnder991px? "0px" : '20px',
                justifyContent: 'center',
               
                
              }}>
                {index === currentThread.length - 2 && editClick && <EditLastPrompt msg={message} />}
                {(index !== currentThread.length - 2 || !editClick) && message.content && typeof message.content === 'string' && message.content?.split("\n").map((line, index) => (
                  <div key={index} style={{ wordBreak: 'break-word', textAlign: 'start', marginRight: isUnder991px ? "0px" : '-13px', padding: '12px', backgroundColor: '' }}>
                    {/* {decodeContent(line)} */}
                    <MDEditor.Markdown source={decodeContent(line)} style={{ backgroundColor: user_conversation_bg_color, color:"white", fontSize: isUnder991px ? "14px" : "16px", fontFamily:"Inter"}}  className="custom-md-editor" previewOptions={{rehypePlugins: [[rehypeSanitize]]}} />
                  </div>
                ))}
              </Col>
              {/* </div> */}
              </Row>
            
            </Row>
            : 
            <Row style={{justifyContent:"flex-end", backgroundColor:"", padding:"0px", margin:"0px"}}>
                    {/* <div > */}
                {index === currentThread.length - 2 && !editClick && (
                  <Col xs="auto" style={{ display: 'flex', alignItems: 'center',backgroundColor: '' }}>
                    {/* <Button onClick={() => setEditClick(true)} style={{ height: 'fit-content' }}>Edit</Button> */}
                    <Button
                      onClick={() => setEditClick(true)}
                      style={{
                        width: '48px',
                        height: '48px',
                        backgroundColor: hoveredOverEditIcon ? "#282D51" : "#1F233A", // Change background on hover
                        borderRadius: '50%',
                        // transition: 'background-color 0.3s, opacity 0.3s',
                        // opacity: hoveredOverEditIcon ? 0.06 : 1, // Change opacity on hover
                        border:'none'
                      }}
                      onMouseOver={() => setHoveredOverEditIcon(true)}
                      onMouseOut={() => setHoveredOverEditIcon(false)}
                    >
                      <EditOutlinedIcon />
                    </Button>
                  </Col>
                )}

                {/* Message Content */}
                <Col xs="auto" style={{
                  backgroundColor: '#2F355F',
                  display: 'flex',
                  flexDirection: 'column',
                  // width: editClick ?  '46vw' : '42vw',
                  width:editClick ?   '661px' : '661px',
                  borderRadius: '8px',
                  paddingRight : '20px',
                  marginRight: '20px',
                  justifyContent: 'center',
                 
                  
                }}>
                  {/* {index === currentThread.length - 2 && editClick && <EditLastPrompt msg={message} />} */}
                  {((index === currentThread.length - 2 || index === currentThread.length - 1) && editClick) && <EditLastPrompt msg={message} />}
                  {/* {(index !== currentThread.length - 2 || !editClick) && message.content && typeof message.content === 'string' && message.content.split("\n").map((line, index) => (
                    <div key={index} style={{ wordBreak: 'break-word', textAlign: 'start', marginRight: '-13px', padding: '12px', backgroundColor: '' }}>
                      <MDEditor.Markdown source={decodeContent(line)} style={{ backgroundColor: user_conversation_bg_color, color:"white"}}  className="custom-md-editor" previewOptions={{rehypePlugins: [[rehypeSanitize]]}} />
                    </div>
                  ))} */}
                  {((index !== currentThread.length - 2 || index !== currentThread.length - 1) && !editClick) && message.content && typeof message.content === 'string' && message.content?.split("\n").map((line, index) => (
                    <div key={index} style={{ wordBreak: 'break-word', textAlign: 'start', marginRight: isUnder991px ? "0px" : '-13px', padding: '12px', backgroundColor: '' }}>
                      {/* {decodeContent(line)} */}
                      <MDEditor.Markdown source={decodeContent(line)} style={{ backgroundColor: user_conversation_bg_color, color:"white",  fontSize: isUnder991px ? "14px" : "16px", fontFamily:"Inter"}}  className="custom-md-editor" previewOptions={{rehypePlugins: [[rehypeSanitize]]}} />
                    </div>
                  ))}
                </Col>
                {/* </div> */}

                {/* User Icon */}
                <Col xs="auto" className="d-flex flex-column justify-content-start align-items-start p-0 m-1" style={{
                  backgroundColor: '#1F233E',
                  borderRadius: '50%',
                  width: '48px',
                  height: '48px',
                  // backgroundColor: 'green',
                  display : "flex",
                  alignItems: 'start',
                }}>
                  <div style={{width : '48px' , height : '48px', backgroundColor:""}}>
                    <img src={userIcon} alt="" style={{width : '48px' , height : '48px', backgroundColor:""}}/>

                  </div>
                  {/* <i className="bi bi-person fs-4" style={{ margin: 'auto' }} /> */}
                </Col>
            </Row>
            }

              </Row>
            </Col></Row>
          ) : (
            //Render Response In First Time Here for big and small Screen  ======================================   
            <Row className="justify-content-start" style={{ backgroundColor : '',margin:"0px" , padding:"0px",border:"",}} >
                          
              <Col xs={11} className="text-end" style={{width:"100%", marginBottom: '0px',backgroundColor : '', padding:"0px"}}>
                <Row style={{ marginLeft  :isUnder991px ? "0px": '0px',display:"flex", flexDirection:isUnder991px ? "column" : "row", rowGap: isUnder991px ? "16px" : "0px" }} >
                <Col style={{width: '48px', height: '48px', backgroundColor : '', marginRight : '', marginLeft : isUnder991px ? "0px" : '15px', borderRadius : '24px',display : "flex" , justifyContent : "center", alignItems :"center", border:""}} xs={1} >
                    <img style={{ width: '48px', height: '48px', marginTop : '',backgroundColor : ''}} src={theme === 'dark' ? updatedModalxLogo : white_logo} alt="modalx_logo" />
                </Col>
                  {message.content === 'There was an error while generating a response' &&
                      (isUnder991px ? (
                        <Box
                          sx={{
                            width: '20px',
                            height: '20px',
                            marginTop: '-28px',
                            marginLeft: '25px',
                            borderRadius: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img src={errorIconWhileGenerating} alt="Error Icon" />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: '20px',
                            height: '20px',
                            marginTop: '40px',
                            marginLeft: '-20px',
                            borderRadius: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img src={errorIconWhileGenerating} alt="Error Icon" />
                        </Box>
                      ))}   
                  <Col style={{ marginLeft: isUnder991px ? "0px" : "0px", padding: isUnder991px ? "0px" : "0px 0px 0px 12px" }}> 
                      {message?.file &&
                      <>
                      {extractURL(message.content)}
                      <Col style={{ 
                        maxWidth : '800px',
                        width:"100%",
                        display: imagePreview ? 'flex' : '', 
                        flexWrap: imagePreview ? 'wrap' :'', 
                        // justifyContent: imagePreview ?'space-evenly' :'', 
                        // flexBasis: imagePreview ?  'calc(50% - 10px)' : '', /* Each item takes 50% of the row minus some gap */ 
                        gap: "10px", /* Optional: adds space between items */
                        border:"",
                        margin:"0px 0px",
                        padding:"0px 0px",
                      }}
>
                            <MessageActions
                              message={message}
                              handleDownload={handleDownload}
                              handlePreview={handlePreview}
                            /> 
                      </Col>
                      {!isUnder991px && message?.status === 'completed' && <Box sx={{margin: isUnder991px ? "0px 0px 0px 12px" : "0px", border:""}}> <SettingsButtonBelowImagePreview message={message}/></Box>} 
                      </>

                      }
                      {message?.status? message?.status === 'completed' &&  !containsURL(message?.content) &&
                        <Col xs={11} style={{
                          margin: 'auto', padding: '10px', borderRadius: "8px",
                          backgroundColor: message.content === 'There was an error while generating a response' ? "" : agent_conversation_bg_color,
                          border: message.content === 'There was an error while generating a response' ? '1px solid #F2B8B5' : '',
                          // width : '100%',
                          marginLeft: isUnder991px ? "0px" : '0px',
                          maxWidth: message.content === 'There was an error while generating a response' ? '388px' : '604px'
                        }} className={message.content.length > 55 ? "text-start" : 'text-start'}>
                          {message.content &&
                            typeof message.content === 'string' && renderFirstTimeResponse(message.content)}
                        </Col> :
                        <Col xs={11} style={{
                          margin: 'auto', padding: '10px', borderRadius: "8px",
                          backgroundColor: message.content === 'There was an error while generating a response' ? "" : agent_conversation_bg_color,
                          border: message.content === 'There was an error while generating a response' ? '1px solid #F2B8B5' : '',
                          // width : '100%',
                          marginLeft: isUnder991px ? "0px" : '0px',
                          maxWidth: message.content === 'There was an error while generating a response' ? '388px' : '604px'
                        }} className={message.content.length > 55 ? "text-start" : 'text-start'}>
                          {message.content &&
                            typeof message.content === 'string' && renderFirstTimeResponse(message.content)}
                        </Col>
                      }
                  </Col>  

                              
                      </Row > 
                      {/* <Row> 
                        {message.file && (
                          <Row style={{backgroundColor : '', marginLeft : '-1px'}}>                
                            <MessageActions
                            message={message}
                              handleDownload={handleDownload}
                              handlePreview={handlePreview}
                            />
                          </Row>
                        )}
                      </Row> */}         
                {message.content === 'There was an error while generating a response' ? (
                  // <div style={{display:'flex',justifyContent:'right',fontSize: '14px'}}>
                  //   <Button
                  //   onClick={handleRetry}
                  //   style={{ marginTop: "10px",maxWidth:'300px',backgroundColor:'transparent'}}
                  // >
                  //   Regenerate Response <i class="bi bi-arrow-repeat"></i>
                  // </Button>
                  // </div>
                  isUnder991px ? 
                 <Box 
                 onClick={handleRetry}
                 sx={{
                  width : '48px',
                  height : '48px',
                  bgcolor : '#1F233E',
                  marginTop : '20px',
                  borderRadius : '50%',
                  display : "flex",
                  justifyContent : 'center',
                  alignItems : 'center'
                 }}><ReplayIcon/>

                 </Box> 
                 :<Box 
                 onClick={handleRetry}
                 sx={{
                  width : '48px',
                  height : '48px',
                  bgcolor : '#1F233E',
                  marginTop : '-60px',
                  marginLeft : '500px',
                  borderRadius : '50%',
                  display : "flex",
                  justifyContent : 'center',
                  alignItems : 'center'
                 }}><ReplayIcon/>

                 </Box>
                  
                ):''}
              </Col>
            </Row>
          )
          }
        </Row>
        :
         //Render Request-Response Box from history (After Reload Render Happen here)  
        <Row  style={{backgroundColor : '', padding:"0px 0px 12px 0px", margin:"0px"}}>
          <Col xs={12} className="text-end" style={{paddingRight: isUnder991px ? '0px' : "0px", paddingLeft: isUnder991px ?  '0px' : "30px", backgroundColor: '' }}>
            <Row style={{ backgroundColor: '', margin :'40px 0px', justifyContent: 'flex-end', alignItems: 'start' }}>


            {/* Render Request Box from history small And Big Screen (After Reload Render Happen here)   */}
              {
                isUnder991px ?
                 <Row  style={{justifyContent:"flex-end",backgroundColor:"", padding:"0px", margin:"0px" ,flexDirection:"column" , flexWrap:"wrap-reverse", gap:isUnder991px ? "16px" : "0px"}}>
                  {/* User Icon */}
              <Col xs="auto" className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{
                backgroundColor: '#1F233E',
                borderRadius: '50%',
                width: '48px',
                height: '48px',
              }}>
                  <div style={{width : '48px' , height : '48px', backgroundColor:""}}>
                    <img src={userIcon} alt="" style={{width : '48px' , height : '48px',}}/>
                  </div>
                {/* <i className="bi bi-person fs-4" style={{ margin: 'auto' }} /> */}
              </Col>

              <Row style={{justifyContent:"flex-end", display:"flex", flexDirection:"row", flexWrap:"nowrap", backgroundColor:"" }}>
               {/* Edit Button */}
               {index === currentThread.length - 1 && !editClick && (
                <Col xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
                   {/* <Button onClick={() => setEditClick(true)} style={{ height: 'fit-content' }}>Edit</Button> */}
                  <Button
                    onClick={() => handleEditClick()}
                    style={{
                      width: '36px',
                      height: '36px',
                      display: 'flex',
                      justifyContent:"center",
                      backgroundColor: hoveredOverEditIcon ? "#282D51" : "#1F233A", // Change background on hover
                      borderRadius: '50%',
                      // transition: 'background-color 0.3s, opacity 0.3s',
                      // opacity: hoveredOverEditIcon ? 0.06 : 1, // Change opacity on hover
                      border:'none'
                    }}
                    onMouseOver={() => setHoveredOverEditIcon(true)}
                    onMouseOut={() => setHoveredOverEditIcon(false)}
                  >
                    <EditOutlinedIcon />
                  </Button>
                </Col>
              )}

                {/* Message Content */}
              <Col xs="auto" style={{
                backgroundColor: user_conversation_bg_color,
                display: 'flex',
                flexDirection: 'column',
                // width: index === currentThread.length - 1 ?  (editClick ? "350px" : '302px') : '350px',
                maxWidth:index === currentThread.length - 1 ?  '302px' : (editClick ? '350px' : '302px'),
                borderRadius: '8px',
                // paddingLeft : '20px',
                // paddingRight : '20px',
                marginRight: isUnder991px? "0px" :  '20px',
                justifyContent: 'center',
                // backgroundColor : 'red'
              }}>
                {index === currentThread.length - 1 && editClick && <EditLastPrompt msg={message} />}
                {(index !== currentThread.length - 1 || !editClick) && message.user && typeof message.user === 'string' && message.user?.split("\n").map((line, index) => (
                  <div key={index} style={{
                    wordBreak: 'break-word',
                    textAlign: 'start',
                    // marginRight:'13px',
                    padding: '12px',
                    backgroundColor: ''
                  }}>
                    {/* {decodeContent(line)} */}
                    <MDEditor.Markdown source={decodeContent(line)} style={{ backgroundColor: user_conversation_bg_color, color:"white",  fontSize: isUnder991px ? "14px" : "16px", fontFamily:"Inter"}}  className="custom-md-editor" previewOptions={{rehypePlugins: [[rehypeSanitize]]}} />
                  </div>
                ))}
              </Col> 
              
              </Row>
                 </Row> 
                 :
                 <Row style={{justifyContent:"flex-end", flexWrap:"" ,  padding:"0px", margin:"0px"}}>
                   {/* Edit Button */}
              {index === currentThread.length - 1 && !editClick && (
                <Col xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <Button onClick={() => setEditClick(true)} style={{ height: 'fit-content' }}>Edit</Button> */}
                  <Button
                    onClick={() => handleEditClick()}
                    style={{
                      width: '48px',
                      height: '48px',
                      backgroundColor: hoveredOverEditIcon ? "#282D51" : "#1F233A", // Change background on hover
                      borderRadius: '50%',
                      // transition: 'background-color 0.3s, opacity 0.3s',
                      // opacity: hoveredOverEditIcon ? 0.06 : 1, // Change opacity on hover
                      border:'none'
                    }}
                    onMouseOver={() => setHoveredOverEditIcon(true)}
                    onMouseOut={() => setHoveredOverEditIcon(false)}
                  >
                    <EditOutlinedIcon />
                  </Button>
                </Col>
              )}
                  {/* Message Content */}
              <Col xs="auto" style={{
                backgroundColor: user_conversation_bg_color,
                display: 'flex',
                flexDirection: 'column',
                // width: editClick ?  '46vw' : '42vw',
                width:editClick ?   '661px' : '661px',
                // height:'auto',
                borderRadius: '8px',
                // paddingLeft : '20px',
                // paddingRight : '20px',
                marginRight: '20px',
                justifyContent: 'center',
                // backgroundColor : 'red'
              }}>
                {index === currentThread.length - 1 && editClick && <EditLastPrompt msg={message} />}
                {(index !== currentThread.length - 1 || !editClick) && message.user && typeof message.user === 'string' && message.user?.split("\n").map((line, index) => (
                  <div key={index} style={{
                    wordBreak: 'break-word',
                    textAlign: 'start',
                    // marginRight: '-13px',
                    padding: '12px',
                    backgroundColor: ''
                  }}>
                    {/* {decodeContent(line)} */}
                    <MDEditor.Markdown source={decodeContent(line)} style={{ backgroundColor: user_conversation_bg_color, color:"white",  fontSize: isUnder991px ? "14px" : "16px", fontFamily:"Inter"}}  className="custom-md-editor" previewOptions={{rehypePlugins: [[rehypeSanitize]]}} />
                  </div>
                ))}
              </Col>
              {/* User Icon */}
              <Col xs="auto" className="d-flex flex-column justify-content-start align-items-start p-0 m-1" style={{
                backgroundColor: '#1F233E',
                borderRadius: '50%',
                width: '48px',
                height: '48px',
                display : "flex",
                alignItems: 'start',
              }}>
                  <div style={{width : '48px' , height : '48px', backgroundColor:""}}>
                    <img src={userIcon} alt="" style={{width : '48px' , height : '48px', backgroundColor:""}}/>

                  </div>
                {/* <i className="bi bi-person fs-4" style={{ margin: 'auto' }} /> */}
              </Col>
                 </Row>
              }
              

            </Row>
          </Col>

           {/* Render Response Box from history small And Big Screen (After Reload Render Happen here)   */}    
          <Row className = "justify-content-start" style={{backgroundColor : '', margin:"0px" , padding:"0px"}}>

            <Col xs={11} className= "text-end" style={{width:"100%", marginBottom: '0px',backgroundColor : '',padding:"0px" }}>
              <Row style={{ marginLeft :isUnder991px ? "0px": '0px',backgroundColor : '', display:"flex", flexDirection:isUnder991px ? "column" : "row", rowGap: isUnder991px ? "16px" : "0px", border:""}}>

                  <Col style={{width: '48px', height: '48px', backgroundColor : '', marginRight : '', marginLeft : isUnder991px ? "0px": '15px', borderRadius : '24px', display : "flex" , justifyContent : "center", alignItems :"center"}} xs={1} >
                    <img style={{ width: '48px', height: '48px', marginTop : '0px', }} src={theme === 'dark' ? updatedModalxLogo : white_logo} alt="modalx_logo" />
                  </Col>
                  <Col  style={{ marginLeft: isUnder991px ? "0px" : "0px", padding: isUnder991px ? "0px" : "0px 0px 0px 12px",backgroundColor : '', border:"" }}>
                  {/* <Col xs={11} style={{ margin: 'auto',marginBottom:"30px",
                    backgroundColor : agent_conversation_bg_color, marginLeft : '10px', width : '100%',borderRadius:'8px'}}>
                    <Row > */}
                    {extractURL(message.agent)}
                    <div style={{  
                      maxWidth : '800px',
                      width:"100%",
                      display: imagePreview ? 'flex' : '', 
                      flexWrap: imagePreview ? 'wrap' :'', 
                      // justifyContent: imagePreview ?'space-between' : '', 
                      // flexBasis: imagePreview ? 'calc(50% - 10px)' : "", /* Each item takes 50% of the row minus some gap */ 
                      gap: 3, /* Optional: adds space between items */
                      margin:"0px 0px",
                      padding:"0px 0px",
                      }} 

                    >
                    {
                      findUrls(message?.agent)  && findUrls(message?.agent).map((element, index)=>{
                         
                        // <Col maxWidth = '800px'  width="100%"  className="" style={{  display: imagePreview ? 'flex' : '',  flexWrap: imagePreview ? 'wrap' :'', backgroundColor : '' , margin: isUnder991px ? "0px" :  'auto', marginBottom:"30px", marginLeft : isUnder991px ? "0px" : '10px',borderRadius:'8px',border:"1px solid yellow",}}>
                        return <MessageActions 
                          message={{
                            file: { url: findUrl(element), mode: 'from_history', status: fileStatus }
                          }}
                          handleDownload={handleDownload}
                          handlePreview={handlePreview}
                        />
                      // </Col>
                      })
                    }

                    </div>

                  {/* </Row>
                </Col> */}
                 {!isUnder991px && message?.status === 'completed' && <Box sx={{marginLeft:"0px",}}> <SettingsButtonBelowImagePreview message={message}/></Box>}

                { message?.status ? message?.status === 'completed' &&  !containsURL(message?.agent) &&
                <Col xs={11} style={{padding: '10px', border:"", backgroundColor : agent_conversation_bg_color, marginLeft : isUnder991px ? "0px" : '0px',
                    // width : '100%',
                    maxWidth:'604px',borderRadius:'8px'}} className={message?.agent?.length > 55 ? "text-start" : 'text-start'}>
                {/* paddingTop:'10px',
                    backgroundColor : 'pink',width : '620px', marginLeft : '10px' */}
                    {/* <Row>
                    {
                      findUrls(message.agent) && findUrls(message.agent).map((element, index)=>{
                        return <Row style={{backgroundColor : '',width : '', marginLeft : '0'}}>
                        <MessageActions
                          message={{
                            file: { url: findUrl(element), mode: 'from_history', status: fileStatus }
                          }}
                          handleDownload={handleDownload}
                          handlePreview={handlePreview}
                        />
                      </Row>
                      })
                    }
                  </Row> */}
                  <Row style={{border:""}}>
                    {message?.agent &&
                      typeof message?.agent === 'string' && message?.agent  && renderResponse(message?.agent)}
                  </Row> 
                </Col> :
                <Col xs={11} style={{padding: '10px', border:"",
                  backgroundColor : agent_conversation_bg_color, marginLeft : isUnder991px ? "0px" : '0px',
                  // width : '100%',
                  maxWidth:'604px',borderRadius:'8px'}} className={message.agent?.length > 55 ? "text-start" : 'text-start'}>
                 <Row style={{border:""}}>
                   {message?.agent &&
                     typeof message?.agent === 'string' && renderResponse(message?.agent)}
                 </Row> 
                </Col>
                }
                </Col>


                {/* <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
                  <img style={{ width: '48px', height: '48px' }} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
                </Col> */}
              </Row>
              {/* {message.file && (
                <Row >
                  <MessageActions
                    message={message}
                    handleDownload={handleDownload}
                    handlePreview={handlePreview}
                  />
                </Row>
              )} */}
            </Col>
          </Row>
        </Row>
      }
    </Container>
  );
};

export default MessageCard;



//===================(Lots Of modification Code)===============
// import React, { useEffect, useState } from "react";
// import { Col, Row, Container, Image, Button } from "react-bootstrap";
// import MessageActions from "./MessageActions";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import TypingIndicator from "./TypingIndicator";
// import black_logo from "../../../assets/ModalX_-orange_Icon.png";
// import white_logo from "../../../assets/modalX_robot_icon_white.png";
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
// import he from 'he';
// import sourceUrl from "../../../api/Source.api";
// import {decode, encode} from 'html-entities';
// import markdownit from 'markdown-it';
// import './custom-markdown-styles.css'
// import hljs from 'highlight.js';
// import MDEditor from '@uiw/react-md-editor';
// import rehypeSanitize from "rehype-sanitize";
// import 'highlight.js/styles/github.css'; // Use a theme similar to ChatGPT's code style


// const MessageCard = ({ message, handleDownload, handlePreview }) => {
//   const token = localStorage.getItem('token');
//   console.log("current_messages_from_history", message);
//   const { waitThread,setCurrentThread,setWaitThread,sendMessage,setSending } = useWorkspace();
//   const [theme, setTheme] = useState(localStorage.getItem('theme'));
//   const [fileStatus, setFileStatus] = useState(null);
//   const [input, setInput] = useState("");
//   const [res, setRes] = useState("");

//   const findUrl = (msg) => {
//     const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
//     const match = urlRegex.exec(msg);
//     return match ? match[1] : null;
//   };

//   const removeUrlsAndBrackets = (msg) => {
//     return msg.replace(/\(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt)\)/g, '');
//   };

//   useEffect(() => {
//     // Update the theme when it changes
//     const handleThemeChange = () => setTheme(localStorage.getItem('theme'));
//     window.addEventListener('storage', handleThemeChange);

//     return () => {
//       window.removeEventListener('storage', handleThemeChange);
//     };
//   }, []);

//   const handleCopyToClipboard = (text) => {
//     navigator.clipboard.writeText(text)
//       .then(() => {
//         console.log('Link copied to clipboard:', text);
//       })
//       .catch((error) => {
//         console.error('Error copying link to clipboard:', error);
//       });
//   };

//   useEffect(() => {
//     const url = findUrl(message.agent);
//     if (url) {
//       checkStatusFromHistoryUrl(url).then((status) => {
//         setFileStatus(status);
//       });
//     }
//   }, [message.agent]);

//   const checkStatusFromHistoryUrl = async (url) => {
//     try {
//       const response = await fetch(`${sourceUrl}/status`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${token}`
//         },
//         body: JSON.stringify({ url }),
//       });

//       const responseData = await response.json();
//       if (responseData.data.status === "completed") {
//         return "completed";
//       } else if (responseData.data.status === "failed") {
//         return "failed";
//       } else {
//         return null;
//       }
//     } catch (error) {
//       console.error("Check URL status request error:", error);
//       return null;
//     }
//   };

//   const renderers = {
//     a: ({ href, children }) => (
//       <div style={{ display: 'inline-flex', alignItems: 'center', wordBreak: 'break-word' }}>
//         <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
//         <i
//           className="bi bi-copy"
//           style={{ cursor: 'pointer', marginLeft: '20px' }}
//           onClick={() => handleCopyToClipboard(href)}
//           title="Copy link"
//         />
//       </div>
//     ),
//   };

//   const decodeUnicode = (str) => {
//     const stringNewLine = str.split("\\n").join("\n")
//     const stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
//       const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
//       return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
//     });
//     return stringNewLineWithAnchorTag

//   };
  
//   const md = markdownit({
//     html: true,
//     linkify: true,
//     typographer: true,
//     quotes: '“”‘’',
//     highlight: function (str, lang) {
//       if (lang && hljs.getLanguage(lang)) {
//         try {
//           return '<pre class="hljs"><code>' +
//                  hljs.highlight(lang, str, true).value +
//                  '</code></pre>';
//         } catch (__) {}
//       }
  
//       return '<pre class="hljs"><code>' + md.utils.escapeHtml(str) + '</code></pre>';
//     }
//   })


//   const handleSubmit = async (messageContent) => {
//     setSending(true)
//     setWaitThread(true);
//     if (messageContent.trim()) {
//       const latestEntry = { sender: "user", content: messageContent };
//       setInput(""); // Reset input field
//       setCurrentThread((prevThread) => [...prevThread, latestEntry]);
//       const entryResponse = await sendMessage(latestEntry).catch(console.error);
      
//       if (entryResponse) {
//         setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//         setSending(false)
//       }
//       else{
//         setCurrentThread((prevThread) => [...prevThread, {content:"Unable to process your query!..",sender:'modalX'}]);
//         setSending(false);
//       }
//     }
//     setWaitThread(false);
//     // here add the functionality to check url status and update it in the currentThread
//   };

//   const handleRetry = () => {
//     setCurrentThread((prevThread) => {
//       const lastUserMessage = prevThread.filter(msg => msg.sender === "user").slice(-1)[0];
//       const newThread = prevThread.slice(0, -1);
//       handleSubmit(lastUserMessage.content); // Retry the submission with the last user message
//       return newThread;
//     });
//   };

//   const getFileFormat = (url) => {
//     // Extract the file extension from the URL
//     const parts = url.split('.');
//     if (parts.length > 1) {
//       return parts[parts.length - 1]; // Return the last part as the file format
//     } else {
//       return ''; // Return empty string if no file extension found
//     }
//   };

//   function renderResponse(response){  
//     response = findUrl(response) ? removeUrlsAndBrackets(response) : response;                   
//     if (findUrl(response) && findUrl(response).includes('dailylimitexceeded')) {
//       // updatedParagraph = 'Your daily '+ getFileFormat(findUrl(message.agent))+' generation limit was exceeded. Consider upgrading to our premium plan for unlimited access to features.' ;
//       let updatedParagraph = `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ getFileFormat(findUrl(response))+` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`
     
//        return <div

//           style={{ wordBreak: "break-word" }}>

//           {/* <ReactMarkdown children={decodeContent(updatedParagraph)} remarkPlugins={[remarkGfm]} components={renderers} /> */}
//           <MDEditor.Markdown source={decodeUnicode(updatedParagraph)} style={{ }} previewOptions={{rehypePlugins: [[rehypeSanitize]],}}/> 
//         </div>
      
//     }else{
//     // let cleanedParagraph = findUrl(message.agent) ? removeUrlsAndBrackets(paragraph) : paragraph;
//     // cleanedParagraph = cleanedParagraph.replace('\\n', '\n');
//       return <div
//         style={{ wordBreak: "break-word",  }}
//       >
//         {/* <ReactMarkdown children={decodeContent(cleanedParagraph)} remarkPlugins={[remarkGfm]} components={renderers} /> */}
//         <MDEditor.Markdown source={decodeUnicode(response)} style={{ backgroundColor:"#171A2D", color:"white"}} previewOptions={{rehypePlugins: [[rehypeSanitize]]}} />
//       </div>
//     } 
// }

//   return (
//     <Container fluid > 
//       {message.sender ?
//         <Row className="pb-3" >
//           {message.sender === "user" ? (
//             <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px',paddingLeft:'40px'}}>
//               <Row>
//                 <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
//                   <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
//                 </Col>
//                 <Col xs={11} style={{ margin: 'auto' }}>
//                   {message.content && typeof message.content === 'string' && message.content.split("\n").map((line, index) => (
//                     <div key={index} style={{ wordBreak: "break-word" }}>
//                       {decodeUnicode(line)}
//                     </div>
//                   ))}
//                 </Col>
//               </Row>
//             </Col>
//           ) : (
//             <Row className="justify-content-end" >
//               <Col xs={11} className="text-end" style={{ marginBottom: '40px'}}>
//                 <Row>
//                   <Col xs={11} style={{ margin: 'auto', paddingRight: message.content.length < 44 ? '20px' : '',paddingTop:'10px'}} className={message.content.length > 55 ? "text-start" : 'text-end'}>
//                     {/* {message.content &&
//                       typeof message.content === 'string' &&
//                       message.content.split("\\n").map((paragraph, index) => {
//                         return (
//                           <div
//                             key={index}
//                             style={{ wordBreak: "break-word" }}
//                           >
//                             <ReactMarkdown children={decodeContent(paragraph)} remarkPlugins={[remarkGfm]} components={renderers} />
//                           </div>
//                         );
//                       })} */}
//                       {message.content &&
//                       typeof message.content === 'string' ? <div
//                       style={{ wordBreak: "break-word", padding:"20px 0px", }}
//                     >
//                       {/* <ReactMarkdown children={decodeContent(cleanedParagraph)} remarkPlugins={[remarkGfm]} components={renderers} /> */}
//                       {/* <div>{renderers}</div> */}
//                       {/* <Markdown options={{overrides: renderers, }}>{decodeContent(cleanedParagraph)}</Markdown> */}
//                       {/* <div dangerouslySetInnerHTML={{ __html: md.render(decodeContent(newString)) }} /> */}
//                       <MDEditor.Markdown source={decodeUnicode(findUrl(message.content) ? removeUrlsAndBrackets(message.content) : message.content)} style={{backgroundColor:"#171A2D",color:"white"}} previewOptions={{rehypePlugins: [[rehypeSanitize]],}}/>
//                     </div>
//                      : ""
//                       // message.agent.split("\n").map((paragraph, index) => {
//                       //   let cleanedParagraph = findUrl(message.agent) ? removeUrlsAndBrackets(paragraph) : paragraph;
//                       //   // cleanedParagraph = cleanedParagraph.replace('\\n', '\n');
//                       //   return (
//                       //     <div
//                       //       key={index}
//                       //       style={{ wordBreak: "break-word", padding:"20px 0px", }}
//                       //     >
//                       //       {/* <ReactMarkdown children={decodeContent(cleanedParagraph)} remarkPlugins={[remarkGfm]} components={renderers} /> */}
//                       //       {/* <div>{renderers}</div> */}
//                       //       {/* <Markdown options={{overrides: renderers, }}>{decodeContent(cleanedParagraph)}</Markdown> */}
//                       //       {/* <div dangerouslySetInnerHTML={{ __html: md.render(decodeContent(newString)) }} /> */}
//                       //       <MDEditor.Markdown source={decodeUnicode(cleanedParagraph)} style={{ }} previewOptions={{rehypePlugins: [[rehypeSanitize]],}}/>
//                       //     </div>
//                       //   );
//                       // }
//                       // )
//                       }
//                   </Col>
//                   <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//                     <img style={{ width: '48px', height: '48px'}} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//                   </Col>
//                 </Row>
//                 {message.file && (
//                   <Row>
//                     <MessageActions
//                       message={message}
//                       handleDownload={handleDownload}
//                       handlePreview={handlePreview}
//                     />
//                   </Row>
//                 )}
//                 {message.content === 'Unable to process your query!..' ? (
//                   <div style={{display:'flex',justifyContent:'right'}}>
//                     <Button
//                     onClick={handleRetry}
//                     style={{ marginTop: "10px",maxWidth:'300px',border:'2px solid red',backgroundColor:'transparent'}}
//                   >
//                     Regenerate Response <i class="bi bi-arrow-repeat"></i>
//                   </Button>
//                   </div>
                  
//                 ):''}
//               </Col>
//             </Row>
//           )}
//         </Row>
//         :
//         <Row className="pb-3">
//           <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px',paddingLeft:'40px'}}>
//             <Row>
//               <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
//                 <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
//               </Col>
//               <Col xs={11} style={{ margin: 'auto' }}>
//                 {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
//                   <div key={index} style={{ wordBreak: "break-word" }}>
//                     {decodeUnicode(line)}
//                   </div>
//                 ))}
//               </Col>
//             </Row>
//           </Col>
//           <Row className="justify-content-end">
//             <Col xs={11} className="text-end">
//               <Row>
//                 <Col xs={11} style={{ margin: 'auto', paddingLeft: '40px', marginBottom: '40px',paddingTop:'10px'}} className={message.agent.length > 55 ? "text-start" : 'text-end'}>
//                   <Row>
//                     {message.agent &&
//                       typeof message.agent === 'string' &&  renderResponse(message.agent)}
                    
//                   </Row>
//                   <Row>
//                     {
//                       findUrl(message.agent) &&
//                       <Row>
//                         <MessageActions
//                           message={{
//                             file: { url: findUrl(message.agent), mode: 'from_history', status: fileStatus }
//                           }}
//                           handleDownload={handleDownload}
//                           handlePreview={handlePreview}
//                         />
//                       </Row>
//                     }
//                   </Row>
//                 </Col>
//                 <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//                   <img style={{ width: '48px', height: '48px' }} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//                 </Col>
//               </Row>
//               {message.file && (
//                 <Row>
//                   <MessageActions
//                     message={message}
//                     handleDownload={handleDownload}
//                     handlePreview={handlePreview}
//                   />
//                 </Row>
//               )}
//             </Col>
//           </Row>
//         </Row>
//       }
//     </Container>
//   );
// };

// export default MessageCard;

//==========================================Shabna Code End======================================================


// ==================================updated code on 21-05-2024  (below)======================================
// import React, { useEffect, useState } from "react";
// import { Col, Row, Container, Image } from "react-bootstrap";
// import MessageActions from "./MessageActions";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import TypingIndicator from "./TypingIndicator";
// import black_logo from "../../../assets/modalX_robot_icon_black.png";
// import white_logo from "../../../assets/modalX_robot_icon_white.png";
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
// import he from 'he';

// const MessageCard = ({ message, handleDownload, handlePreview }) => {
//   const token = localStorage.getItem('token');
//   console.log("current_messages_from_history", message);
//   const { waitThread } = useWorkspace();
//   const [theme, setTheme] = useState(localStorage.getItem('theme'));
//   const [fileStatus, setFileStatus] = useState(null);
//   const findUrl = (msg) => {
//     const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
//     const match = urlRegex.exec(msg);
//     return match ? match[1] : null;
//   };
//   const removeUrlsAndBrackets = (msg) => {
//     return msg.replace(/\(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt)\)/g, '');
//   };

//   useEffect(() => {
//     // Update the theme when it changes
//     const handleThemeChange = () => setTheme(localStorage.getItem('theme'));
//     window.addEventListener('storage', handleThemeChange);

//     return () => {
//       window.removeEventListener('storage', handleThemeChange);
//     };
//   }, []);

//   const handleCopyToClipboard = (text) => {
//     navigator.clipboard.writeText(text)
//       .then(() => {
//         console.log('Link copied to clipboard:', text);
//       })
//       .catch((error) => {
//         console.error('Error copying link to clipboard:', error);
//       });
//   };

//   useEffect(() => {
//     const url = findUrl(message.agent);
//     if (url) {
//       checkStatusFromHistoryUrl(url).then((status) => {
//         setFileStatus(status);
//       });
//     }
//   }, [message.agent]);

//   const checkStatusFromHistoryUrl = async (url) => {
//     try {
//       const response = await fetch("https://www.modalx.ai/status", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${token}`
//         },
//         body: JSON.stringify({ url }),
//       });

//       const responseData = await response.json();
//       // console.log("zzzzzzzzzzzzzzzzzzzz", responseData);
//       if (responseData.data.status === "completed") {
//         return "completed";
//       } else if (responseData.data.status === "failed") {
//         return "failed";
//       } else {
//         return null;
//       }

//     } catch (error) {
//       console.error("Check URL status request error:", error);
//       return null;
//     }
//   }

//   const renderers = {
//     a: ({ href, children }) => (
//       <div style={{ display: 'inline-flex', alignItems: 'center', wordBreak: 'break-word' }}>
//         <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
//         <i
//           className="bi bi-copy"
//           style={{ cursor: 'pointer', marginLeft: '20px' }}
//           onClick={() => handleCopyToClipboard(href)}
//           title="Copy link"
//         />
//       </div>
//     ),
//   };

//   return (
//     <Container fluid>
//       {message.sender ?
//         <Row className="pb-3">
//           {message.sender === "user" ? (
//             <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px' }}>
//               <Row>
//                 <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//                   <i className="bi bi-person fs-4" />
//                 </Col>
//                 <Col xs={11} style={{ margin: 'auto' }}>
//                   {message.content && typeof message.content === 'string' && message.content.split("\n").map((line, index) => (
//                     <div key={index} style={{ wordBreak: "break-word" }}>
//                       {he.decode(line)}
//                     </div>
//                   ))}
//                 </Col>
//               </Row>
//             </Col>
//           ) : (
//             <Row className="justify-content-end">
//               <Col xs={11} className="text-end" style={{ marginBottom: '40px' }}>
//                 <Row>
//                   <Col xs={11} style={{ margin: 'auto', paddingRight: message.content.length < 42 ? '20px' : '' }} className={message.content.length > 42 ? "text-start" : 'text-end'}>
//                     {message.content &&
//                       typeof message.content === 'string' &&
//                       message.content.split("\\n").map((paragraph, index) => {
//                         return (
//                           <div
//                             key={index}
//                             style={{ wordBreak: "break-word" }}
//                           >
//                             <ReactMarkdown children={he.decode(paragraph)} remarkPlugins={[remarkGfm]} components={renderers} />
//                           </div>
//                         );
//                       })}
//                   </Col>
//                   <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//                     <img style={{ width: '25px', height: '25px' }} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//                   </Col>
//                 </Row>
//                 {message.file && (
//                   <Row>
//                     <MessageActions
//                       message={message}
//                       handleDownload={handleDownload}
//                       handlePreview={handlePreview}
//                     />
//                   </Row>
//                 )}
//               </Col>
//             </Row>
//           )}
//         </Row>
//         :
//         <Row className="pb-3">
//           <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px' }}>
//             <Row>
//               <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//                 <i className="bi bi-person fs-4" />
//               </Col>
//               <Col xs={11} style={{ margin: 'auto' }}>
//                 {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
//                   <div key={index} style={{ wordBreak: "break-word" }}>
//                     {he.decode(line)}
//                   </div>
//                 ))}
//               </Col>
//             </Row>
//           </Col>
//           <Row className="justify-content-end">
//             <Col xs={11} className="text-end">
//               <Row>
//                 <Col xs={11} style={{ margin: 'auto', paddingLeft: '40px', marginBottom: '40px' }} className={message.agent.length > 42 ? "text-start" : 'text-end'}>
//                   <Row>
//                     {/* {message.agent &&
//                       typeof message.agent === 'string' &&
//                       message.agent.split("\\n").map((paragraph, index) => {
//                         return (
//                           <div
//                             key={index}
//                             style={{ wordBreak: "break-word" }}
//                           >
//                             <ReactMarkdown children={he.decode(paragraph)} remarkPlugins={[remarkGfm]} components={renderers} />
//                           </div>
//                         );
//                       })} */}
//                       {message.agent &&
//                       typeof message.agent === 'string' &&
//                       message.agent.split("\\n").map((paragraph, index) => {
//                         const cleanedParagraph = findUrl(message.agent) ? removeUrlsAndBrackets(paragraph) : paragraph;
//                         return (
//                           <div
//                             key={index}
//                             style={{ wordBreak: "break-word" }}
//                           >
//                             <ReactMarkdown children={he.decode(cleanedParagraph)} remarkPlugins={[remarkGfm]} components={renderers} />
//                           </div>
//                         );
//                       })}
//                   </Row>
//                   <Row>
//                     {
//                       findUrl(message.agent) &&
//                       <Row>
//                         <MessageActions
//                           message={{
//                             file: { url: findUrl(message.agent), mode: 'from_history',status:fileStatus }
//                           }}
//                           handleDownload={handleDownload}
//                           handlePreview={handlePreview}
//                         />
//                       </Row>
//                     }
//                   </Row>
//                 </Col>
//                 <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//                   <img style={{ width: '25px', height: '25px' }} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
//                 </Col>
//               </Row>
//               {message.file && (
//                 <Row>
//                   <MessageActions
//                     message={message}
//                     handleDownload={handleDownload}
//                     handlePreview={handlePreview}
//                   />
//                 </Row>
//               )}
//             </Col>
//           </Row>
//         </Row>
//       }
//     </Container>
//   );
// };

// export default MessageCard;
// ==================================updated code on 21-05-2024  (above)======================================



// import React from "react";
// import { Col, Row, Container } from "react-bootstrap";
// import MessageActions from "./MessageActions";
// // import Image from "react-bootstrap/Image";
// // import GetLogo from "../../GetLogo";

// const MessageCard = ({ message, handleDownload, handlePreview }) => {
//   // const logoSrc = GetLogo();
//   return (
//     <Container fluid>
//       <Row className="pb-3">
//         {/* <Col xs={{ span: 1, offset: 0 }} className="pe-0 me-0 py-0 my-0">
//           <div
//             style={{
//               height: "24px",
//               minHeight: "24px",
//               maxHeight: "24px",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             {message.sender === "user" ? (
//               <i
//                 className="bi bi-person fs-4"
//                 style={{ margin: "0", padding: "0" }}
//               />
//             ) : (
//               <Image
//                 src={logoSrc}
//                 loading="lazy"
//                 alt="Company Logo"
//                 width="24px"
//                 minWidth="24px"
//                 style={{
//                   objectFit: "cover",
//                 }}
//               />
//             )}
//           </div>
//         </Col> */}

//         <Col
//           xs={1}
//           className="d-flex flex-column justify-content-start align-items-center p-0 m-0"
//         >
//           <i
//             className={`bi ${
//               message.sender === "user" ? "bi-person" : "bi-robot"
//             } fs-4`}
//           />
//         </Col>
//         <Col xs={11}>
//           <Row>
//             {message.content.split("\n").map((line, index) => (
//               <div key={index} style={{ wordBreak: "break-word" }}>
//                 {line}
//               </div>
//             ))}
//           </Row>
//           {message.file && (
//             <Row>
//               <MessageActions
//                 message={message}
//                 handleDownload={handleDownload}
//                 handlePreview={handlePreview}
//               />
//             </Row>
//           )}
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default MessageCard;






{/* <Col xs={12} className="text-end" style={{ marginBottom: '', paddingRight: '30px',paddingLeft:'30px',backgroundColor : ''}}>
            <Row style={{ backgroundColor : '' , marginTop : '40px', marginBottom : '40px'}}>
              {index === currentThread.length - 1 && !editClick && (
                <Col xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
                  <Button onClick={() => setEditClick(true)} style={{ height: 'fit-content' }}>Edit</Button>
                </Col>
              )}
              <Col  style={{ margin: 'auto' , backgroundColor : user_conversation_bg_color ,display : 'flex',flexDirection:'column',marginRight : '20px',maxWidth:'455px',borderRadius:'8px',
                  justifyContent : 'end'}}>
                {index === currentThread.length - 1 && !editClick && <Button onClick={() => setEditClick(true)}>edit</Button>}
                {index === currentThread.length - 1 && editClick && <EditLastPrompt msg={message}/>}
                {(index !== currentThread.length - 1 || !editClick) && message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
                  <div key={index} style={{ wordBreak: "break-word", textAlign : 'start', marginRight : '-13px',padding:'12px'
                    ,backgroundColor : ''}}>
                    {decodeContent(line)}
                  </div>
                ))}
              </Col>
              <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-1" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
                <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
              </Col>
            </Row>
          </Col> */}