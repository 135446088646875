import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, FormHelperText, TextField, Typography, useMediaQuery } from "@mui/material";
import  CustomizedSnackbars  from "./CustomizedSnackbars";
import { New_Project_Button_bg_color } from "../../Provider/Color_Provider";
import axios from "axios";
import sourceUrl from "../../api/Source.api";
import { disposableEmailProviders } from "../../constants/disposableEmailDomains";
import { useAuth } from "../../contexts/AuthContext";
import SignupLeftSide from "./SignupLeftSide";
import mailIcon from "../../assets/signup/mail.png";
import ModalXLogo from '../../assets/signup/loginPageLogo.png'
import { useWorkspace } from "../../contexts/WorkspaceContext";
import { useNavigate } from "react-router-dom";
import CommonToast from "../Common_Components/CommonToast";

const ResetPassword = ({
  showLoginModal,
  setShowLoginModal,
  setShowSignUpModal,
  setMaskedPassword
}) => {
 
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [notifMsg, setNotifMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const [notifVariant, setNotifVariant] = useState("");
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const [currentResetWindow, setCurrentResetWindow] = React.useState(1);
  const {showResetPasswordModal,setShowResetPasswordModal,showResendModal, setShowResendModal, setCurrentWindow} =useAuth()
  const {email,setEmail,} = useWorkspace()
  const navigate = useNavigate();
  const customTextFieldStylesForInvalid = (isInvalid) => {
    return ( {"& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80)",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
      color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",}
    })
    }

  // const handleOpenSignUpModal = () => {
  //   setShowSignUpModal(true);
  //   setShowResetPasswordModal(false);
  // };

  // const handleOpenLoginModal = () => {
  //   setEmail("");
  //   setMaskedPassword("")
  //   setShowLoginModal(true);
  //   setShowResetPasswordModal(false);
  // };

  const handleEmailSubmit = async (e) => {
    if (isDisposableEmail(email)) {
      setNotifMsg(
        "We do not accept temporary or disposable email addresses. Please use a permanent email."
      );
      setNotifVariant("error");
      setOpen(true);
      return;
    }
    if (email === "") {
      setNotifVariant("error");
      setOpen(true);
      setNotifMsg("Please enter your email")
      return;
    } else {
        try{
          const response = await axios.post(
            `${sourceUrl}/reset-password`,
            { email:email },
          );
          if(response.data.status === false) {
            setNotifVariant("error");
            setOpen(true);
            setNotifMsg(response.data.message)
            throw new Error(response.data.message)
          }else if(response.data.status === true){
            setNotifVariant("success");
            setOpen(true);
            setNotifMsg(response.data.message)
            setCurrentResetWindow(2)
          }
        }catch(e){
          console.log(e)
          setNotifVariant("error");
          setOpen(true);
          setNotifMsg(e.response.data.message)
        }
    
    }
  };

  const isDisposableEmail = (email) => {
    const emailDomain = email.split("@")[1];
    return disposableEmailProviders.includes(emailDomain);
  };

  const handleEmailInputChange = (e) =>{
    const newEmail = e.target.value;
    setEmail(newEmail);
        
        if (isDisposableEmail(newEmail)) {
          setIsInvalidEmail(true);
        } else {
          setIsInvalidEmail(false);
        }
  }

  const handleCloseToast = () => {
    setOpen(false);
  };

  return (<>
    
    <div
            style={{
              display: "flex",
              width: "100vw",
              height: isBelow991 ? "" : "100vh",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              zIndex: 10,
            }}
          >
            <Box
              onClick={(event) => event.stopPropagation()}
              sx={{
                display: "flex",
                flexDirection: isBelow991 ? "column-reverse" : "row",
                width:"100%",
                height: "100%",
                // width:isBelow991 ? "100%" : "90%",
                // maxWidth:isBelow991 ? "342px" : "1200px",
                // border: isBelow991 ? null : "1px solid #CAC4D0",
              }}
            >
               <Box
                sx={{
                  // backgroundColor: isBelow991 ? "#171A2D" : "#282D51",
                  backgroundColor: "#171A2D",
                  width: isBelow991 ? "100%": "50%",
                  height: "100%",
                  borderRadius: "0px 8px 8px 0px ",
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  padding: {
                    xs: "0px 0px",
                    sm: "0px 0px",
                    md: "0px 0px",
                    lg: "0px 141px",
                    xl: "0px 141px",
                  },
                }}
              >              
              {
              currentResetWindow === 1 && 
                <Box
                      onClick={(event) => event.stopPropagation()}
                      sx={{
                        alignItems:"center",
                        maxWidth: "340px",
                        padding: isBelow991 ? "" : "",
                        marginTop:isBelow991 ? "40px" : "60px",
                      }}
                    >
                    <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom: isBelow991? "40px" : "140px"}}>
                    <img src={ModalXLogo} alt={"logo"} style={{width:"163px", height:"51px"}}/>
                  </Box>
                      <Box
                        sx={{
                          width: "100%",
                          margin: "0px 0px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "20px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            alignSelf: "stretch",
                            textAlign: "center",
                            margin: "0px 0px",
                          }}
                        >
                          Reset your password
                        </Typography>
                      </Box>
              
                      <Box
                        sx={{
                          marginTop: "24px",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            textAlign: "center",
                          }}
                        >
                          Enter your registered email address, and we’ll send you a link to
                          reset your password
                        </Typography>
                      </Box>
              
                      <Box
                        sx={{
                          marginTop: "48px",
                          width: "100%",
                        }}
                      >
                        <FormControl
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            type="email"
                            onChange={(e)=>{handleEmailInputChange(e)}}
                            value={email}
                            id="outlined-basic"
                            label="Email address*"
                            variant="outlined"
                            sx={()=>customTextFieldStylesForInvalid(isInvalidEmail)}
                            style={{
                              width: "100%",
                              height: "56px",
                              color: "#FFFFFF",
                              backgroundColor: "#232746",
                              borderRadius: "8px",
                              boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                              placeholderfontsize: "16px",
                            }}
                          />
                           
                            {
                              isInvalidEmail ? 
                              <FormHelperText
                              sx={{
                                color: isInvalidEmail ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "16px",
                                alignSelf: "start",
                                
                                margin: {
                                    md:  "4px 0px 14px 0px",
                                    lg:  "4px 16px 14px 0px",
                                    xl:  "4px 16px 14px 0px",
                                  },
                              }}
                            >
                              {isInvalidEmail ? "Email address is invalid" : ""}
                            </FormHelperText>
                             : null
                            }
                        </FormControl>
                      </Box>
              
                      <Box
                        sx={ isInvalidEmail ? {
                          marginTop: "26px",
                          width: "100%",
                        } : { marginTop: "60px",
                               width: "100%",}}
                      >
                        <Button
                          onClick={handleEmailSubmit}
                          variant="contained"
                          sx={{
                            bgcolor: New_Project_Button_bg_color,
                            color: "rgba(23, 24, 44, 1)",
                            padding: "10px 24px 10px 24px",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            textAlign: "center",
                            width: "100%",
                            "&:hover": {
                              bgcolor: "#ffd9aa",
                            },
                          }}
                        >
                          Continue
                        </Button>
                      </Box>
              
                      <Box
                        onClick={()=>{navigate('/login')}}
                        sx={{
                          width: "100%",
                          marginTop: "24px",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            alignSelf: "stretch",
                            textAlign: "center",
                          }}
                        >
                          Back to Login
                        </Typography>
                      </Box>
              
                      {/* <Box
                        sx={{
                          width: "100%",
                          marginTop: "60px",
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px",
                            whiteSpace: "nowrap",
                            margin: "4px 16px 14px 16px",
                            textAlign: "center",
                          }}
                        >
                          Don’t have an account yet?{" "}
                          <span
                            onClick={()=>{setCurrentWindow(2)}}
                            style={{
                              textDecorationLine: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Sign up
                          </span>
                        </Typography>
                      </Box> */}
                </Box>
              }
               {
              currentResetWindow === 2 && 
              <Box sx={{maxWidth:"340px", display:"flex", flexDirection:"column", justifyContent:"center",marginTop:isBelow991 ? "40px" : "60px" }}>
               <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom: isBelow991? "40px":"140px"}}>
                    <img src={ModalXLogo} alt={"logo"} style={{width:"163px", height:"51px"}}/>
                </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  style={{
                    width: "80px",
                    height: "80px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255,0.05)",
                    opacity: 0.8,
                  }}
                >
                  {/* <MailIcon
                    onClick={() => {
                      // handleClose();
                    }}
                    sx={{
                      // color: "#CAC4D0",
                      width: "40px",
                      height: "40px",
                    }}
                  /> */}
                   <img src={mailIcon} alt="mailIcon" style={{width:"40px", height:"40px"}}/>
                </Box>
              </Box>
    
              <Box
                sx={{
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    alignSelf: "stretch",
                    textAlign: "center",
                    margin: "0px 0px",
                  }}
                >
                  Check your Email
                </Typography>
              </Box>
    
              <Box
                sx={{
                  marginTop: "24px",
                  width: "340px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    textAlign: "center",
                  }}
                >
                  We have sent you an email with the link to reset your password. If
                  you haven’t received it momentarily, please click on resend mail.
                </Typography>
              </Box>
    
              <Box
                sx={{
                  marginTop: "48px",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: New_Project_Button_bg_color,
                    color: "rgba(23, 24, 44, 1)",
                    padding: "10px 24px 10px 24px",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "center",
                    width: "100%",
                    "&:hover": {
                      bgcolor: "#ffd9aa",
                    },
                  }}
                  onClick={handleEmailSubmit}
                >
                  Resend Email
                </Button>
              </Box>
              <Box
                        onClick={()=>{setCurrentResetWindow(1)}}
                        sx={{
                          width: "100%",
                          marginTop: "24px",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            alignSelf: "stretch",
                            textAlign: "center",
                          }}
                        >
                          Go Back 
                        </Typography>
                      </Box>
                         <Box
                        sx={{
                          marginTop: "60px",
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px",
                            whiteSpace: "nowrap",
                            // margin: "4px 16px 14px 16px",
                            textAlign: "center",
                          }}
                        >
                          Changed password successfully?{" "}
                          <span
                            onClick={()=>{ setCurrentWindow(1);navigate('/login')}}
                            style={{
                              textDecorationLine: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Log in
                          </span>
                        </Typography>
                      </Box>
            </Box>
              }
              </Box>
              <Box
                sx={{
                  // backgroundColor: isBelow991 ? "#171A2D" : "#1F233E",
                  backgroundColor: isBelow991 ? "" : "#1F233E",
                  width: isBelow991 ? "100%": "50%",
                  borderRadius: "16px 16px 16px 16px ",
                  display:isBelow991 ?  "none" : "flex",
                  justifyContent:"center",
                  alignItems:"center",
                  margin:"20px 20px 20px 0px ",
                }}
              >
              <SignupLeftSide />
              </Box>
             
            </Box>
          </div>
        <CommonToast 
          open={open}
          onClose={handleCloseToast}
          content={notifMsg}
          bgColor="#282D51"
          fontColor="#fff"
          fontSize="14px"
          fontWeight="500"
          autoHideDuration={3000}
          iconType = {notifVariant}
          />
    </>
  );
};

export default ResetPassword;
