// import React, { useState } from "react";
// import { Col, Button, Spinner, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
// import PreviewMedia from "./PreviewMedia"; // Import the PreviewMedia component

// const MessageActions = ({ message, handleDownload }) => {
//   // console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkk",message)
//   const [showPreviewModal, setShowPreviewModal] = useState(false);
//   const [previewUrl, setPreviewUrl] = useState("");

//   const handlePreview = () => {
//     setShowPreviewModal(true);
//     setPreviewUrl(message.file.url);
//   };

//   const handleClosePreviewModal = () => {
//     setShowPreviewModal(false);
//     setPreviewUrl("");
//   };

//   const renderTooltip = (props) => (
//     <Tooltip id="button-tooltip" {...props}>
//       Error occurred while generating!
//     </Tooltip>
//   );

//   return (
//     <>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <OverlayTrigger
//           placement="bottom"
//           overlay={message.file.status === "failed" ? renderTooltip : <></>}
//         >
//           <span>
//             <Button
//               variant="outline-secondary"
//               onClick={() => handleDownload(message.file.url)}
//               disabled={message.file.status === "processing" || message.file.status === "failed"}
//               style={{ fontWeight:'400',borderColor:message.file.status === "failed" ? 'red' : ''}}
//               className="my-2 d-flex align-items-center justify-content-start"
//             >
//               {!message.file.url.endsWith(".mp3") &&
//               !message.file.url.endsWith(".mp4") &&
//               !message.file.url.endsWith(".jpg") ? (
//                 <>
//                   <i className="bi bi-cloud-arrow-down pe-3" />
//                   Download
//                 </>
//               ) : (
//                 <>
//                   <i className="bi bi-arrow-up-right-circle pe-3" />
//                   Open
//                 </>
//               )}
//               {message.file.status === "processing" && (
//                 <Spinner
//                   animation="border"
//                   role="status"
//                   size="sm"
//                   className="ms-2"
//                 />
//               )}
//             </Button>
//           </span>
//         </OverlayTrigger>
//       </Col>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <OverlayTrigger
//           placement="bottom"
//           overlay={message.file.status === "failed" ? renderTooltip : <></>}
//         >
//           <span>
//             <Button
//               variant="outline-secondary"
//               disabled={message.file.status === "processing" || message.file.status === "failed"}
//               className="my-2 d-flex align-items-center justify-content-start"
//               onClick={handlePreview}
//               style={{fontWeight:'400',borderColor:message.file.status === "failed" ? 'red' : '' }}
//             >
//               <i className="bi bi-easel2 pe-3" />
//               Preview
//               {message.file.status === "processing" && (
//                 <Spinner
//                   animation="border"
//                   role="status"
//                   size="sm"
//                   className="ms-2"
//                 />
//               )}
//             </Button>
//           </span>
//         </OverlayTrigger>
//       </Col>
//       {/* Render Preview modal */}
//       <Modal show={showPreviewModal} onHide={handleClosePreviewModal} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Preview</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <PreviewMedia url={previewUrl} />
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default MessageActions;

import React, {useEffect, useRef, useState} from "react";
import { Col, Spinner, Row } from "react-bootstrap";
import PreviewMedia from "./PreviewMedia"; // Import the PreviewMedia component
import { agent_conversation_bg_color } from "../../../constants/colors";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { CommanLoader, CommanLoaderAudio, CommanLoaderImage, CommanLoaderPresentation, CommanLoaderVideo } from "../../Common_Components/Comman_Loader";
import { Height } from "@mui/icons-material";



const MessageActions = ({ message }) => {
  // console.log("Inside message actionsssssssssssssssssssssssssssssssssssssssssssssssssssssssss", message);

//   return (
//     <Col style={{ display: "flex", flexDirection: "column",marginBottom : '24px', marginTop : '16px' }}>
//     {message.file.status === "processing" ? (
//       <Spinner animation="border" role="status" className="my-2">
//         <span className="visually-hidden">Loading...</span>
//       </Spinner>
//     ) : message.file.status === "failed" ? (
//       <span style={{ color: "red" }}>Error occurred while generating!</span>
//       // message.file.status === null  ---> this one i have added  below for reading file from public links
//     ) : (message.file.status === "processed" || message.file.status === "completed" || message.file.status === null) ? (
//       <PreviewMedia url={message.file.url} />
//     ) : null}
//   </Col>
// );



// LinearProgressWithLabel.propTypes = {
//   value: PropTypes.number.isRequired,
// };

  return (  
  <>
    { message.file.length > 0  ? 
      message.file.map((msg)=>{
      //  return <Col style={{ display: "flex", flexDirection: "column", border:"" }}>
      //   {msg.status === "processing" || msg.status === "pending" ? (
          
      //     ((msg?.progress !== undefined || msg?.progress === 0) &&
      //     msg?.url?.includes(".mp4")) ? 
      //   <Box sx={{ width: '100%',border:"1px solid green" }}>
      //     <CommanLoaderVideo value={msg?.progress} />
      //   </Box>
      //     :
      //     msg?.url && typeof msg?.url === "string" && msg?.url?.includes(".mp4") ?  
      //     <Box sx={{ width: '100%', border:"1px solid red" }}>
      //     <CommanLoaderVideo value={0} />
      //     </Box> 
          
      //     :
      //     <Spinner animation="border" role="status" className="my-2" sx={{border:""}}>
      //       <span className="visually-hidden">Loading...</span>
      //     </Spinner>
      //     // <CommanLoader/>
      //   ) : msg.status === "failed" ? (
      //     <span style={{ color: "red" }}>Error occurred while generating!</span>
      //   ) : (msg.status === "processed" || msg.status === "completed") ? (
      //          <Col xs={11} width="auto"  className="text-start" style={{ margin: 'auto', marginBottom:"30px",
      //         backgroundColor : agent_conversation_bg_color, marginLeft : '10px',borderRadius:'8px', border:""}}>
      //       <PreviewMedia url={msg.url} /></Col>
      //   ) : null}
      // </Col>
      return (
        <Col style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", width:"",  border: "" }}>
          {msg.status === "processing" || msg.status === "pending" ? (
            // MP4 file conditions
            msg?.url?.includes(".mp4") ? (
              (msg?.progress !== undefined || msg?.progress === 0) ? (
                <Box sx={{ width: '100%', border: "" }}>
                  <CommanLoaderVideo value={msg?.progress} />
                </Box>
              ) : (
                <Box sx={{ width: '100%', border: "" }}>
                  <CommanLoaderVideo value={0} />
                </Box>
              )
            ) : 
            // MP3 file conditions
            msg?.url?.includes(".mp3") ? (
              (msg?.progress !== undefined || msg?.progress === 0) ? (
                <Box sx={{ width: '100%', border: "" }}>
                  <CommanLoaderAudio value={msg?.progress} />
                </Box>
              ) : (
                <Box sx={{ width: '100%', border: "" }}>
                  <CommanLoaderAudio value={0} />
                </Box>
              )
            ) : 
            // Image file conditions (.jpg or .png)
            (msg?.url?.includes(".jpg") || msg?.url?.includes(".png")) ? (
              (msg?.progress !== undefined || msg?.progress === 0) ? (
                <Col sx={{ width: '100%', Height:"350px" ,border: "", flexShrink:0, marginLeft: '10px', marginBottom: "20px", margin: 'auto',}}>
                  <CommanLoaderImage value={msg?.progress} />
                </Col>
              ) : (
                <Box sx={{ width: '100%',Height:"350px", border: "",flexShrink:0 }}>
                  <CommanLoaderImage value={0} />
                </Box>
              )
            ) : 
            // PPT file conditions
            
            (msg?.url?.includes(".pptx") || msg?.url?.includes(".docx")) ? (
              (msg?.progress !== undefined || msg?.progress === 0) ? (
                <Box sx={{ width: '100%', border: "" }}>
                  <CommanLoaderPresentation value={msg?.progress} />
                </Box>
              ) : (
                <Box sx={{ width: '100%', border: "" }}>
                  <CommanLoaderPresentation value={0} />
                </Box>
              )
            ) 
            : (
              // Default loader
              // <Spinner animation="border" role="status" className="my-2" sx={{ border: "" }}>
              //   <span className="visually-hidden">Loading...</span>
              // </Spinner>
              <CommanLoaderImage value={0}/>
            )
          ) : msg.status === "failed" ? (
            <span style={{ color: "red" }}>Error occurred while generating!</span>
          ) : msg.status === "processed" || msg.status === "completed" ? (
            // Image file conditions (.jpg or .png)
            (msg?.url?.includes(".jpg") || msg?.url?.includes(".png") || msg?.url?.includes(".jpeg")) ? (
              <Box
              xs={11}
              width="auto"
              className="text-start"
              style={{
                // margin: 'auto',
                marginBottom: "20px",
                backgroundColor: agent_conversation_bg_color,
                // marginLeft: '10px',
                borderRadius: '8px',
                border: "",
                width:"350px",
                flexWrap:"nowrap",
                flexShrink:0
              }}
            >
              <PreviewMedia url={msg.url} />
            </Box>
            ) : (
            <Box
              xs={11}
              width="auto"
              className="text-start"
              style={{
                // margin: 'auto',
                marginBottom: "20px",
                backgroundColor: agent_conversation_bg_color,
                // marginLeft: '10px',
                borderRadius: '8px',
                border: "",
              }}
            >
              <PreviewMedia url={msg.url} />
            </Box>
            )
          ) : null}
        </Col>
      );
      
      })
     :
      <Col style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", width:"", border:"" }}>
      {message.file.status === "processing" ? (
        // <Spinner animation="border" role="status" className="my-2">
        //   <span className="visually-hidden">Loading...</span>
        // </Spinner>
        // <Box sx={{ width: '100%', border: "" }}>
        // <CommanLoader value={0} />
        // </Box>
        message?.file?.url?.includes(".mp4") ? (
          <div>
         { 
           ( message?.file?.progress !== undefined || message?.file?.progress === 0) ? (
            <Box sx={{ width: '100%', border: "" }}>
              <CommanLoaderVideo value={message?.file?.progress} />
            </Box>
          ) : (
            <Box sx={{ width: '100%', border: "" }}>
              <CommanLoaderVideo value={0} />
            </Box>)
          }
          </div>
        ) 
        : 
        // MP3 file conditions
        message?.file?.url?.includes(".mp3") ? (
          (message?.file?.progress !== undefined || message?.file?.progress === 0) ? (
            <Box sx={{ width: '100%', border: "" }}>
              <CommanLoaderAudio value={message?.file?.progress} />
            </Box>
          ) : (
            <Box sx={{ width: '100%', border: "" }}>
              <CommanLoaderAudio value={0} />
            </Box>
          )
        ) 
        : 
        // Image file conditions (.jpg or .png)
        (message?.file?.url?.includes(".jpg") || message?.url?.includes(".png")) ? (
          (message?.file?.progress !== undefined || message?.file?.progress === 0) ? (
            <Col sx={{ width: '100%', Height:"350px" ,border: "", flexShrink:0, marginLeft: '10px', marginBottom: "20px", margin: 'auto',}}>
              <CommanLoaderImage value={message?.file?.progress} />
            </Col>
          ) : (
            <Box sx={{ width: '100%',Height:"350px", border: "1px solid green",flexShrink:0, }}>
              <CommanLoaderImage value={0} />
            </Box>
            // <Col sx={{ width: '100%', Height:"350px" ,border: "", flexShrink:0, marginLeft: '10px', marginBottom: "30px", margin: 'auto',}}>
            //   <CommanLoaderImage value={message?.file?.progress} />
            // </Col>
          )
        ) 
        : 
        // PPT file conditions
       ( message?.url?.includes(".pptx") || message?.url?.includes(".docx")) ? (
          (message?.progress !== undefined || message?.file?.progress === 0) ? (
            <Box sx={{ width: '100%', border: "" }}>
              <CommanLoaderPresentation value={message?.file?.progress} />
            </Box>
          ) : (
            <Box sx={{ width: '100%', border: "" }}>
              <CommanLoaderPresentation value={0} />
            </Box>
          )
        ) 
        : (
          <CommanLoaderImage value={0}/>
        )
      ) : message.file.status === "failed" ? (
        <span style={{ color: "red" }}>Error occurred while generating!</span>
        // message.file.status === null  ---> this one i have added  below for reading file from public links
      ) : (message.file.status === "processed" || message.file.status === "completed" || message.file.status === null) ? (
        (message?.file?.url?.includes(".jpg") || message?.file?.url?.includes(".png") || message?.file?.url?.includes(".jpeg")) ? (
        <Box
        xs={11}
        width="auto"
        className="text-start"
        style={{
          // margin: 'auto',
          marginBottom: "20px",
          backgroundColor: agent_conversation_bg_color,
          // marginLeft: '10px',
          borderRadius: '8px',
          border: "",
          width:"350px",
          flexWrap:"nowrap",
          flexShrink:0
        }}
      >
        <PreviewMedia url={message.file.url} />
        </Box>
        ) : (
          <Box
          xs={11}
          width="auto"
          className="text-start"
          style={{
            // margin: 'auto',
            marginBottom: "20px",
            backgroundColor: agent_conversation_bg_color,
            // marginLeft: '10px',
            borderRadius: '8px',
            border: "",
          }}
        >
          <PreviewMedia url={message.file.url} />
          </Box> 
        )
      ) : null}
      </Col>
    }
    </>
  );
};

export default MessageActions;






// function LinearProgressWithLabel({value}) {

  // const customStylesForProgressBar = () =>{
  //   return (
  //   { 
  //      backgroundColor:"#4A5076",
  //     "& .MuiInputLabel-root": {
  //     fontFamily: "Inter",
  //     fontSize: "16px",
  //     fontStyle: "normal",
  //     fontWeight: 400,
  //     lineHeight: "24px",
  //     letterSpacing: "0.5px",
  //     color:  "rgba(255, 255, 255, 0.80)",
  //     },
  //     '& .MuiLinearProgress-bar': {
  //     borderRadius: 3, // Rounded corners for the progress bar
  //     backgroundColor: '#CAC4D0', // Progress bar color
  //   },
  
  //  }
  //   )
  // }
  
  // const [progress, setProgress] = useState(props?.value)
  // const ref = useRef()

  // useEffect(()=>{
  //   ref.current = setInterval(()=>{
  //     if(Number(progress) < 100){
  //       setProgress((prev)=>Number(prev) + 1)
  //     }else{
  //       setProgress(0)
  //     }
  //   },2000)
  // },[])

  // useEffect(()=>{
  //   if(props?.value){
  //     setProgress(props?.value)
  //     clearInterval(ref.current)
  //   }
  // },[props?.value])

  // return (
  //   <Box sx={{ display: 'flex', alignItems: 'center', height:"48px", ml:"10px", mb:"20px" }}>
  //     <Box sx={{ width: '100%' ,mr: 3 }}>
  //       <LinearProgress variant="determinate" value = {progress} sx={()=>customStylesForProgressBar()}/>
  //     </Box>
  //     <Box sx={{ minWidth: 35 }}>
  //       <Typography variant="body2" sx={{ color: '#ffffff', fontSize:"20px", fontFamily:"Inter", fontStyle:"normal", fontWeight:500, lineHeight:"20px", letterSpacing:"0.1px"  }}>
  //         {
  //           `${Math.floor(progress)}%`
  //         }
  //       </Typography>
  //     </Box>
  //   </Box>
  // );

// }

// import React, { useEffect, useState } from "react";
// import { Col, Button, Spinner, Modal } from "react-bootstrap";
// import PreviewMedia from "./PreviewMedia"; // Import the PreviewMedia component
// import { useWorkspace } from "../../../contexts/WorkspaceContext";

// const MessageActions = ({ message, handleDownload }) => {
//   const {checkUrlStatus} = useWorkspace()
//   const [showPreviewModal, setShowPreviewModal] = useState(false);
//   const [previewUrl, setPreviewUrl] = useState("");
//   const [progress,setProgress] = useState(true);
//   const url = message.file.url || '' ;

//   useEffect(() => {
//     let intervalId;
  
//     const checkStatus = () => {
//       if (checkUrlStatus(url)) {
//         clearInterval(intervalId);
//         setProgress(false);
//       }
//     };

//     intervalId = setInterval(checkStatus, 3000);
    
  
//     return () => {
//       clearInterval(intervalId);
//     };
//   }, [url, checkUrlStatus, progress]);
  

//   const handlePreview = () => {
//     setShowPreviewModal(true);
//     setPreviewUrl(message.file.url);
//   };

//   const handleClosePreviewModal = () => {
//     setShowPreviewModal(false);
//     setPreviewUrl("");
//   };

//   return (
//     <>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <Button
//           variant="outline-secondary"
//           onClick={() => handleDownload(message.file.url)}
//           disabled={message.file.status === "processing"}
//           className="my-2 d-flex align-items-center justify-content-start"
//         >
//           {!message.file.url.endsWith(".mp3") &&
//           !message.file.url.endsWith(".mp4") &&
//           !message.file.url.endsWith(".jpg") ? (
//             <>
//               <i className="bi bi-cloud-arrow-down pe-3" />
//               Download
//             </>
//           ) : (
//             <>
//               <i className="bi bi-arrow-up-right-circle pe-3" />
//               Open
//             </>
//           )}
//           {progress && (
//             <Spinner
//               animation="border"
//               role="status"
//               size="sm"
//               className="ms-2"
//             />
//           )}
//         </Button>
//       </Col>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <Button
//           variant="outline-secondary"
//           disabled={message.file.status === "processing"}
//           className="my-2 d-flex align-items-center justify-content-start"
//           onClick={handlePreview} // Add onClick handler to trigger preview
//         >
//           <i className="bi bi-easel2 pe-3" />
//           Preview
//           {progress && (
//             <Spinner
//               animation="border"
//               role="status"
//               size="sm"
//               className="ms-2"
//             />
//           )}
//         </Button>
//       </Col>
//       {/* Render Preview modal */}
//       <Modal show={showPreviewModal} onHide={handleClosePreviewModal} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Preview</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <PreviewMedia url={previewUrl} />
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default MessageActions;







// import React from "react";
// import { Col, Button, Spinner } from "react-bootstrap";

// const MessageActions = ({ message, handleDownload, handlePreview }) => {
//   console.log("here is the message actions",message);
//   return (
//     <>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <Button
//           variant="outline-secondary"
//           onClick={() => handleDownload(message.file.url)}
//           disabled={message.file.status === "processing"}
//           className="my-2 d-flex align-items-center justify-content-start"
//         >
//           {!message.file.url.endsWith(".mp3") &&
//           !message.file.url.endsWith(".mp4") &&
//           !message.file.url.endsWith(".jpg") ? (
//             <>
//               <i className="bi bi-cloud-arrow-down pe-3" />
//               Download
//             </>
//           ) : (
//             <>
//               <i className="bi bi-arrow-up-right-circle pe-3" />
//               Open
//             </>
//           )}
//           {message.file.status === "processing" && (
//             <Spinner
//               animation="border"
//               role="status"
//               size="sm"
//               className="ms-2"
//             />
//           )}
//         </Button>
//       </Col>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <Button
//           variant="outline-secondary"
//           onClick={() => handlePreview(message.file.url)}
//           disabled={message.file.status === "processing"}
//           className="my-2 d-flex align-items-center justify-content-start"
//         >
//           <i className="bi bi-easel2 pe-3" />
//           Preview
//           {message.file.status === "processing" && (
//             <Spinner
//               animation="border"
//               role="status"
//               size="sm"
//               className="ms-2"
//             />
//           )}
//         </Button>
//       </Col>
//     </>
//   );
// };

// export default MessageActions;
