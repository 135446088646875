// import React, { useState } from "react";
// import { Modal, Button, Form, Row, Col } from "react-bootstrap";
// import { useAuth } from "../../contexts/AuthContext";
// import Notification from "../Notification";
// import { auth, createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from "../../config/firebase.config";

// function SignUp({
//   showSignUpModal,
//   setShowSignUpModal,
//   setShowLoginModal,
//   showLoginModal,
//   setEmailSent
// }) {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showNotif, setShowNotif] = useState(false);
//   const [notifVariant, setNotifVariant] = useState("");
//   const [notifMsg, setNotifMsg] = useState("");
//   const [passwordCriteria, setPasswordCriteria] = useState({
//     minLength: false,
//     uppercase: false,
//     lowercase: false,
//     number: false,
//     symbol: false
//   });
//   const [passwordTouched, setPasswordTouched] = useState(false);

//   const { setUser } = useAuth();

//   const handlePasswordChange = (e) => {
//     const newPassword = e.target.value;
//     setPassword(newPassword);
//     setPasswordTouched(true);

//     // Validate password criteria
//     const criteria = {
//       minLength: newPassword.length >= 8,
//       uppercase: /[A-Z]/.test(newPassword),
//       lowercase: /[a-z]/.test(newPassword),
//       number: /[0-9]/.test(newPassword),
//       symbol: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword)
//     };
//     setPasswordCriteria(criteria);
//   };

//   const handleSignUp = async () => {
//     try {
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       await sendVerificationEmail();
//     } catch (error) {
//       console.error("Error signing up:", error);
//       setNotifMsg(`Error signing up: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const sendVerificationEmail = async () => {
//     try {
//       await sendEmailVerification(auth.currentUser);
//       setEmailSent(true);
//       setNotifMsg("Verification email sent. Please verify your email address.");
//       setNotifVariant("info");
//       setShowNotif(true);
//     } catch (error) {
//       console.error("Error sending verification email:", error);
//       throw error;
//     }
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     if (password === confirmPassword) {
//       handleSignUp();
//     } else {
//       setNotifMsg("Passwords do not match.");
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleGoogleSignUp = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);
//       // Redirect to .workspace after successful signup

//       if(result.user.emailVerified){
//         window.location.href = "/workspace";
//         localStorage.setItem("isLoggedIn",true)
//       }

//       // window.location.href = "/workspace"; // Alternatively, use react-router-dom for routing
//     } catch (error) {
//       console.error("Error signing up with Google:", error);
//       setNotifMsg(`Error signing up with Google: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleCloseSignUpModal = () => {
//     setShowSignUpModal(false);
//   };

//   const handleOpenLoginModal = () => {
//     setShowSignUpModal(false);
//     setShowLoginModal(true);
//   };

//   return (
//     <>
//       <Notification
//         showNotif={showNotif}
//         setShowNotif={setShowNotif}
//         variant={notifVariant}
//       >
//         {notifMsg}
//       </Notification>

//       <Modal
//         centered
//         show={showSignUpModal}
//         onHide={handleCloseSignUpModal}
//       >
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Sign up</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="my-3">
//           <Row className="justify-content-center">
//             <Col md={10}>
//               <Form onSubmit={handleFormSubmit}>
//                 <Form.Group controlId="formBasicEmail" className="mb-3">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control
//                     type="email"
//                     placeholder="Enter email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formBasicPassword" className="mb-3">
//                   <Form.Label>Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={handlePasswordChange}
//                     required
//                   />
//                   {passwordTouched && (
//                     <div className="text-muted mt-1">
//                       <ul style={{ listStyleType: "none", padding: 0 }}>
//                         <li>
//                           {passwordCriteria.minLength ? (
//                             <span className="text-success">&#10003; Minimum 8 characters</span>
//                           ) : (
//                             <span className="text-danger">&#10005; Minimum 8 characters</span>
//                           )}
//                         </li>
//                         <li>
//                           {passwordCriteria.uppercase ? (
//                             <span className="text-success">&#10003; At least one uppercase letter</span>
//                           ) : (
//                             <span className="text-danger">&#10005; At least one uppercase letter</span>
//                           )}
//                         </li>
//                         <li>
//                           {passwordCriteria.lowercase ? (
//                             <span className="text-success">&#10003; At least one lowercase letter</span>
//                           ) : (
//                             <span className="text-danger">&#10005; At least one lowercase letter</span>
//                           )}
//                         </li>
//                         <li>
//                           {passwordCriteria.number ? (
//                             <span className="text-success">&#10003; At least one number</span>
//                           ) : (
//                             <span className="text-danger">&#10005; At least one number</span>
//                           )}
//                         </li>
//                         <li>
//                           {passwordCriteria.symbol ? (
//                             <span className="text-success">&#10003; At least one symbol</span>
//                           ) : (
//                             <span className="text-danger">&#10005; At least one symbol</span>
//                           )}
//                         </li>
//                       </ul>
//                     </div>
//                   )}
//                 </Form.Group>

//                 <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
//                   <Form.Label>Confirm Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Confirm Password"
//                     value={confirmPassword}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Button variant="primary" type="submit" className="w-100">
//                   Sign up
//                 </Button>
//                 <Button variant="info" className="w-100 mt-3" onClick={handleGoogleSignUp} style={{display:'flex',gap:'10px',alignItems:'center',justifyContent:'center'}}>
//                   <img style={{width:'5%'}} src={require(`../../assets/transparent-google-logo-google-logo-with-multicolored-g-and-1710875781697.png`)}/>
//                   Sign up with Google
//                 </Button>
//               </Form>
//             </Col>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             Already a member?{" "}
//             <Button variant="link" onClick={handleOpenLoginModal}>
//               Login
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default SignUp;

//================================ Vishnu Last Code for Signup Start =============================

// import React, { useState, useEffect } from "react";
// import { Modal, Button, Form, Row, Col, CloseButton } from "react-bootstrap";
// import { useAuth } from "../../contexts/AuthContext";
// import Notification from "../Notification";
// import {
//   auth,
//   createUserWithEmailAndPassword,
//   sendEmailVerification,
//   signInWithPopup,
//   GoogleAuthProvider,
// } from "../../config/firebase.config";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { disposableEmailProviders } from "../../constants/disposableEmailDomains";
// import sourceUrl from "../../api/Source.api";

// function SignUp({
//   showSignUpModal,
//   setShowSignUpModal,
//   setShowLoginModal,
//   showLoginModal,
//   setEmailSent,
// }) {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showNotif, setShowNotif] = useState(false);
//   const [notifVariant, setNotifVariant] = useState("");
//   const [notifMsg, setNotifMsg] = useState("");
//   const [showVerificationMsg, setShowVerificationMsg] = useState(false);
//   const [verified, setVerified] = useState(false);
//   const [pageReloaded, setPageReloaded] = useState(false); // State to keep track of whether the page has been reloaded
//   const { setUser } = useAuth();
//   const token = localStorage.getItem("token");
//   const [passwordCriteria, setPasswordCriteria] = useState({
//     minLength: false,
//     uppercase: false,
//     lowercase: false,
//     number: false,
//     symbol: false,
//   });
//   const navigate = useNavigate();
//   const [passwordTouched, setPasswordTouched] = useState(false);
//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user && user.emailVerified) {
//         setVerified(true);
//       }
//     });

//     return () => {
//       unsubscribe();
//     };
//   }, []);
//   useEffect(() => {
//     validatePassword(password);
//   }, [password]);

//   const validatePassword = (password) => {
//     const criteria = {
//       minLength: password.length >= 8,
//       uppercase: /[A-Z]/.test(password),
//       lowercase: /[a-z]/.test(password),
//       number: /[0-9]/.test(password),
//       symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
//     };
//     setPasswordCriteria(criteria);
//   };

//   useEffect(() => {
//     if (verified && !pageReloaded) {
//       setShowLoginModal(false);
//       setPageReloaded(true);
//     }
//   }, [verified, pageReloaded]);

//   const isDisposableEmail = (email) => {
//     const emailDomain = email.split("@")[1];
//     return disposableEmailProviders.includes(emailDomain);
//   };

//   const verifyUser = async (email) => {
//     const token = localStorage.getItem("token");
//     try {
//       const response = await axios.post(
//         `${sourceUrl}/login/verify`,
//         { email },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (response.data.status === false) {
//         throw new Error("Only invited users will be able to use Modalx.ai");
//       }
//       return true;
//     } catch (error) {
//       setNotifMsg(error.message || "Verification failed");
//       setNotifVariant("danger");
//       setShowNotif(true);
//       return false;
//     }
//   };

//   const handleSignUp = async () => {
//     if (isDisposableEmail(email)) {
//       setNotifMsg(
//         "We do not accept temporary or disposable email addresses. Please use a permanent email."
//       );
//       setNotifVariant("danger");
//       setShowNotif(true);
//       return;
//     }

//     try {
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );
//       await sendVerificationEmail();
//       console.log("user_credentials", userCredential);
//       await axios.post(
//         `${sourceUrl}/profile`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${userCredential._tokenResponse.idToken}`,
//           },
//         }
//       );
//     } catch (error) {
//       console.error("Error signing up:", error);
//       setNotifMsg(`Error signing up: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const sendVerificationEmail = async () => {
//     try {
//       await sendEmailVerification(auth.currentUser);
//       setEmailSent(true);
//       handleOpenLoginModal();
//       setNotifMsg("Verification email sent. Please verify your email address.");
//       setNotifVariant("info");
//       setShowNotif(true);
//       setShowVerificationMsg(true);
//     } catch (error) {
//       console.error("Error sending verification email:", error);
//       throw error;
//     }
//   };

//   const handleAccountCreation = async () => {
//     try {
//       setShowVerificationMsg(true);
//     } catch (error) {
//       console.error("Error completing account creation:", error);
//       setNotifMsg(`Error completing account creation: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleGoogleSignUp = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);

//       // ===================== Invited users check =====================
//       // const isVerified = await verifyUser(result.user.email);
//       // if (!isVerified) return;

//       // ===============================================================
//       if (result.user.emailVerified) {
//         {
//           result.user &&
//             result.user.accessToken &&
//             (await axios.post(
//               `${sourceUrl}/profile`,
//               {},
//               {
//                 headers: {
//                   Authorization: `Bearer ${result.user.accessToken}`,
//                 },
//               }
//             ));
//         }

//         localStorage.setItem("token", result.user.accessToken);
//         const isVerified = await verifyUser(result.user.email);
//         if (!isVerified) {
//           setShowSignUpModal(true);
//           await axios.post(
//             `${sourceUrl}/profile`,
//             {},
//             {
//               headers: {
//                 Authorization: `Bearer ${result.user.accessToken}`,
//               },
//             }
//           );
//           return;
//         }
//         localStorage.setItem("current_thread_id", "");
//         localStorage.setItem("current_history_id", "");
//         setUser(result.user.emailVerified);
//         navigate("/workspace");
//         localStorage.setItem("isLoggedIn", true);
//         localStorage.setItem("userEmail", result.user.email);
//         setShowLoginModal(false);
//         window.location.reload();
//       } else {
//         setShowLoginModal(true);
//         localStorage.setItem("isLoggedIn", "");
//       }
//     } catch (error) {
//       console.error("Error signing up with Google:", error);
//       setNotifMsg(`Error signing up with Google: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     if (password === confirmPassword) {
//       handleSignUp();
//     } else {
//       setNotifMsg("Passwords do not match.");
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleCloseSignUpModal = () => {
//     setShowSignUpModal(false);
//   };

//   const handleCloseLoginModal = () => {
//     setShowLoginModal(false);
//   };

//   const handleOpenSignUpModal = () => {
//     setShowSignUpModal(true);
//     setShowLoginModal(false);
//   };

//   const handleOpenLoginModal = () => {
//     setShowSignUpModal(false);
//     setShowLoginModal(true);
//   };

//   return (
//     <>
//       <Notification
//         showNotif={showNotif}
//         setShowNotif={setShowNotif}
//         variant={notifVariant}
//       >
//         {notifMsg}
//       </Notification>

//       <Modal centered show={showSignUpModal} onHide={handleCloseSignUpModal}>
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Sign up</Modal.Title>
//           <CloseButton onClick={handleCloseSignUpModal} />
//         </Modal.Header>
//         <Modal.Body className="my-3">
//           {/* {showVerificationMsg && (
//           <p className="text-center mt-3" style={{color:'red'}}>Please verify your email address.</p>
//         )} */}
//           <Row className="justify-content-center">
//             <Col md={10}>
//               <Form
//                 onSubmit={handleFormSubmit}
//                 style={{ width: "75%", margin: "auto" }}
//               >
//                 <Form.Group controlId="formBasicEmail" className="mb-3">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control
//                     type="email"
//                     placeholder="Enter email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formBasicPassword" className="mb-3">
//                   <Form.Label>Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group
//                   controlId="formBasicConfirmPassword"
//                   className="mb-3"
//                 >
//                   <Form.Label>Confirm Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Confirm Password"
//                     value={confirmPassword}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 {password && (
//                   <div className="text-muted mt-1">
//                     <ul style={{ listStyleType: "none", padding: 0 }}>
//                       <li>
//                         {passwordCriteria.minLength ? (
//                           <span className="text-success">
//                             &#10003; Minimum 8 characters
//                           </span>
//                         ) : (
//                           <span className="text-danger">
//                             &#10005; Minimum 8 characters
//                           </span>
//                         )}
//                       </li>
//                       <li>
//                         {passwordCriteria.uppercase ? (
//                           <span className="text-success">
//                             &#10003; At least one uppercase letter
//                           </span>
//                         ) : (
//                           <span className="text-danger">
//                             &#10005; At least one uppercase letter
//                           </span>
//                         )}
//                       </li>
//                       <li>
//                         {passwordCriteria.lowercase ? (
//                           <span className="text-success">
//                             &#10003; At least one lowercase letter
//                           </span>
//                         ) : (
//                           <span className="text-danger">
//                             &#10005; At least one lowercase letter
//                           </span>
//                         )}
//                       </li>
//                       <li>
//                         {passwordCriteria.number ? (
//                           <span className="text-success">
//                             &#10003; At least one number
//                           </span>
//                         ) : (
//                           <span className="text-danger">
//                             &#10005; At least one number
//                           </span>
//                         )}
//                       </li>
//                       <li>
//                         {passwordCriteria.symbol ? (
//                           <span className="text-success">
//                             &#10003; At least one symbol
//                           </span>
//                         ) : (
//                           <span className="text-danger">
//                             &#10005; At least one symbol
//                           </span>
//                         )}
//                       </li>
//                     </ul>
//                   </div>
//                 )}
//                 <div>
//                   <Button variant="primary" type="submit" className="w-100">
//                     Sign up
//                   </Button>
//                   <Button
//                     variant="info"
//                     className="w-100 mt-3"
//                     onClick={handleGoogleSignUp}
//                     style={{
//                       display: "flex",
//                       gap: "10px",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       backgroundColor: "#d95959",
//                       color: "white",
//                       border: "none",
//                     }}
//                   >
//                     <img
//                       style={{ width: "5%" }}
//                       src={require(`../../assets/transparent-google-logo-google-logo-with-multicolored-g-and-1710875781697.png`)}
//                     />
//                     Sign up with Google
//                   </Button>
//                   {/* <Button variant="info" className="w-100 mt-3" onClick={handleGoogleSignUp} style={{display:'flex',gap:'10px',alignItems:'center',justifyContent:'center',backgroundColor:'#d95959',color:'white',border:'none'}}>
//                   <img style={{width:'5%'}} src={require(`../../assets/—Pngtree—facebook social media icon facebook_3654772.png`)}/>
//                   Sign up with Facebook
//                 </Button> */}
//                 </div>
//               </Form>
//             </Col>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             Already a member?{" "}
//             <Button variant="link" onClick={handleOpenLoginModal}>
//               Login
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>

//       <Modal centered show={showLoginModal} onHide={handleCloseLoginModal}>
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Login</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="my-3">{/* Your login form here */}</Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             Not a member?{" "}
//             <Button variant="link" onClick={handleOpenSignUpModal}>
//               Sign up
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default SignUp;

//========================Vishnu Last Code for Signup End=====================================

//========================Shaban Code for Signup Start=====================================

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  useMediaQuery,MenuItem,CardMedia
} from "@mui/material";
import { New_Project_Button_bg_color } from "../../Provider/Color_Provider";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { disposableEmailProviders } from "../../constants/disposableEmailDomains";
import {
  button_bg_color_for_New_Project_dm,
  uploadFiles_button_default_bg_color,
  uploadFiles_button_hovered_bg_color,
} from "../../constants/colors";

import { useAuth } from "../../contexts/AuthContext";
import {
  auth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "../../config/firebase.config";
import sourceUrl from "../../api/Source.api";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SignupLeftSide from "./SignupLeftSide";
import success from "../../assets/signup/success.png";
import error from "../../assets/signup/error.png";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VerifyEmailModal from "./VerifyEmailModal";
import { useTheme } from "@mui/material/styles";
import india from '../../assets/Flag/india.png'
import us from '../../assets/Flag/us.png'
import canada from '../../assets/Flag/canada.png'
import { ErrorEvent, SignUpEvent } from "../../config/analytics";
import CommonToast from "../Common_Components/CommonToast";
import ModalXLogo from '../../assets/signup/loginPageLogo.png'
import SignUpLogInLayout from "./SignUpLogInLayout";
import { useWorkspace } from "../../contexts/WorkspaceContext";
import mailIcon from "../../assets/signup/mail.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const countryCodes = [
  { id : 1, code: '+1', countryName: 'Canada', flag : canada }, // United States
  { id : 3, code: '+91', countryName: 'India',flag : india }, // India
  { id : 2, code: '+1', countryName: 'United State', flag : us }, // United States
];


const Signup = () => {
  const [phone , setPhone] = useState('')
  const [password, setPassword] = useState("");
  const [maskedPassword, setMaskedPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNotif, setShowNotif] = useState(false);
  const [notifVariant, setNotifVariant] = useState("");
  const [notifMsg, setNotifMsg] = useState("");
  const [showVerificationMsg, setShowVerificationMsg] = useState(false);
  const [verified, setVerified] = useState(false);
  const [pageReloaded, setPageReloaded] = useState(false);
  const { setUser } = useAuth();
  const token = localStorage.getItem("token");
  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });
  const navigate = useNavigate();
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPhoneNumber , setPhoneNumber] = useState(false)
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const { setShowLoginModal, setShowSignUpModal,showVerificationModal,setShowVerificationModal, currentWindow, setCurrentWindow} = useAuth()
   const { email, setEmail} = useWorkspace()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setVerified(true);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    validatePassword(password);
  }, [password]);

  const validatePasswords = (pwd, confirmPwd) => {
    if (confirmPwd.length === 0) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(pwd === confirmPwd);
    }
  };

  const handlePasswordChange = (event) => {
    const input = event.target.value;
    let updatedPassword;
  
    if (showPassword) {
      updatedPassword = input;
    } else {
      if (input.length < password.length) {
        updatedPassword = password.slice(0, input.length);
      } else {
        updatedPassword = password + input[input.length - 1];
      }
    }
  
    setPassword(updatedPassword);
    setMaskedPassword('*'.repeat(updatedPassword.length));
    validatePasswords(updatedPassword, confirmPassword);
  };

  const validatePassword = (password) => {
    const criteria = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordCriteria(criteria);
  };

  const handleToggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (verified && !pageReloaded) {
      // setShowLoginModal(false);
      setPageReloaded(true);
    }
  }, [verified, pageReloaded]);

  const verifyUser = async (email) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${sourceUrl}/login/verify`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === false) {
        throw new Error("Only invited users will be able to use Modalx.ai");
      }
      return true;
    } catch (error) {
      setNotifMsg(error.message || "Verification failed");
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
      return false;
    }
  };

  const isDisposableEmail = (email) => {
    const emailDomain = email.split("@")[1];
    return disposableEmailProviders.includes(emailDomain);
  };

  const handleEmailChange = (e) =>{
    const newEmail = e.target.value;
    setEmail(newEmail);
        if (isDisposableEmail(newEmail)) {
          setIsInvalidEmail(true);
        } else {
          setIsInvalidEmail(false);
        }
  }

  const [toggle, setToggle] = useState(false); // Toggle state for showing InputProps
  const onMousEnter = () =>{
    setToggle(true)
  }
  const onMouseLeve = () =>{
    setToggle(false)
  }
  const [selectedCountryCode, setSelectedCountryCode] = useState(''); // Default country code
  const handleNumberChange = (event) => {
    let value = event.target.value;
    // Remove any existing country code from the input and re-apply the selected country code
    if (value.startsWith(selectedCountryCode)) {
      value = value.slice(selectedCountryCode.length);
    }
    setPhone(`${selectedCountryCode}${value}`);
  };
  // console.log(phone)

    // Handle country code change
    const [hide , setHide] = useState(false)
    
    const handleCountryCodeChange = (event) => {
      const newCode = event.target.value;
      setHide(true)
      // Update selected country code and adjust phone number accordingly
      if (phone.startsWith(selectedCountryCode)) {
        setPhone(newCode + phone.slice(selectedCountryCode.length));
      } else {
        setPhone(newCode + phone);
      }
      setSelectedCountryCode(newCode);
    };
    const handleClick = () => {
      if (!phone.startsWith(selectedCountryCode)) {
        setPhone(`${selectedCountryCode}${phone}`);
      }
    };

  const handleSignUp = async () => {
    if (isDisposableEmail(email)) {
      setNotifMsg(
        "We do not accept temporary or disposable email addresses. Please use a permanent email."
      );
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
      return;
    }
    if(phone== "" || phone.length <=3){
      setPhoneNumber("Please enter a phone number")
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
       await sendVerificationEmail();
      // console.log("user_credentials", userCredential);
       const res = await axios.post(`${sourceUrl}/profile`,
        {
          mobile_number : phone,
          firstName : firstName,
          lastName : lastName,  
        },
        {
          headers: {
            Authorization: `Bearer ${userCredential._tokenResponse.idToken}`,
          },
        }
      );
      SignUpEvent("user_signup", "signup_with_email")

      // clearStates()
    } catch (error) {
      ErrorEvent(error.status,error.message);
      console.error("Error signing up:", error);
      setNotifMsg('This email is already in use. Please login.');
      setShowLoginModal(true);
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
    }
  };

  const handleOpenVerificationModal = () =>{
    setShowVerificationModal(true);
    setShowSignUpModal(false)
  }

  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      // setEmailSent(true);
      // handleOpenLoginModal();
      handleOpenVerificationModal();
      setNotifMsg("Verification email sent. Please verify your email address.");
      setNotifVariant("success");
      setShowNotif(true);
      setShowVerificationMsg(true);
      setCurrentWindow(3);
    } catch (error) {
      ErrorEvent(error, error.message);
      console.error("Error sending verification email:", error);
      throw error;
    }
  };

  const handleAccountCreation = async () => {
    try {
      setShowVerificationMsg(true);
    } catch (error) {
      console.error("Error completing account creation:", error);
      setNotifMsg(`Error completing account creation: ${error.message}`);
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
    }
  };

  const handleCloseToast = () => {
    setOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if(firstName === "" ){
      setFirstNameError(true)
      return
    }else if(lastName === ""){
      setLastNameError(true)
      return
    } else {
      handleSignUp();
    }
  };

  const handleOpenLoginModal = () => {
    setCurrentWindow(1)
    navigate('/login')
  };

  const handleCloseSignUpModal = () => {
    if(!isBelow991){
      setShowSignUpModal(false);
    }
  };
  const customTextFieldStyles = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
      color: "rgba(255, 255, 255, 0.80) !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.80) !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "rgba(255, 255, 255, 0.40)",
    },
    "& .MuiInputLabel-root.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.40)", // New color
    },
  };

  const customTextFieldStylesForInvalid = (isInvalid) => {
  return ( 
    {"& .MuiInputLabel-root": {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color:  "rgba(255, 255, 255, 0.80)",
    "&.Mui-focused": {
      color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80)",
    },
  },
  "& .MuiInputBase-input": {
    color: "#FFFFFF",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
    },
    "&:hover fieldset": {
      borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
    },
    "&.Mui-focused fieldset": {
      borderColor: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
    color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",},
  "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "rgba(255, 255, 255, 0.40)",
  },
  })
  }

  const [countryCode, setCountryCode] = useState('in'); // initialize with empty string or default

  useEffect(() => {
    axios.get('https://ipinfo.io?token=<your_ipinfo_token>').then(response => {
      const country = response.data.country.toLowerCase(); // returns country code, e.g., "us"
      setCountryCode(country);
    }).catch(error => {
      console.log('Error fetching country code', error);
    });
  }, []);

   const HandleGoBackClickOnSignUpPage = () =>{
    setCurrentWindow(1);
   }

   const handleUseDiffrentButtonClick = () =>{
    setEmail("");
    setPassword("");
    setFirstName("")
    setLastName("")
    setPhone('')
    setCurrentWindow(1);
   }

   const handleResendEmailClick = async () =>{
    //write a lofic to resend verfication link again
   }

  return (
    <>
     {/* <div style={{position: isBelow991 ? "" :"absolute", zIndex:50, top: isBelow991 ? "0%" : "50%", marginTop: isBelow991 ? "0%" :"-40px"  , left:isBelow991 ? "0%" :"50%",transform: isBelow991 ? "" : "translate(-50%, -0%)",  backgroundColor:"#171A2D"}}> */}
      {
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: isBelow991 ? "" : "100vh",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: 10,
          }}
        >
          <Box
            onClick={(event) => event.stopPropagation()}
            sx={{
              display: "flex",
              flexDirection: isBelow991 ? "column-reverse" : "row",
              height: "100%",
              width: "100%",
              // width:isBelow991 ? "100%" : "90%",
              // maxWidth:isBelow991 ? "342px" : "1200px",
              // height: "40.875rem",
              // borderRadius: "8px",
              // border: isBelow991 ? "" : "1px solid #CAC4D0",
            }}
          >
          <Box
              sx={{
                // backgroundColor: isBelow991 ? "#171A2D" : "#282D51",
                backgroundColor: "#171A2D",
                width: isBelow991 ? "100%": "50%",
                height: "100%",
                borderRadius: "0px 8px 8px 0px ",
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                alignItema: "center",
                padding: {
                  xs: "0px 0px",
                  sm: "0px 0px",
                  md: "0px 0px",
                  lg: "0px 141px",
                  xl: "0px 141px",
                },
              }}
            >
            {
            currentWindow === 1 &&
           <SignUpLogInLayout setCurrentWindow={setCurrentWindow} route={"signup"}/>
            }
            {
              currentWindow === 2 && 
              <div style={{ maxWidth: "340px", display:"flex", flexDirection:"column", alignSelf:"center", }}>
                  <Box sx={{display:"flex", justifyContent:"center", alignItems:"center",marginTop: isBelow991 ? "40px" : "60px", marginBottom: isBelow991 ? "40px" : "80px"}}>
                    <img src={ModalXLogo} alt={"logo"} style={{width:"163px", height:"51px"}}/>
                  </Box>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "20px",
                    color: "#FFFFFF",
                    marginBottom: "40px",
                    alignSelf: "stretch",
                    whiteSpace: "nowrap",
                  }}
                >
                  Create an account
                </Typography>
                <FormControl
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                
                  <TextField disabled={true}
                    type="email"
                    value={email}
                    id="outlined-basic"
                    label="Email address*"
                    variant="outlined"
                    sx={customTextFieldStyles}
                    style={{
                      width: "100%", 
                      maxWidth :  "340px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: isInvalidEmail ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      // margin:  isBelow991 ?  "4px 0px 14px 16px":"4px 0px 14px 16px",
                      alignSelf: "start",
                      marginBottom: "40px"
                    }}
                  >
                    {isInvalidEmail ? "Email address is invalid" : ""}
                  </FormHelperText>


                  <Box sx={{display:"flex", flexDirection:"row", gap:"15px", marginBottom: firstNameError || lastNameError ? "10px" : "40px"}}>
                  <Box>
                  <TextField
                    type="text"
                    onChange={(e)=>{setFirstName(e.target.value); setFirstNameError(false)}}
                    value={firstName}
                    id="outlined-basic"
                    label="First Name*"
                    variant="outlined"
                    sx={()=>customTextFieldStylesForInvalid(firstNameError)}
                    style={{
                      width: "100%", 
                      maxWidth :  isBelow991 ? "100%" : "300px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: firstNameError ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      margin:  isBelow991 ? ( firstNameError ? "4px 0px 14px 16px" : "4px 0px 0px 16px" ):(firstNameError ? "4px 0px 14px 16px" : "4px 0px 0px 16px"),
                      alignSelf: "start",
                    }}
                  >
                    {firstNameError ? "Enter First Name" : ""}
                  </FormHelperText>
                  </Box>
                  <Box>
                <TextField
                    type="text"
                    onChange={(e)=>{setLastName(e.target.value); setLastNameError(false)}}
                    value={lastName}
                    id="outlined-basic"
                    label="Last Name*"
                    variant="outlined"
                    sx={()=>customTextFieldStylesForInvalid(lastNameError)}
                    style={{
                      width: "100%", 
                      maxWidth :  isBelow991 ? "100%" : "300px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: lastNameError ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      margin:  isBelow991 ?  ( lastNameError ? "4px 0px 14px 16px" : "4px 0px 0px 16px" ):(lastNameError ? "4px 0px 14px 16px" : "4px 0px 0px 16px"),
                      alignSelf: "start",
                    }}
                  >
                    {lastNameError ? "Enter Last Name" : ""}
                  </FormHelperText>
                  </Box>
                </Box>

                  <TextField
                    type="tel"
                    onChange={handleNumberChange}
                    onMouseEnter={onMousEnter}
                    onMouseLeave={onMouseLeve}
                    onTouchStart={onMousEnter} // For mobile touch interaction
                    onTouchEnd={onMouseLeve}   // For mobile touch interaction
                    value={phone}
                    onClick={handleClick} // Ensure country code is prepended when clicked
                    label="Phone number"
                    variant="outlined"
                    sx={()=>customTextFieldStylesForInvalid(isPhoneNumber)}
                    placeholder="Phone number"
                    InputProps={toggle ? { 
                      startAdornment: (
                        <InputAdornment position="start" >
                          <TextField 
                            select
                            value={selectedCountryCode}
                            onChange={handleCountryCodeChange}
                            variant="standard"
                            style={{ width: '50px' }} // Adjust width to accommodate flags
                          > 
                              {countryCodes.map((country) => (
                                
                              <MenuItem key={country.id} 
                                value={country.code} 
                                sx={{
                                bgcolor : '#282D51',
                                marginTop : '-8px',
                                marginBottom : '-8px',
                                width : '200px',
                                height : '56px',
                                '&:hover' :{
                                  bgcolor : '#393F69'
                                }                  
                              }}>
                                <Box  sx={{display : 'flex'}}>
                                <Box sx={{
                                  width : '24px',
                                  height : '24px',
                                  marginRight : '12px',
                                }}>
                                <CardMedia
                                  component="img" // Can also be "video" or other media types        
                                  image={country.flag} // Replace with your image URL
                                  alt="flag"
                                />
                                </Box>
                                <Typography sx={{
                                display : hide ? 'none' : 'block',
                                color : "white", 
                                fontFamily : 'Inter',
                                fontSize : '14px',
                                textAlign : 'left',
                                opacity : '80%'
                                }}>{country.countryName}
                                <Typography component='span'
                                sx={{
                                color : "white", 
                                fontFamily : 'Inter',
                                fontSize : '14px',
                                textAlign : 'left',
                                opacity : '80%',
                                marginLeft : '5px'
                                }}>{country.code}</Typography>
                                </Typography>
                                </Box>
                              </MenuItem>
                            ))}
                          </TextField>
                        </InputAdornment>
                      )
                    } : null}
                    style={{
                      width: "100%", 
                      maxWidth :  "340px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                      // marginBottom : '20px'
                    }}
                  />
                
                <Box sx={{width:"340px", marginTop:"40px"}}>
                  <TextField
                    type={"text"} 
                    onChange={handlePasswordChange}
                    value={showPassword ? password : maskedPassword} 
                    id="outlined-basic"
                    label="Create a password*"
                    variant="outlined"
                    sx={ customTextFieldStylesForInvalid(false)}        
                    style={{
                      width: "100%", 
                      maxWidth :  "340px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                            tabIndex={-1}
                            
                          >
                            {showPassword ?  <Visibility sx={{color:"#CAC4D0"}}/> : <VisibilityOff  sx={{color:"#CAC4D0"}}/> }
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> 
                  { 
                     password ? 
                      <Box style={{padding:"4px 16px 0px 16px",display:"flex", flexDirection:"column", alignSelf:"flex-start"}}>
                                          <ul style={{ listStyleType: "none", padding: 0,  display:"flex", flexDirection:"column", gap:"4px",color:"#FFFFFF", fontSize:"12px", fontWeight:400, lineHeight:"16px" }}>
                                            <li >
                                              {passwordCriteria.minLength ? (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px", height:"16px"}}>
                                                  <img width="14px" height="14px" src={success} alt=" "/>
                                                  <span>8 characters minimum</span>
                                                </div>
                                              ) : (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px", alignItems:"center",height:"16px"}}>
                                                   <img  width="14px" height="14px" src={error} alt=" "/>
                                                   <span>8 characters minimum</span>
                                                </div>
                                              )}
                                            </li>

                                            <li>
                                              {passwordCriteria.uppercase ? (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",  alignItems:"center", height:"16px"}}>
                                                  <img width="14px" height="14px" src={success} alt=" "/>
                                                  <span>At least one uppercase letter</span>
                                                </div>
                                              ) : (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",  alignItems:"center", height:"16px"}}>
                                                   <img  width="14px" height="14px" src={error} alt=" "/>
                                                   <span>At least one uppercase letter</span>
                                                </div>
                                              )}
                                            </li>

                                            <li>
                                              {passwordCriteria.lowercase ? (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",alignItems:"center"}}>
                                                  <img width="14px" height="14px" src={success} alt=" "/>
                                                  <span>At least one lowercase letter</span>
                                                </div>
                                              ) : (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",  alignItems:"center"}}>
                                                   <img  width="14px" height="14px" src={error} alt=" "/>
                                                   <span>At least one lowercase letter</span>
                                                </div>
                                              )}
                                            </li>

                                            <li>
                                              {passwordCriteria.number ? (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",  alignItems:"center"}}>
                                                  <img width="14px" height="14px" src={success} alt=" "/>
                                                  <span>At least one number</span>
                                                </div>
                                              ) : (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px", alignItems:"center"}}>
                                                   <img  width="14px" height="14px" src={error} alt=" "/>
                                                   <span>At least one number</span>
                                                </div>
                                              )}
                                            </li>

                                            <li>
                                              {passwordCriteria.symbol ? (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",  alignItems:"center"}}>
                                                  <img width="14px" height="14px" src={success} alt=" "/>
                                                  <span>At least one symbol</span>
                                                </div>
                                              ) : (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px", alignItems:"center"}}>
                                                   <img  width="14px" height="14px" src={error} alt=" "/>
                                                   <span>At least one symbol</span>
                                                </div>
                                              )}
                                            </li>
                                          </ul>
                      </Box>
                     
                      : 
                    <FormHelperText
                    sx={{
                      color:
                        "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      alignSelf: "start",
                      margin:  isBelow991 ?  "4px 16px 40px 16px":"4px 16px 40px 16px",
                    }}
                  >
                    8 characters minimum
                  </FormHelperText>
                  }
                </Box>

                  <Box sx={password !=="" ? {marginBottom:"27px"} : {marginBottom:"50px"}}>
                    <Button
                      onClick={handleFormSubmit}
                      variant="outlined"
                      // onClick={handleNewChat}
                      // disabled={!isLoggedIn}
                      sx={{
                        gap: "8px",
                        padding: "10px 24px 10px 16px",
                        fontSize: "16px",
                        width: "340px", 
                        textTransform: "none",
                        bgcolor: '#F6BE6B',
                        color: "black",
                        fontWeight: "600",
                        height: "40px",
                        fontFamily: "Inter, sans-serif",
                        marginBottom:"28px",
                        "&:hover": {
                          bgcolor: "#ffd9aa",
                          border : 'none'
                        },
                        border : 'none'
                      }}
                    >
                      Continue
                    </Button>
                    <Typography onClick={HandleGoBackClickOnSignUpPage}
                      sx={{
                        display: "flex",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px",
                        color: "rgba(255, 255, 255, 0.80);",
                        padding: "auto auto",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        
                      }}
                    >
                      Go Back
                    </Typography>
                    <FormHelperText
                  sx={{
                    display: isBelow991 ? "flex" :"flex",
                    justifyContent: isBelow991 ? "center" :"center",
                    width: "100%", 
                    maxWidth :  isBelow991 ? "100%" : "300px",
                    color:
                      "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                    fontFamily: "Inter",
                    fontSize: '16px',
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    alignSelf: "center",
                    textAlign: "center",
                    whiteSpace:"nowrap",
                    marginTop : password ? "38px" : "60px"

                  }}
                >
                  Already have an account?{" "}
                  <span
                    onClick={handleOpenLoginModal}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      
                    }}
                  >
                    Log in
                  </span>

                </FormHelperText>
                  </Box>
                 
                </FormControl>

              </div>
            }
            {
              currentWindow === 3 && 
              <Box sx={{ marginTop: isBelow991 ? "40px" : "60px", display: "flex", flexDirection:"column", alignItems:"center", justifyContent:"center" }}>
                 <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom: isBelow991 ? "40px" : "80px"}}>
                    <img src={ModalXLogo} alt={"logo"} style={{width:"163px", height:"51px"}}/>
                  </Box>
                <Box sx={{maxWidth:"340px",}}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent:  "center",
                  }}
                >
                  <Box
                    style={{
                      width: "80px",
                      height: "80px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      backgroundColor: "rgba(255, 255, 255,0.05)",
                      opacity: 0.8,
                    }}
                  >
                    <img src={mailIcon} alt="mailIcon" style={{width:"40px", height:"40px"}}/>
                  </Box>
                </Box>
      
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "40px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      alignSelf: "stretch",
                      textAlign:  "center",
                      margin: "0px 0px",
                    }}
                  >
                    Verify your Email address
                  </Typography>
                </Box>
      
                <Box
                  sx={{
                    marginTop: "24px",
                    width: "300px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      textAlign:  "center",
                    }}
                  >
                    {`We have sent a verification link on ${email}. If you don't receive it shortly, please click Resend Mail.`}
                  </Typography>
                </Box>
      
                <Box
                  sx={{
                    marginTop: "48px",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: New_Project_Button_bg_color,
                      color: "rgba(23, 24, 44, 1)",
                      padding: "10px 24px 10px 24px",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      letterSpacing: "0.1px",
                      textAlign: "center",
                      width: "100%",
                      "&:hover": {
                        bgcolor: "#ffd9aa",
                      },
                    }}
                    onClick={handleResendEmailClick}
                  >
                    Resend Email
                  </Button>
                </Box>
    
            <Box
              onClick={()=>{handleUseDiffrentButtonClick()}}
              sx={{
                width: "100%",
                marginTop: "24px",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  alignSelf: "stretch",
                  textAlign: "center",
                }}
              >
                Use a different email address
              </Typography>
            </Box>
            <FormHelperText
                  sx={{
                    display: isBelow991 ? "flex" :"flex",
                    justifyContent: isBelow991 ? "center" :"center",
                    width: "100%", 
                    maxWidth :  isBelow991 ? "100%" : "300px",
                    color:
                      "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                    fontFamily: "Inter",
                    fontSize: '16px',
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    alignSelf: "center",
                    textAlign: "center",
                    whiteSpace:"nowrap",
                    marginTop : '80px',
                    marginBottom:"20px"
                  }}
                >
                  Verified email successfully?{" "}
                  <span
                    onClick={handleOpenLoginModal}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      
                    }}
                  >
                    Log in
                  </span>

                </FormHelperText>
            </Box>
    
              </Box>
            }
          </Box>
            <Box
                sx={{
                  backgroundColor:isBelow991 ? "" : "#1F233E",
                  width: isBelow991 ? "100%": "50%",
                  borderRadius: "16px 16px 16px 16px ",
                  display:isBelow991 ? currentWindow === 1 ? "flex" : "none" : "flex",
                  justifyContent:"center",
                  alignItems:"center",
                  margin: isBelow991? "0px" : "20px 20px 20px 20px ",
                }}
              >
               <SignupLeftSide /> 
            </Box>
          </Box>
        </div>
      }
      <CommonToast 
        open={open}
        onClose={handleCloseToast}
        content={notifMsg}
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={3000}
        iconType = 'error'
      />
      {/* </div> */}
    </>
  );
};
export default Signup;

//========================Shaban Code for Signup End=====================================

// import React, { useState, useEffect } from "react";
// import { Modal, Button, Form, Row, Col } from "react-bootstrap";
// import { useAuth } from "../../contexts/AuthContext";
// import Notification from "../Notification";
// import { auth, createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from "../../config/firebase.config";

// function SignUp({
//   showSignUpModal,
//   setShowSignUpModal,
//   setShowLoginModal,
//   showLoginModal
// }) {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showNotif, setShowNotif] = useState(false);
//   const [notifVariant, setNotifVariant] = useState("");
//   const [notifMsg, setNotifMsg] = useState("");
//   const [showVerificationMsg, setShowVerificationMsg] = useState(false);
//   const [verified, setVerified] = useState(false);
//   const [pageReloaded, setPageReloaded] = useState(false); // State to keep track of whether the page has been reloaded
//   const { setUser } = useAuth();

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user && user.emailVerified) {
//         setVerified(true);
//       }
//     });

//     return () => {
//       unsubscribe();
//     };
//   }, []);

//   useEffect(() => {
//     if (verified && !pageReloaded) {
//       setShowLoginModal(true);
//       setPageReloaded(true);
//     }
//   }, [verified, pageReloaded]);

//   const handleSignUp = async () => {
//     try {
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       await sendVerificationEmail();
//       setNotifMsg("Verification email sent. Please verify your email address.");
//       setNotifVariant("info");
//       setShowNotif(true);
//       setShowVerificationMsg(true);
//     } catch (error) {
//       console.error("Error signing up:", error);
//       setNotifMsg(`Error signing up: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const sendVerificationEmail = async () => {
//     try {
//       await sendEmailVerification(auth.currentUser);
//     } catch (error) {
//       console.error("Error sending verification email:", error);
//       throw error;
//     }
//   };

//   const handleAccountCreation = async () => {
//     try {
//       setShowVerificationMsg(true);
//     } catch (error) {
//       console.error("Error completing account creation:", error);
//       setNotifMsg(`Error completing account creation: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleGoogleSignUp = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);
//       handleAccountCreation(result.user);
//     } catch (error) {
//       console.error("Error signing up with Google:", error);
//       setNotifMsg(`Error signing up with Google: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     if (password === confirmPassword) {
//       handleSignUp();
//     } else {
//       setNotifMsg("Passwords do not match.");
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleCloseSignUpModal = () => {
//     setShowSignUpModal(false);
//   };

//   const handleCloseLoginModal = () => {
//     setShowLoginModal(false);
//   };

//   const handleOpenSignUpModal = () => {
//     setShowSignUpModal(true);
//     setShowLoginModal(false);
//   };

//   const handleOpenLoginModal = () => {
//     setShowLoginModal(true);
//     setShowSignUpModal(false);
//   };

//   return (
//     <>
//       <Notification
//         showNotif={showNotif}
//         setShowNotif={setShowNotif}
//         variant={notifVariant}
//       >
//         {notifMsg}
//       </Notification>

//       <Modal
//         centered
//         show={showSignUpModal}
//         onHide={handleCloseSignUpModal}
//       >
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Sign up</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="my-3">
//         {showVerificationMsg && (
//           <p className="text-center mt-3" style={{color:'red'}}>Please verify your email address.</p>
//         )}
//           <Row className="justify-content-center">
//             <Col md={10}>
//               <Form onSubmit={handleFormSubmit}>
//                 <Form.Group controlId="formBasicEmail" className="mb-3">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control
//                     type="email"
//                     placeholder="Enter email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formBasicPassword" className="mb-3">
//                   <Form.Label>Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
//                   <Form.Label>Confirm Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Confirm Password"
//                     value={confirmPassword}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Button variant="primary" type="submit" className="w-100">
//                   Sign up
//                 </Button>
//                 <Button variant="danger" className="w-100 mt-3" onClick={handleGoogleSignUp}>
//                   Sign up with Google
//                 </Button>
//               </Form>
//             </Col>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             Already a member?{" "}
//             <Button variant="link" onClick={handleOpenLoginModal}>
//               Login
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         centered
//         show={showLoginModal}
//         onHide={handleCloseLoginModal}
//       >
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Login</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="my-3">
//           {/* Your login form here */}
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             Not a member?{" "}
//             <Button variant="link" onClick={handleOpenSignUpModal}>
//               Sign up
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default SignUp;
